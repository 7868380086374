import { Machine, assign } from "xstate";
import { PlaylistMoment } from "../../../types/moments";

// The hierarchical (recursive) schema for the states
interface PlaylistStateSchema {
  states: {
    mounting: {};
    mounted: {
      states: {
        player: {
          states: {
            playing: {};
            paused: {};
            loading: {};
          };
        };
        range: {
          states: {
            idle: {};
            dragging: {};
          };
        };
      };
    };
    failure: {};
  };
}

// The events that the machine handles

type PlaylistEvent =
  | { type: "MOUNTED"; audio: HTMLAudioElement }
  | { type: "PAUSE" }
  | { type: "PLAY" }
  | { type: "FAILURE" }
  | {
      type: "CHANGE_CURRENT_MOMENT";
      nextIndex: number;
    }
  | {
      type: "LOADING_MOMENT";
    }
  | {
      type: "LOADED_MOMENT";
    }
  | { type: "TIME_UPDATE"; timestamp: number }
  | { type: "DRAG_START" }
  | { type: "DRAG_UPDATE"; sliderTimestamp: number }
  | { type: "DRAG_END"; sliderTimestamp: number }
  | { type: "ENABLE_AUTOPLAY" }
  | { type: "DISABLE_AUTOPLAY" };

// The context (extended state) of the machine
interface PlaylistContext {
  audio: HTMLAudioElement | null;
  playlistMoments: PlaylistMoment[];
  currentIndex: number;
  autoplay: boolean;
  startTime: number;
  endTime: number;
  currentTime: number;
  currentSliderTime: number;
}

export const playlistMachine = Machine<
  PlaylistContext,
  PlaylistStateSchema,
  PlaylistEvent
>(
  {
    // Machine identifier
    id: "playlist",

    // Initial state
    initial: "mounting",

    // Local context for entire machine
    context: {
      audio: null,
      playlistMoments: [],
      currentIndex: 0,
      autoplay: true,
      startTime: 0,
      endTime: 0,
      currentTime: 0,
      currentSliderTime: 0
    },

    states: {
      mounting: {
        on: {
          MOUNTED: {
            target: "mounted",
            actions: [
              assign({
                audio: (_, event) => event.audio,
                startTime: (context, _) =>
                  parseFloat(context.playlistMoments[0].moment.startTime),
                endTime: (context, _) =>
                  parseFloat(context.playlistMoments[0].moment.endTime),
                currentTime: (context, _) =>
                  parseFloat(context.playlistMoments[0].moment.startTime),
                currentSliderTime: (context, _) =>
                  parseFloat(context.playlistMoments[0].moment.startTime)
              }),
              "incrementPlays"
            ],
            cond: (context, _) => context.playlistMoments.length > 0
          }
        }
      },
      mounted: {
        type: "parallel",
        states: {
          player: {
            initial: "paused",
            on: {
              TIME_UPDATE: {
                target: "player.loading",
                actions: [
                  "nextMoment",
                  assign<PlaylistContext>((context, _) => {
                    const nextIndex =
                      context.currentIndex + 1 < context.playlistMoments.length
                        ? context.currentIndex + 1
                        : 0;

                    return {
                      ...context,
                      currentIndex: nextIndex,
                      autoplay: nextIndex === 0 ? false : context.autoplay,
                      startTime: parseFloat(
                        context.playlistMoments[nextIndex].moment.startTime
                      ),
                      endTime: parseFloat(
                        context.playlistMoments[nextIndex].moment.endTime
                      ),
                      currentTime: parseFloat(
                        context.playlistMoments[nextIndex].moment.startTime
                      ),
                      currentSliderTime: parseFloat(
                        context.playlistMoments[nextIndex].moment.startTime
                      )
                    };
                  })
                ],
                cond: (context, event) => event.timestamp >= context.endTime
              },
              CHANGE_CURRENT_MOMENT: {
                target: "player.loading",
                actions: [
                  assign({
                    currentIndex: (_, event) => event.nextIndex,
                    startTime: (context, event) =>
                      parseFloat(
                        context.playlistMoments[event.nextIndex].moment
                          .startTime
                      ),
                    endTime: (context, event) =>
                      parseFloat(
                        context.playlistMoments[event.nextIndex].moment.endTime
                      ),
                    currentTime: (context, event) =>
                      parseFloat(
                        context.playlistMoments[event.nextIndex].moment
                          .startTime
                      ),
                    currentSliderTime: (context, event) =>
                      parseFloat(
                        context.playlistMoments[event.nextIndex].moment
                          .startTime
                      )
                  }),
                  "changeMoment"
                ],
                cond: (context, event) =>
                  context.currentIndex !== event.nextIndex
              },
              ENABLE_AUTOPLAY: {
                actions: assign((context, _) => ({
                  ...context,
                  autoplay: true
                }))
              },
              DISABLE_AUTOPLAY: {
                actions: assign((context, _) => ({
                  ...context,
                  autoplay: false
                }))
              }
            },
            states: {
              playing: {
                on: {
                  PAUSE: {
                    target: "paused",
                    actions: ["pause"]
                  }
                }
              },
              paused: {
                on: {
                  PLAY: {
                    target: "playing",
                    actions: ["play"]
                  }
                }
              },
              loading: {
                on: {
                  LOADED_MOMENT: {
                    target: "paused",
                    // will only play if autoplay is true
                    actions: "playNewMoment"
                  }
                },
                onExit: "incrementPlays"
              }
            }
          },
          range: {
            initial: "idle",
            states: {
              idle: {
                on: {
                  DRAG_START: {
                    target: "dragging"
                  },
                  TIME_UPDATE: {
                    actions: assign({
                      currentTime: (_, event) => event.timestamp,
                      currentSliderTime: (context, event) =>
                        event.timestamp <= context.endTime
                          ? event.timestamp
                          : context.endTime
                    })
                  }
                }
              },
              dragging: {
                on: {
                  DRAG_END: {
                    target: "idle",
                    actions: ["syncCurrentTimeWithSlider"]
                  },
                  DRAG_UPDATE: {
                    actions: assign({
                      currentSliderTime: (_, event) => event.sliderTimestamp
                    }),
                    cond: (context, event) =>
                      event.sliderTimestamp <= context.endTime
                  },
                  TIME_UPDATE: {
                    actions: assign({
                      currentTime: (_, event) => event.timestamp
                    })
                  }
                }
              }
            }
          }
        }
      },

      failure: {
        type: "final"
      }
    }
  },

  {
    actions: {
      // action implementations
      play: (context, _) => {
        console.log(context.audio);
        context.audio?.play();
      },
      pause: (context, _) => {
        console.log(context.audio);
        context.audio?.pause();
      },
      playNewMoment: (context, _) => {
        if (context.autoplay) {
          context.audio?.play();
        }
      },
      changeMoment: (context, event) => {
        if (event.type === "CHANGE_CURRENT_MOMENT" && context.audio) {
          const moment = context.playlistMoments[event.nextIndex].moment;

          context.audio.pause();
          context.audio.src =
            moment.audio + `#t=${moment.startTime},${moment.endTime}`;
          context.audio.load();
        }
      },
      nextMoment: (context, _) => {
        if (context.audio) {
          const nextIndex =
            context.currentIndex + 1 < context.playlistMoments.length
              ? context.currentIndex + 1
              : 0;

          const moment = context.playlistMoments[nextIndex].moment;

          context.audio.pause();
          context.audio.src =
            moment.audio + `#t=${moment.startTime},${moment.endTime}`;
          context.audio.load();
        }
      },
      syncCurrentTimeWithSlider: (context, event) => {
        if (context.audio && event.type === "DRAG_END") {
          context.audio.currentTime = event.sliderTimestamp;
        }
      },
      incrementPlays: (context, _) => {
        const currentMoment =
          context.playlistMoments[context.currentIndex].moment;

        fetch(
          `https://podsoda-server.herokuapp.com/moment/increment-plays/${currentMoment.id}`,
          {
            method: "POST"
          }
        )
          .then(response => response.json())
          .then(_ => console.log("inc play playlistplayer"))
          .catch(_ => {});
      }
    }
  }
);
