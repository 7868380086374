import React, { FC, useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { usePodSodaFetch } from "../../hooks";
import { UserPlaylist } from "../../types/moments";
import PodSodaError from "../shared/PodSodaError";
import ShimmerSearchResult from "../shared/ShimmerSearchResult";
import List from "../shared/List";
import SearchResult from "../shared/SearchResult";
import momentjs from "moment";

const limit = 10;

const UserPlaylists: FC = () => {
  const { username } = useParams();

  const { loading, error, data, retry } = usePodSodaFetch<
    [UserPlaylist[], number]
  >(`/playlist/created/${username}?limit=${limit}`, [username]);

  const [playlists, setPlaylists] = useState<UserPlaylist[]>([]);
  const [loadingMore, setLoadingMore] = useState(false);

  useEffect(() => {
    if (data) {
      setPlaylists(data[0]);
    }
  }, [data]);

  const handleLoadMore = () => {
    setLoadingMore(true);
    fetch(
      `https://podsoda-server.herokuapp.com/playlist/created/${username}?limit=${limit}&offset=${playlists.length}`
    )
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((data: [UserPlaylist[], number]) => {
        setPlaylists(moments => [...moments, ...data[0]]);
        setLoadingMore(false);
      })
      .catch(_ => {
        setLoadingMore(false);
      });
  };

  if (loading)
    return (
      <div className="flex flex-col items-center">
        <div className="w-10/12 mt-8 flex flex-col">
          <div className="self-center w-3/5">
            <h3 className="text-center text-xl font-bold mt-4 my-8">
              Playlists created by{" "}
              <Link to={`/user/${username}`}>
                <span className="text-blue-500 hover:text-blue-700">
                  @{username}
                </span>
              </Link>
            </h3>
          </div>

          <List
            items={Array(10).fill(0)}
            RenderItem={item => <ShimmerSearchResult hasTertiaryText={true} />}
            keyExtractor={(item, i) => i!.toString()}
          />
        </div>
      </div>
    );
  if (error) return <PodSodaError retry={retry} />;

  if (data)
    return (
      <div className="flex flex-col items-center">
        <div className="w-10/12 mt-8 flex flex-col">
          <div className="self-center w-3/5">
            <h3 className="text-center text-xl font-bold mt-4 my-8">
              Playlists created by{" "}
              <Link to={`/user/${username}`}>
                <span className="text-blue-500 hover:text-blue-700">
                  @{username}
                </span>
              </Link>
            </h3>
          </div>

          <List
            items={playlists}
            keyExtractor={playlist => playlist.id}
            RenderItem={playlist => (
              <SearchResult
                link={`/playlists/${playlist.id}`}
                primaryText={playlist.name}
                secondaryText={`${playlist.playlistMoments.length} moments`}
                tertiaryText={`Last updated: ${momentjs
                  .utc(playlist.updatedDate)
                  .fromNow()}`}
                imageUrl={`${process.env.PUBLIC_URL}/podsoda.png`}
              />
            )}
            RenderEmpty={() => (
              <div className="border p-4">
                <p className="text-sm text-gray-600 font-semibold">
                  @{username} hasn't created any playlists yet
                </p>
              </div>
            )}
          />
          {playlists.length < data[1] ? (
            loadingMore ? (
              <div className="bg-white font-bold px-2 w-full hover:bg-gray-300 border-b border-l border-r p-2 overflow-hidden">
                <p>loading...</p>
              </div>
            ) : (
              <div
                onClick={handleLoadMore}
                className="bg-white font-bold px-2 w-full hover:bg-gray-300 border-b border-l border-r p-2 overflow-hidden cursor-pointer"
              >
                <p>load more</p>
              </div>
            )
          ) : null}
        </div>
      </div>
    );
  return <PodSodaError retry={retry} />;
};

export default UserPlaylists;
