import React, { FC, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { IFollowing } from "../../types/moments";
import PodSodaError from "../shared/PodSodaError";
import { usePodSodaFetch } from "../../hooks";
import List from "../shared/List";
import UserSearchResult from "./UserSearchResult";
import UserShimmer from "./components/UserShimmer";

const limit = 20;

const Following: FC = () => {
  const { username } = useParams();

  const { loading, error, data, retry } = usePodSodaFetch<
    [IFollowing[], number]
  >(`/relationships/following-list/${username}/anon?limit=${limit}`, [
    username
  ]);

  const [followingList, setFollowingList] = useState<IFollowing[]>([]);
  const [loadingMore, setLoadingMore] = useState(false);

  useEffect(() => {
    if (data) {
      setFollowingList(data[0]);
    }
  }, [data]);

  const handleLoadMore = () => {
    setLoadingMore(true);
    fetch(
      `https://podsoda-server.herokuapp.com/relationships/following-list/${username}/anon?limit=${limit}&offset=${followingList.length}`
    )
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((data: [IFollowing[], number]) => {
        setFollowingList(followingList => [...followingList, ...data[0]]);
        setLoadingMore(false);
      })
      .catch(_ => {
        setLoadingMore(false);
      });
  };

  if (loading)
    return (
      <div className="flex flex-col items-center">
        <div className="w-10/12 mt-8 flex flex-col">
          <div className="self-center w-3/5">
            <h3 className="text-center text-xl font-bold my-4 text-blue-500 hover:text-blue-700">
              @{username}'s followers{" "}
              <span className="text-gray-500 hover:text-gray-700">()</span>
            </h3>

            <List
              items={Array(10).fill(0)}
              RenderItem={item => <UserShimmer />}
              keyExtractor={(item, i) => i!.toString()}
            />
          </div>
        </div>
      </div>
    );
  if (error) return <PodSodaError retry={retry} />;

  if (data) {
    return (
      <div className="flex flex-col items-center">
        <div className="w-10/12 mt-8 flex flex-col">
          <div className="self-center w-3/5">
            <h3 className="text-center text-xl font-bold my-4 text-blue-500 hover:text-blue-700">
              Users @{username} follows{" "}
              <span className="text-gray-500 hover:text-gray-700">
                ({data[1]})
              </span>
            </h3>

            <List
              items={followingList}
              RenderItem={item => (
                <UserSearchResult
                  moments_count={item.followed.moments_count}
                  followers_count={item.followed.followers_count}
                  following_count={item.followed.following_count}
                  username={item.followed.username}
                />
              )}
              keyExtractor={item => item.followed.id}
              RenderEmpty={() => (
                <div className="border p-4">
                  <p className="text-sm text-gray-600 font-semibold">
                    @{username} is not following anyone yet
                  </p>
                </div>
              )}
            />
            {followingList.length < data[1] ? (
              loadingMore ? (
                <div className="bg-white font-bold px-2 w-full hover:bg-gray-300 border-b border-l border-r p-2 overflow-hidden">
                  <p>loading...</p>
                </div>
              ) : (
                <div
                  onClick={handleLoadMore}
                  className="bg-white font-bold px-2 w-full hover:bg-gray-300 border-b border-l border-r p-2 overflow-hidden cursor-pointer"
                >
                  <p>load more</p>
                </div>
              )
            ) : null}
          </div>
        </div>
      </div>
    );
  }
  return <PodSodaError retry={retry} />;
};

export default Following;
