import React, { FC, useEffect, useState } from "react";
import { Moment } from "../../../types/moments";
import { useAudio } from "react-use";
import { PlayCircle, PauseCircle } from "react-feather";
import { formatTimestamp } from "../../../utils/time";
import TwitterShare from "../../shared/TwitterShare";

type Props = {
  moment: Moment;
  onClick?: (moment: Moment) => void;
  selectedMoment: boolean;
  unselectMoment?: (moment: Moment) => void;
};

const ShowcaseMoment: FC<Props> = ({
  moment,
  onClick,
  selectedMoment,
  unselectMoment
}) => {
  const [audio, state, controls] = useAudio({
    src: `${moment.audio}#t=${moment.startTime},${moment.endTime}`,
    autoPlay: false,
    title: `PodSoda Moment: ${moment.momentTitle}`,
    preload: "none"
  });

  const [startTime, setStartTime] = useState(parseFloat(moment.startTime));
  const [endTime, setEndTime] = useState(parseFloat(moment.endTime));

  function play() {
    controls.play();
  }

  function pause() {
    controls.pause();
  }

  function replay() {
    controls.seek(startTime);
    setTimeout(() => {
      controls.play();
    }, 16);
  }

  // don't play past the end of a moment
  // the native audio player will only limit it the first time with the uri fragment
  useEffect(() => {
    if (state.time >= endTime) {
      controls.pause();
    }
  }, [state.time, moment.endTime, controls]);

  // record unique plays
  useEffect(() => {
    fetch(
      `https://podsoda-server.herokuapp.com/moment/increment-plays/${moment.id}`,
      {
        method: "POST"
      }
      // eslint-disable-next-line no-unused-vars
    ).catch(_ => {});
    // fail silently, nobody cares
  }, [moment.id]);

  const percent = ((state.time - startTime) / (endTime - startTime)) * 100;

  // for available moments. click to select them
  if (!selectedMoment) {
    return (
      <div className="flex flex-row w-full border h-full rounded overflow-hidden bg-gray-100 my-2">
        {audio}

        <div className="relative flex-none">
          <img
            src={moment.thumbnail}
            className="object-cover border-r"
            style={{
              height: 92,
              width: 92
            }}
            alt=""
          />

          <div
            className="cursor-pointer absolute opacity-75 shadow-md hover:opacity-100"
            style={{
              top: 23,
              left: 23
            }}
          >
            {state.paused ? (
              state.time >= endTime ? (
                <PlayCircle
                  color="#f5f7fa"
                  fill="#f9703e"
                  size={40}
                  onClick={replay}
                />
              ) : (
                <PlayCircle
                  color="#f5f7fa"
                  fill="#f9703e"
                  size={40}
                  onClick={play}
                />
              )
            ) : (
              <PauseCircle
                color="#f5f7fa"
                fill="#f9703e"
                size={40}
                onClick={pause}
              />
            )}
          </div>
        </div>

        <div
          className="flex-1 flex flex-col justify-between mx-4 cursor-pointer"
          onClick={() => {
            if (onClick) {
              onClick(moment);
            }
          }}
          style={{ minWidth: 0 }}
        >
          <p
            className="truncate text-gray-700 text-sm  mt-1"
            style={{ fontWeight: 500 }}
          >
            {moment.momentTitle}
          </p>

          <div className="flex flex-col mb-2">
            <p
              className="text-xs self-end"
              style={{
                color: "#7b8794"
              }}
            >
              -
              {state.time
                ? formatTimestamp(clampToZero(endTime - state.time))
                : formatTimestamp(clampToZero(endTime - startTime))}
            </p>
            <progress value={percent} max={100} className="w-full" />
          </div>

          <div className="flex flex-row justify-end items-center my-1">
            <TwitterShare momentId={moment.id} />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex flex-row w-full border h-full rounded overflow-hidden bg-gray-100">
        {audio}

        <div className="relative flex-none">
          <img
            src={moment.thumbnail}
            className="object-cover border-r"
            style={{
              height: 92,
              width: 92
            }}
            alt=""
          />

          <div
            className="cursor-pointer absolute opacity-75 shadow-md hover:opacity-100"
            style={{
              top: 23,
              left: 23
            }}
          >
            {state.paused ? (
              state.time >= endTime ? (
                <PlayCircle
                  color="#f5f7fa"
                  fill="#f9703e"
                  size={40}
                  onClick={replay}
                />
              ) : (
                <PlayCircle
                  color="#f5f7fa"
                  fill="#f9703e"
                  size={40}
                  onClick={play}
                />
              )
            ) : (
              <PauseCircle
                color="#f5f7fa"
                fill="#f9703e"
                size={40}
                onClick={pause}
              />
            )}
          </div>
        </div>

        <div
          className="flex-1 flex flex-col justify-between mx-4"
          onClick={() => {
            if (onClick) {
              onClick(moment);
            }
          }}
          style={{ minWidth: 0 }}
        >
          <p
            className="truncate text-gray-700 text-sm  mt-1"
            style={{ fontWeight: 500 }}
          >
            {moment.momentTitle}
          </p>

          <div className="flex flex-col mb-2">
            <p
              className="text-xs self-end"
              style={{
                color: "#7b8794"
              }}
            >
              -
              {state.time
                ? formatTimestamp(clampToZero(endTime - state.time))
                : formatTimestamp(clampToZero(endTime - startTime))}
            </p>
            <progress value={percent} max={100} className="w-full" />
          </div>

          <div className="flex flex-row justify-end items-center my-1">
            <button
              className="uppercase text-xs font-semibold text-red-500 hover:text-red-700 mx-4"
              onClick={() => {
                if (unselectMoment) {
                  unselectMoment(moment);
                }
              }}
            >
              Remove
            </button>
            <TwitterShare momentId={moment.id} />
          </div>
        </div>
      </div>
    );
  }
};

export default ShowcaseMoment;

function clampToZero(value: number) {
  return value > 0 ? value : 0;
}
