import React, { FC } from "react";
import { usePodSodaFetch } from "../../hooks";
import { useStoreState } from "react-svelte-stores/build";
import userAccountStore from "../../stores/userAccount";
import { Showcase } from "../../types/moments";
import List from "../shared/List";
import SearchResult from "../shared/SearchResult";
import { Link } from "react-router-dom";
import ShimmerSearchResult from "../shared/ShimmerSearchResult";

const YourShowcases: FC = () => {
  const userAccountState = useStoreState(userAccountStore);
  const { loading, error, data: showcases } = usePodSodaFetch<Showcase[]>(
    `/showcase/user/${userAccountState.userId}`
  );

  if (loading)
    return (
      <div className="flex flex-col items-center">
        <div className="w-10/12 mt-8 flex flex-col">
          <div className="self-center w-3/5">
            <h3 className="text-center text-2xl font-bold mt-4 my-8">
              Your Showcases
            </h3>
          </div>

          <List
            items={Array(10).fill(0)}
            RenderItem={item => <ShimmerSearchResult hasTertiaryText={true} />}
            keyExtractor={(item, i) => i!.toString()}
          />
        </div>
      </div>
    );
  if (error) return <p>error</p>;

  if (showcases) {
    return (
      <div className="flex flex-col items-center">
        <div className="w-10/12 mt-8 flex flex-col">
          <div className="self-center w-3/5">
            <h3 className="text-center text-2xl font-bold mt-4 my-8">
              Your Showcases
            </h3>
          </div>

          <List
            items={showcases}
            keyExtractor={showcase => showcase.id}
            RenderItem={showcase => (
              <SearchResult
                imageUrl={showcase.image}
                primaryText={showcase.podcast_title}
                secondaryText={showcase.publisher}
                tertiaryText={`/${showcase.path}`}
                link={``}
                noLink={true}
                quaternaryChild={
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={`https://podsoda-showcase.now.sh/${showcase.path}`}
                  >
                    <p className="text-xs uppercase text-blue-500 hover:text-blue-700">
                      Go to showcase
                    </p>
                  </a>
                }
                TopRightElement={
                  <Link to={`/edit-showcase/${showcase.id}`}>
                    <button className="bg-red-500 hover:bg-red-700 text-white font-bold my-2 py-1 px-2 rounded-full focus:outline-none">
                      Edit
                    </button>
                  </Link>
                }
              />
            )}
            RenderEmpty={() => (
              <div className="w-full  p-2 text-gray-500 text-sm border-t">
                <p>You don't have any showcases</p>
              </div>
            )}
          />
        </div>
      </div>
    );
  } else {
    return <p>error</p>;
  }
};

export default YourShowcases;
