import { persisted } from "react-svelte-stores";

interface HistoryProgressState {
  lastUpdated: Date;
  position: number;
  bufferedPosition: number;
  duration: number;
}

// all in seconds
// add moments later
interface ListeningHistoryState {
  podcasts: {
    [id: string]: HistoryProgressState;
  };
}

const listeningHistoryDefaultState: ListeningHistoryState = {
  podcasts: {}
};

const createListeningHistoryStore = (initialState: ListeningHistoryState) => {
  const { subscribe, update } = persisted(initialState, "listeningHistory");

  return {
    subscribe,
    updatePodcastPosition: (
      podcastId: string,
      position: number,
      bufferedPosition: number,
      duration: number
    ) =>
      update(state => ({
        ...state,
        podcasts: {
          ...state.podcasts,
          [podcastId]: {
            lastUpdated: new Date(),
            position,
            bufferedPosition,
            duration
          }
        }
      }))
  };
};

export default createListeningHistoryStore(listeningHistoryDefaultState);
