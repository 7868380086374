import React, { FC, useState } from "react";
import { usePodSodaFetch } from "../../hooks";
import { TrendingMoment } from "../../types/moments";
import PodSodaError from "../shared/PodSodaError";
import DiscoverMoments from "./DiscoverMoments";
import ShimmerDiscoverMoments from "./ShimmerDiscoverMoments";

const limit = 5;

const HotMoments: FC = () => {
  const [timespan, setTimespan] = useState<"day" | "week" | "month">("week");

  const { loading, error, data: moments, retry } = usePodSodaFetch<
    TrendingMoment[]
  >(`/moment/trending?limit=${limit}&timespan=${timespan}`, [timespan, limit]);

  if (loading)
    return (
      <div className="mb-4">
        <div className="flex flex-row justify-start items-center my-4 mx-2">
          <h2 className="font-semibold text-left text-3xl">
            Trending Moments{" "}
          </h2>
          <img
            src={process.env.PUBLIC_URL + "/podsoda.png"}
            alt=""
            className="h-full w-8 object-cover ml-2"
          />
        </div>

        <div className="flex flex-row justify-start mx-2">
          <button
            onClick={() => setTimespan("day")}
            className={
              timespan === "day"
                ? "text-white bg-gray-700 font-semibold px-2 py-1 focus:outline-none border-l border-t border-b border-gray-700"
                : "text-gray-700 hover:text-black font-semibold px-2 py-1 focus:outline-none  border-l border-t border-b border-gray-700"
            }
          >
            Today
          </button>
          <button
            onClick={() => setTimespan("week")}
            className={
              timespan === "week"
                ? "text-white bg-gray-700  font-semibold px-2 py-1 focus:outline-none border border-gray-700"
                : "text-gray-700 hover:text-black font-semibold px-2 py-1 focus:outline-none border  border-gray-700"
            }
          >
            Week
          </button>
          <button
            onClick={() => setTimespan("month")}
            className={
              timespan === "month"
                ? "text-white bg-gray-700 font-semibold px-2 py-1 focus:outline-none border-r border-t border-b border-gray-700"
                : "text-gray-700 hover:text-black font-semibold px-2 py-1 focus:outline-none border-r border-t border-b border-gray-700"
            }
          >
            Month
          </button>
        </div>

        <ShimmerDiscoverMoments />
      </div>
    );
  if (error) return <PodSodaError retry={retry} />;

  if (moments) {
    return (
      <div className="mb-4">
        <div className="flex flex-row justify-start items-center my-4 mx-2">
          <h2 className="font-semibold text-left text-3xl">
            Trending Moments{" "}
          </h2>
          <img
            src={process.env.PUBLIC_URL + "/podsoda.png"}
            alt=""
            className="h-full w-8 object-cover ml-2"
          />
        </div>

        <div className="flex flex-row justify-start mx-2">
          <button
            onClick={() => setTimespan("day")}
            className={
              timespan === "day"
                ? "text-white bg-gray-700 font-semibold px-2 py-1 focus:outline-none border-l border-t border-b border-gray-700"
                : "text-gray-700 hover:text-black font-semibold px-2 py-1 focus:outline-none  border-l border-t border-b border-gray-700"
            }
          >
            Today
          </button>
          <button
            onClick={() => setTimespan("week")}
            className={
              timespan === "week"
                ? "text-white bg-gray-700  font-semibold px-2 py-1 focus:outline-none border border-gray-700"
                : "text-gray-700 hover:text-black font-semibold px-2 py-1 focus:outline-none border  border-gray-700"
            }
          >
            Week
          </button>
          <button
            onClick={() => setTimespan("month")}
            className={
              timespan === "month"
                ? "text-white bg-gray-700 font-semibold px-2 py-1 focus:outline-none border-r border-t border-b border-gray-700"
                : "text-gray-700 hover:text-black font-semibold px-2 py-1 focus:outline-none border-r border-t border-b border-gray-700"
            }
          >
            Month
          </button>
        </div>

        <div className="mx-2">
          <DiscoverMoments moments={moments} />
        </div>
      </div>
    );
  }
  return <PodSodaError retry={retry} />;
};

export default HotMoments;
