import React, { FC, useState, useEffect } from "react";
import { usePodSodaFetch } from "../../hooks";
import { Moment } from "../../types/moments";
import PodSodaError from "../shared/PodSodaError";
import List from "../shared/List";
import SearchResult from "../shared/SearchResult";
import ShimmerSearchResult from "../shared/ShimmerSearchResult";

type Props = {
  podcastId: string;
};

const limit = 10;

const PodcastMoments: FC<Props> = ({ podcastId }) => {
  const { loading, error, data, retry } = usePodSodaFetch<[Moment[], number]>(
    `/moment/by-podcast-id/${podcastId}?limit=${limit}&offset=0`
  );

  const [moments, setMoments] = useState<Moment[]>([]);

  const [loadingMore, setLoadingMore] = useState(false);

  useEffect(() => {
    if (data) {
      setMoments(data[0]);
    }
  }, [data]);

  const handleLoadMore = () => {
    setLoadingMore(true);
    fetch(
      `https://podsoda-server.herokuapp.com/moment/by-podcast-id/${podcastId}?limit=${limit}&offset=${moments.length}`
    )
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((data: [Moment[], number]) => {
        setMoments(moments => [...moments, ...data[0]]);
        setLoadingMore(false);
      })
      .catch(_ => {});
  };

  if (loading)
    return (
      <div className="mt-8">
        <List
          items={Array(10).fill(0)}
          RenderItem={item => <ShimmerSearchResult hasTertiaryText={true} />}
          keyExtractor={(item, i) => i!.toString()}
        />
      </div>
    );
  if (error) return <PodSodaError retry={retry} />;

  if (data) {
    return (
      <div className="mt-8">
        <List
          items={moments}
          keyExtractor={moment => moment.id}
          RenderItem={moment => (
            <SearchResult
              imageUrl={moment.thumbnail}
              primaryText={moment.momentTitle}
              secondaryText={moment.episodeTitle}
              tertiaryText={`created by @${moment.username}`}
              link={`/moments/${moment.id}`}
            />
          )}
          RenderEmpty={() => (
            <div className="border p-4">
              <p className="text-sm text-gray-600 font-semibold">
                No moments found
              </p>
            </div>
          )}
        />
        {moments.length < data[1] ? (
          loadingMore ? (
            <div className="bg-white font-bold px-2 w-full hover:bg-gray-300 border-b border-l border-r p-2 overflow-hidden">
              <p>loading...</p>
            </div>
          ) : (
            <div
              onClick={handleLoadMore}
              className="bg-white font-bold px-2 w-full hover:bg-gray-300 border-b border-l border-r p-2 overflow-hidden cursor-pointer"
            >
              <p>load more</p>
            </div>
          )
        ) : null}
      </div>
    );
  }
  return <PodSodaError retry={retry} />;
};

export default PodcastMoments;
