export async function listenNotesFetch<T>(path: string): Promise<T> {
  try {
    const response = await fetch(
      `https://podsoda-showcase.now.sh/api/listen${path}`
    );

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    const data: T = await response.json();

    return data;
  } catch (err) {
    throw new Error(err.message);
  }
}

const PODSODA_SERVER_URL = "https://podsoda-server.herokuapp.com";

// use this in API, not components!
export async function podsodaFetch<T>(
  path: string,
  init?: RequestInit
): Promise<T> {
  try {
    const response = await fetch(
      `${PODSODA_SERVER_URL}${path}`,
      init
        ? init
        : {
            method: "GET"
          }
    );

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    const data: T = await response.json();

    return data;
  } catch (err) {
    throw new Error(err.message);
  }
}
