import React, { FC, useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { usePodSodaFetch } from "../../hooks";
import { Playlist, PlaylistMoment } from "../../types/moments";
import PodSodaError from "../shared/PodSodaError";
import SavePlaylist from "./SavePlaylist";
import List from "../shared/List";
import ShimmerSearchResult from "../shared/ShimmerSearchResult";
import ErrorMessage from "../shared/ErrorMessage";
import EditPlaylist from "./components/EditPlaylist";
import Player from "./player/Player";

const PlaylistById: FC = () => {
  const { id } = useParams();

  const { loading, error, data: playlist, retry } = usePodSodaFetch<Playlist>(
    `/playlist/${id}?userId=anon`
  );

  const [optimisticMoments, setOptimisticMoments] = useState<
    PlaylistMoment[] | null
  >(null);

  useEffect(() => {
    if (playlist) {
      setOptimisticMoments(playlist.playlistMoments);
    }
  }, [playlist]);

  const [editing, setEditing] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  if (loading)
    return (
      <div className="flex flex-col items-center">
        <br />
        <div className="h-10 w-24 bg-gray-300 rounded" />
        <div className="h-6 w-32 bg-gray-300 rounded mt-1" />
        <div className="h-6 w-24 bg-gray-400 rounded-full mt-2" />

        <div className="w-10/12 mt-8">
          <p className="text-center text-xl text-gray-700 font-semibold">
            Loading playlist...
          </p>
        </div>
      </div>
    );
  if (error) return <PodSodaError retry={retry} />;

  if (playlist && optimisticMoments) {
    return (
      <div className="flex flex-col items-center">
        <br />
        <h2 className="text-2xl font-semibold">{playlist.name}</h2>
        <p className="text-gray-600 font-semibold">
          created by{" "}
          <Link to={`/user/${playlist.creator.username}`}>
            <span className="text-blue-700 hover:text-gray-600">
              @{playlist?.creator.username}
            </span>
          </Link>
        </p>
        <div className="flex flex-row items-center">
          <SavePlaylist
            playlistId={playlist.id}
            playlist={playlist}
            toggleEditing={() => setEditing(editing => !editing)}
            editing={editing}
          />
        </div>

        <div className="w-10/12 mt-8">
          <ErrorMessage
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
          />

          <div className="mt-2" />

          {editing ? (
            <EditPlaylist
              playlist={playlist}
              optimisticMoments={optimisticMoments}
              setStopEditing={() => setEditing(false)}
              setOptimisticMoments={setOptimisticMoments}
            />
          ) : optimisticMoments.length > 0 ? (
            <Player
              playlistMoments={optimisticMoments}
              title={playlist.name}
              id={playlist.id}
            />
          ) : (
            <div className="w-full  p-2 text-gray-500 text-sm border-t">
              <p className="hover:text-gray-700">
                This playlist has no moments
              </p>
            </div>
          )}
        </div>
      </div>
    );
  }
  return <PodSodaError retry={retry} />;
};

export default PlaylistById;
