import React, { FC } from "react";
import { ListenFeedEpisodePreview } from "../../types/listenApi";
// import Logger from "../shared/Logger";
import { useSelectedStoreState, useStoreState } from "react-svelte-stores";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faPause } from "@fortawesome/free-solid-svg-icons";

// @ts-ignore
import posthog from "posthog-js";
import { playerStore } from "../shared/NewPlayer";

type Props = {
  episode: ListenFeedEpisodePreview;
  podcastId: string;
  podcastName: string;
  genre_ids: number[];
};

const EpisodePlayButton: FC<Props> = ({
  episode,
  podcastId,
  podcastName,
  genre_ids
}) => {
  const selectedEpisode = useSelectedStoreState(
    playerStore,
    state => state.metadata?.id === episode.id,
    [episode.id]
  );

  return (
    <div className="flex flex-row">
      {selectedEpisode ? (
        <SelectedEpisodePlayButton />
      ) : (
        <button
          onClick={e => {
            e.preventDefault();
            posthog.capture("podcast-episode-play");

            playerStore.dispatch({
              type: "SET_PODCAST",
              metadata: {
                podcastId,
                podcastName,
                genre_ids,
                ...episode
              }
            });
          }}
          className="text-sm w-24 uppercase font-semibold text-blue-500 hover:text-blue-700 focus:outline-none bg-gray-300 rounded-full px-2 py-1"
        >
          <FontAwesomeIcon icon={faPlay} transform="shrink-6" color="gray" />{" "}
          Play
        </button>
      )}{" "}
    </div>
  );
};

export default EpisodePlayButton;

const SelectedEpisodePlayButton: FC = () => {
  const playerState = useStoreState(playerStore);

  if (playerState.status === "playing")
    return (
      <div>
        <button
          onClick={e => {
            e.preventDefault();
            playerStore.dispatch({ type: "PAUSE" });
          }}
          className="text-sm w-24 uppercase font-semibold text-blue-500 hover:text-blue-700 focus:outline-none bg-gray-300  rounded-full px-2 py-1"
        >
          <FontAwesomeIcon icon={faPause} transform="shrink-6" color="gray" />{" "}
          pause
        </button>
      </div>
    );

  if (playerState.status === "paused")
    return (
      <div>
        <button
          onClick={e => {
            e.preventDefault();
            playerStore.dispatch({ type: "PLAY" });
          }}
          className="text-sm w-24 uppercase font-semibold text-blue-500 hover:text-blue-700 focus:outline-none bg-gray-300 rounded-full px-2 py-1"
        >
          <FontAwesomeIcon icon={faPlay} transform="shrink-6" color="gray" />{" "}
          Play
        </button>
      </div>
    );

  return (
    <div className="text-sm w-24 uppercase font-semibold text-blue-500 focus:outline-none bg-gray-300 rounded-full px-2 py-1">
      Loading...
    </div>
  );
};
