import React, { FC } from "react";

type Props = {
  hasTertiaryText: boolean;
};

const ShimmerSearchResult: FC<Props> = ({ hasTertiaryText }) => {
  return (
    <div className="bg-white  w-full hover:bg-gray-300 border-b p-2 overflow-hidden">
      <div className="flex flex-row">
        <div className="h-20 w-20 rounded-sm bg-gray-500" />

        <div className="flex flex-col  justify-around mx-2 pb-2">
          {hasTertiaryText && (
            <div className=" h-4 w-16 rounded-sm bg-gray-400" />
          )}
          <div className="h-4 w-48 rounded-sm bg-gray-400" />
          <div className="h-4 w-20 rounded-sm bg-gray-400" />
        </div>
      </div>
    </div>
  );
};

export default ShimmerSearchResult;
