import React, { FC } from "react";
import { IProfile } from "../../../types/moments";
import { Link } from "react-router-dom";
import List from "../../shared/List";
import SearchResult from "../../shared/SearchResult";
import momentjs from "moment";
import FollowButton from "./FollowButton";
import { useStoreState } from "react-svelte-stores/build";
import userAccountStore from "../../../stores/userAccount";
import { User } from "react-feather";

type Props = {
  profile: IProfile;
};

const PublicProfile: FC<Props> = ({ profile }) => {
  const userAccountState = useStoreState(userAccountStore);

  return (
    <div className="flex flex-col items-center">
      <div className="w-10/12 mt-8 flex flex-col">
        <div className="flex flex-col items-center self-center w-3/5">
          <div className="bg-white p-2 rounded-full border self-center">
            <User />
          </div>
          <h3 className="text-center text-xl font-bold my-4 text-blue-500 hover:text-blue-700">
            @{profile.username}
          </h3>

          <div className="flex flex-col mt-8 mb-4">
            <p className="appearance-none block  bg-gray-200 text-gray-700 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white">
              {profile.bio ? profile.bio : "Empty bio"}
            </p>
          </div>

          {userAccountState.hasAccount ? (
            <FollowButton
              following={profile.following}
              followedUsername={profile.username}
              followersCount={profile.followers_count}
              followingCount={profile.following_count}
            />
          ) : (
            <div className="w-full border rounded flex flex-col sm:flex-row justify-between sm:justify-around items-center my-2 p-2">
              <Link to={`/user/${profile.username}/followers`}>
                <div className="flex flex-col text-blue-500 hover:text-blue-700 my-1 sm:my-0">
                  <p className="text-center">{profile.followers_count}</p>
                  <p className="text-gray-500 hover:text-gray-700">followers</p>
                </div>
              </Link>

              <Link to={`/user/${profile.username}/following`}>
                <div className="flex flex-col text-blue-500 hover:text-blue-700 my-1 sm:my-0">
                  <p className="text-center">{profile.following_count}</p>
                  <p className="text-gray-500 hover:text-gray-700">following</p>
                </div>
              </Link>

              <Link to="/login">
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full my-1 sm:my-0">
                  Follow
                </button>
              </Link>
            </div>
          )}

          <div className="w-full border rounded flex flex-col sm:flex-row justify-between sm:justify-around items-center p-2">
            {/* <Link to={`/user/${profile.username}/following`}> */}
            <div className="flex flex-col text-blue-500 my-1 sm:my-0">
              <p className="text-center">{profile.comments_count}</p>
              <p className="text-gray-500 ">comments</p>
            </div>
            {/* </Link> */}
            <Link to={`/user/${profile.username}/created-playlists`}>
              <div className="flex flex-col text-blue-500 hover:text-blue-700 my-1 sm:my-0">
                <p className="text-center">{profile.created_playlists_count}</p>
                <p className="text-gray-500 hover:text-gray-700">playlists</p>
              </div>
            </Link>
          </div>
        </div>

        <div className="flex flex-row justify-between items-baseline mt-8 mb-2">
          <p className="font-semibold">Created Moments</p>
          <Link to={`/user/${profile.username}/created-moments`}>
            <button className="font-semibold text-sm text-blue-500 hover:text-blue-700 focus:outline-none">
              View All
            </button>
          </Link>
        </div>
        <List
          items={profile.moments}
          keyExtractor={moment => moment.id}
          RenderItem={moment => (
            <SearchResult
              imageUrl={moment.thumbnail}
              primaryText={moment.momentTitle}
              secondaryText={moment.podcastName}
              link={`/moments/${moment.id}`}
              tertiaryText={`${momentjs
                .utc(parseInt(moment.startTime) * 1000)
                .format(
                  parseInt(moment.startTime) >= 3600 ? "HH:mm:ss" : "mm:ss"
                )} to ${momentjs
                .utc(parseInt(moment.endTime) * 1000)
                .format(
                  parseInt(moment.endTime) >= 3600 ? "HH:mm:ss" : "mm:ss"
                )}`}
            />
          )}
          RenderEmpty={() => (
            <div className="border p-4">
              <p className="text-sm text-gray-600 font-semibold">
                {profile.username} hasn't created any moments yet
              </p>
            </div>
          )}
        />

        <div className="flex flex-row justify-between items-baseline mt-8 mb-2">
          <p className="font-semibold">Liked Moments</p>
          <Link to={`/user/${profile.username}/liked-moments`}>
            <button className="font-semibold text-sm text-blue-500 hover:text-blue-700 focus:outline-none">
              View All
            </button>
          </Link>
        </div>
        <List
          items={profile.likes}
          keyExtractor={like => like.moment.id}
          RenderItem={like => (
            <SearchResult
              imageUrl={like.moment.thumbnail}
              primaryText={like.moment.momentTitle}
              secondaryText={like.moment.podcastName}
              tertiaryText={`${momentjs
                .utc(parseInt(like.moment.startTime) * 1000)
                .format(
                  parseInt(like.moment.startTime) >= 3600 ? "HH:mm:ss" : "mm:ss"
                )} to ${momentjs
                .utc(parseInt(like.moment.endTime) * 1000)
                .format(
                  parseInt(like.moment.endTime) >= 3600 ? "HH:mm:ss" : "mm:ss"
                )}`}
              link={`/moments/${like.moment.id}`}
            />
          )}
          RenderEmpty={() => (
            <div className="border p-4">
              <p className="text-sm text-gray-600 font-semibold">
                {profile.username} hasn't liked any moments yet
              </p>
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default PublicProfile;
