import React, { FC, useState, useLayoutEffect } from "react";
import PodSodaError from "../shared/PodSodaError";
import List from "../shared/List";
import { CuratedListsResponse } from "../../types/listenApi";
import SearchResult from "../shared/SearchResult";
import { fromNow } from "../../utils/time";
import ShimmerSearchResult from "../shared/ShimmerSearchResult";
import { useStoreState } from "react-svelte-stores/build";
import { curatedListsStore } from "../../stores/cacheStores";

const CuratedLists: FC = () => {
  const [lists, setLists] = useState<CuratedListsResponse>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const curatedListsState = useStoreState(curatedListsStore);

  useLayoutEffect(() => {
    if (curatedListsState) {
      setLists(curatedListsState);
      setLoading(false);
    } else {
      fetch(`https://podsoda-showcase.now.sh/api/listen/curated_podcasts`)
        .then(response => {
          if (!response.ok) {
            throw Error(response.statusText);
          }
          return response.json();
        })
        .then((data: CuratedListsResponse) => {
          setLists(data);
          curatedListsStore.set(data);
          setLoading(false);
        })
        .catch(_ => {
          setError(true);
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading)
    return (
      <div>
        <h3 className="text-center text-xl font-bold mb-4 text-blue-500">
          Curated Podcast Lists
        </h3>
        <List
          items={Array(10).fill(0)}
          RenderItem={item => <ShimmerSearchResult hasTertiaryText={true} />}
          keyExtractor={(item, i) => i!.toString()}
        />
      </div>
    );
  if (error) return <PodSodaError retry={() => {}} />;

  if (lists) {
    return (
      <div>
        <h3 className="text-center text-xl font-bold mb-4 text-blue-500">
          Curated Podcast Lists
        </h3>
        <List
          items={lists.curated_lists}
          keyExtractor={list => list.id}
          RenderItem={list => (
            <SearchResult
              imageUrl={list.podcasts[0]?.image}
              primaryText={list.title}
              secondaryText={list.description}
              tertiaryText={fromNow(list.pub_date_ms)}
              link={`/podcasts/curated-list/${list.id}`}
            />
          )}
        />
      </div>
    );
  }
  return <PodSodaError retry={() => {}} />;
};

export default CuratedLists;
