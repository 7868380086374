import React, { FC } from "react";
import { useStoreState } from "react-svelte-stores/build";
import userAccountStore from "../../stores/userAccount";
import { Link } from "react-router-dom";

type Props = {
  to: string;
  children: JSX.Element;
};

const AuthorizedLink: FC<Props> = ({ to, children }) => {
  const userAccountState = useStoreState(userAccountStore);

  if (userAccountState.hasAccount) {
    return <Link to={to}>{children}</Link>;
  } else {
    return <Link to="/login">{children}</Link>;
  }
};

export default AuthorizedLink;
