import React, { FC } from "react";
import { Link } from "react-router-dom";

type Props = {
  moments_count: number;
  followers_count: number;
  following_count: number;
  username: string;
};

const UserSearchResult: FC<Props> = ({
  moments_count,
  following_count,
  followers_count,
  username
}) => {
  return (
    <div className="bg-white w-full hover:bg-gray-300 border-b border-l border-r p-2 overflow-hidden ">
      <Link to={`/user/${username}`}>
        <p className="font-semibold text-md truncate">@{username}</p>

        <div className="flex flex-row">
          <p className="text-sm truncate mr-1">{followers_count} followers,</p>
          <p className="text-sm truncate">{following_count} following</p>
        </div>
        <p className="text-gray-600 text-xs  truncate">
          {moments_count} moments created
        </p>
      </Link>
    </div>
  );
};

export default UserSearchResult;
