import React, { FC } from "react";
import { useStoreState } from "react-svelte-stores/build";
import favoritePodcasts from "../../../stores/favoritePodcasts";
import List from "../../shared/List";
import SearchResult from "../../shared/SearchResult";

const FavoritePodcasts: FC = () => {
  const favoritePodcastsState = useStoreState(favoritePodcasts);

  return (
    <div>
      <div className="flex flex-row justify-start items-baseline  mt-8 mb-2">
        <p className="font-semibold">Favorite Podcasts</p>
      </div>

      <List
        items={favoritePodcastsState}
        keyExtractor={podcast => podcast.id}
        RenderItem={podcast => (
          <SearchResult
            imageUrl={podcast.image}
            primaryText={podcast.title_original}
            secondaryText={`${podcast.description_original}`}
            tertiaryText={podcast.publisher_original}
            link={`/podcasts/${podcast.id}`}
          />
        )}
        RenderEmpty={() => (
          <div className="border p-4">
            <p className="text-sm text-gray-600 font-semibold">
              You haven't favorited any podcasts yet
            </p>
          </div>
        )}
      />
    </div>
  );
};

export default FavoritePodcasts;
