import { FavoritedPodcast } from "../types/listenApi";
import { persisted } from "react-svelte-stores/build";

type FavoritePodcastsState = Array<FavoritedPodcast>;

const defaultFavoritePodcastsState: FavoritePodcastsState = [];

const createFavoritePodcastsStore = (initialState: FavoritePodcastsState) => {
  const { subscribe, update } = persisted(initialState, "favoritePodcasts");

  return {
    subscribe,
    addFavoritePodcast: (favoritePodcast: FavoritedPodcast) =>
      update(state => [...state, favoritePodcast]),
    removeFavoritePodcast: (podcastId: string) =>
      update(state => state.filter(podcast => podcast.id !== podcastId))
  };
};

export default createFavoritePodcastsStore(defaultFavoritePodcastsState);
