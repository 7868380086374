import React, { FC, useReducer, useEffect } from "react";
import { PlaylistMoment, Playlist } from "../../../types/moments";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult
} from "react-beautiful-dnd";
import SearchResult from "../../shared/SearchResult";
import { formatTimestamp } from "../../../utils/time";
import { useStoreState } from "react-svelte-stores/build";
import userAccountStore from "../../../stores/userAccount";

// might be hard to read bc i changed the types from searchmoment to playlistmoment

type State = {
  status: "editing" | "submitting" | "error" | "success";
  moments: PlaylistMoment[];
  originalMoments: PlaylistMoment[];
};

// need playlist for metadata to submit to server, but use optimistic moments
type Props = {
  playlist: Playlist;
  setStopEditing: () => void;
  optimisticMoments: PlaylistMoment[];
  setOptimisticMoments: React.Dispatch<
    React.SetStateAction<PlaylistMoment[] | null>
  >;
};

type EditPlaylistActions =
  | { type: "STOP_EDITING" }
  | { type: "UNDO_CHANGES" }
  | { type: "UPDATE_MOMENTS"; moments: PlaylistMoment[] }
  | { type: "SUBMIT_CHANGES" }
  | { type: "SUBMISSION_ERROR" }
  | { type: "DISMISS_ERROR" }
  | { type: "SUBMISSION_SUCCESS" };

const reducer = (state: State, action: EditPlaylistActions): State => {
  switch (state.status) {
    case "editing":
      switch (action.type) {
        case "UPDATE_MOMENTS":
          return {
            ...state,
            moments: action.moments
          };

        case "UNDO_CHANGES":
          return {
            ...state,
            moments: state.originalMoments
          };

        case "SUBMIT_CHANGES":
          return {
            ...state,
            status: "submitting"
          };

        default:
          return state;
      }

    case "submitting":
      switch (action.type) {
        case "SUBMISSION_SUCCESS":
          return {
            ...state,
            status: "success"
          };

        case "SUBMISSION_ERROR":
          return {
            ...state,
            status: "error"
          };

        default:
          return state;
      }

    case "error":
      switch (action.type) {
        case "DISMISS_ERROR":
          return {
            ...state,
            status: "editing"
          };

        default:
          return state;
      }

    case "success":
      switch (action.type) {
        default:
          return state;
      }

    default:
      return state;
  }
};

const EditPlaylist: FC<Props> = ({
  playlist,
  setStopEditing,
  optimisticMoments,
  setOptimisticMoments
}) => {
  const [state, dispatch] = useReducer(reducer, {
    status: "editing",
    moments: optimisticMoments,
    originalMoments: optimisticMoments
  });

  const userAccountState = useStoreState(userAccountStore);

  useEffect(() => {
    if (state.status === "submitting") {
      fetch(
        `https://podsoda-server.herokuapp.com/playlist/update-moments/${playlist.id}`,
        {
          method: "POST",
          headers: {
            Authorization: `bearer ${userAccountState.token}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            momentIds: state.moments.map(
              playlistMoment => playlistMoment.moment.id
            )
          })
        }
      )
        .then(response => {
          if (!response.ok) {
            throw Error(response.statusText);
          }
          return response.json();
        })
        .then(_ => dispatch({ type: "SUBMISSION_SUCCESS" }))
        .catch(_ => dispatch({ type: "SUBMISSION_ERROR" }));
    }

    if (state.status === "success") {
      setOptimisticMoments(state.moments);
      setStopEditing();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.status]);

  const unselectMoment = (playlistMoment: PlaylistMoment) => {
    dispatch({
      type: "UPDATE_MOMENTS",
      moments: state.moments.filter(
        statePlaylistMoment =>
          statePlaylistMoment.id !== playlistMoment.moment.id
      )
    });
  };

  const onDragEnd = (result: DropResult, moments: PlaylistMoment[]) => {
    if (!result.destination) {
      return;
    } else {
      if (result.source.droppableId === result.destination.droppableId) {
        const newSelectedMoments = moments.filter(
          moment => moment.moment.id !== result.draggableId
        );
        const sourceMoment = moments.find(
          moment => moment.moment.id === result.draggableId
        );
        if (sourceMoment) {
          newSelectedMoments.splice(result.destination.index, 0, sourceMoment);
        }
        dispatch({ type: "UPDATE_MOMENTS", moments: newSelectedMoments });
      }
    }
  };

  if (state.status === "submitting")
    return (
      <div>
        <p className="text-center text-xl font-bold mx-8">
          Submitting playlist changes...
        </p>
      </div>
    );

  return (
    <div>
      {state.status === "error" && (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative w-full my-4"
          role="alert"
        >
          <strong className="font-bold">Error saving playlist</strong>

          <span
            className="absolute top-0 bottom-0 right-0 px-4 py-3"
            onClick={() => dispatch({ type: "DISMISS_ERROR" })}
          >
            <svg
              className="fill-current h-6 w-6 text-red-500"
              role="button"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <title>Close</title>
              <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
            </svg>
          </span>
        </div>
      )}

      <div className="flex flex-row justify-center">
        <button
          className="bg-red-500 hover:bg-red-700 text-white font-bold my-2 mx-2 py-1 px-2 rounded-full focus:outline-none"
          onClick={() => dispatch({ type: "UNDO_CHANGES" })}
        >
          Undo Changes
        </button>

        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold my-2 mx-2 py-1 px-2 rounded-full  focus:outline-none"
          onClick={() => {
            dispatch({ type: "SUBMIT_CHANGES" });
          }}
        >
          Submit Changes
        </button>
      </div>

      <DragDropContext
        onDragStart={() => {
          // Add a little vibration if the browser supports it.
          // Adds a nice little physical feedback
          if (window.navigator.vibrate) {
            window.navigator.vibrate(100);
          }
        }}
        onDragEnd={result => onDragEnd(result, state.moments)}
      >
        <Droppable key="selectedMoments" droppableId="selectedMoments">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{
                background: snapshot.isDraggingOver ? "lightgrey" : "lightgrey",
                padding: 4,

                minHeight: 100
              }}
              className="w-full"
            >
              {" "}
              {state.moments.map((playlistMoment, index) => (
                <Draggable
                  key={playlistMoment.moment.id}
                  draggableId={playlistMoment.moment.id}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        userSelect: "none",

                        margin: 4,

                        ...provided.draggableProps.style
                      }}
                    >
                      <SearchResult
                        link=""
                        noLink={true}
                        imageUrl={playlistMoment.moment.thumbnail}
                        primaryText={playlistMoment.moment.momentTitle}
                        secondaryText={playlistMoment.moment.podcastName}
                        tertiaryText={formatTimestamp(
                          parseFloat(playlistMoment.moment.endTime) -
                            parseFloat(playlistMoment.moment.startTime)
                        )}
                        TopRightElement={
                          <button
                            className="bg-red-500 hover:bg-red-700 text-white font-bold my-2 py-1 px-2 rounded-full focus:outline-none"
                            onClick={() => unselectMoment(playlistMoment)}
                          >
                            remove
                          </button>
                        }
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default EditPlaylist;
