import React, { FC } from "react";

type Props = {
  retry: () => void;
};

const PodSodaError: FC<Props> = ({ retry }) => {
  return (
    <div>
      <p className="my-8 text-gray-700 text-center font-semibold text-2xl">
        Failed to load content :(
      </p>
      {/* <button onClick={retry}>retry</button> */}
    </div>
  );
};

export default PodSodaError;
