import React, { FC, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { usePodSodaFetch } from "../../hooks";
import { Moment } from "../../types/moments";
import PodSodaError from "../shared/PodSodaError";
import List from "../shared/List";
import SearchResult from "../shared/SearchResult";
import ShimmerSearchResult from "../shared/ShimmerSearchResult";

const limit = 10;

const MomentsByTag: FC = () => {
  const { tag } = useParams();

  const [moments, setMoments] = useState<Moment[]>([]);
  const [loadingMore, setLoadingMore] = useState(false);

  const { loading, error, data, retry } = usePodSodaFetch<[Moment[], number]>(
    `/moment/hashtag/${tag}?limit=${limit}&offset=0`,
    [tag]
  );

  useEffect(() => {
    if (data) {
      setMoments(data[0]);
    }
  }, [data]);

  const handleLoadMore = () => {
    setLoadingMore(true);
    fetch(
      `https://podsoda-server.herokuapp.com/moment/hashtag/${tag}?limit=${limit}&offset=${moments.length}`
    )
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((data: [Moment[], number]) => {
        setMoments([...moments, ...data[0]]);
        setLoadingMore(false);
      })
      .catch(_ => {
        setLoadingMore(false);
      });
  };

  if (loading)
    return (
      <div className="flex flex-col items-center">
        <div className="w-10/12 mt-8 flex flex-col">
          <h3 className="text-center text-xl font-bold my-4 text-blue-500 hover:text-blue-700">
            #{tag}
          </h3>

          <List
            items={Array(10).fill(0)}
            RenderItem={item => <ShimmerSearchResult hasTertiaryText={true} />}
            keyExtractor={(item, i) => i!.toString()}
          />
        </div>
      </div>
    );
  if (error) return <PodSodaError retry={retry} />;

  if (data)
    return (
      <div className="flex flex-col items-center">
        <div className="w-10/12 mt-8 flex flex-col">
          <h3 className="text-center text-xl font-bold my-4 text-blue-500 hover:text-blue-700">
            #{tag}
          </h3>

          <List
            items={moments}
            keyExtractor={moment => moment.id}
            RenderItem={moment => (
              <SearchResult
                imageUrl={moment.thumbnail}
                primaryText={moment.momentTitle}
                secondaryText={moment.podcastName}
                tertiaryText={`created by @${moment.username}`}
                link={`/moments/${moment.id}`}
              />
            )}
            RenderEmpty={() => (
              <div className="border p-4">
                <p className="text-sm text-gray-600 font-semibold">
                  No moments with this tag
                </p>
              </div>
            )}
          />
          {moments.length < data[1] ? (
            loadingMore ? (
              <div className="w-full p-2 text-gray-500 text-sm ">
                <p>loading...</p>
              </div>
            ) : (
              <div
                onClick={handleLoadMore}
                className="w-full cursor-pointer p-2 text-gray-500 hover:text-gray-700 text-sm"
              >
                <p>load more</p>
              </div>
            )
          ) : null}
        </div>
      </div>
    );

  return <PodSodaError retry={retry} />;
};

export default MomentsByTag;
