import React, { FC } from "react";
import List from "../shared/List";

const ShimmerDiscoverMoments: FC = () => {
  return (
    <List
      items={Array(10).fill(0)}
      RenderItem={item => (
        <div className="flex flex-row my-6 w-full mx-2">
          <div className="h-24 w-24  bg-gray-300 rounded" />
          {/* 
          <div className="flex flex-col ml-6 h-24 md:ml-4 w-full max-w-md bg-gray-200 " /> */}

          <div className="flex flex-col ml-6 md:ml-4  truncate">
            <div className="h-4 w-24 bg-gray-200 rounded" />
            <div className="h-6 w-40 my-2 bg-gray-200 rounded" />
            <div className="h-4 w-32 bg-gray-200 rounded" />
          </div>
        </div>
      )}
      noBorder={true}
      keyExtractor={(item, i) => i!.toString()}
    />
  );
};

export default ShimmerDiscoverMoments;
