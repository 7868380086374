import React, { FC } from "react";
import { useParams } from "react-router-dom";
import { usePodSodaFetch } from "../../hooks";
import { IProfile } from "../../types/moments";
import PodSodaError from "../shared/PodSodaError";
import { useStoreState } from "react-svelte-stores/build";
import userAccountStore from "../../stores/userAccount";
import YourProfile from "./components/YourProfile";
import PublicProfile from "./components/PublicProfile";
import { User } from "react-feather";

const UserProfile: FC = () => {
  const { username } = useParams();

  const userAccountState = useStoreState(userAccountStore);

  const { loading, error, data: profile, retry } = usePodSodaFetch<IProfile>(
    `/users/profile/${username}?userId=${
      userAccountState.hasAccount ? userAccountState.userId : "anon"
    }`,
    [username]
  );

  if (loading) {
    return (
      <div className="flex flex-col items-center">
        <div className="w-10/12 mt-8 flex flex-col">
          <div className="flex flex-col items-center self-center w-3/5">
            <div className="bg-white p-2 rounded-full border self-center">
              <User />
            </div>
            <h3 className="text-center text-xl font-bold my-4 text-blue-500 hover:text-blue-700">
              @{username}
            </h3>

            <div className="flex flex-col mt-8 mb-4">
              <p className="appearance-none block w-full bg-gray-200 text-gray-500 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white">
                {"Loading..."}
              </p>
            </div>

            <div className="flex flex-row justify-around items-center w-full my-2 p-2 border rounded">
              <div
                style={{ height: 44, width: 72 }}
                className="bg-gray-300 p-4 rounded-full"
              />
              <div
                style={{ height: 44, width: 72 }}
                className="bg-gray-300 p-4 rounded-full"
              />

              <div
                style={{ height: 44, width: 72 }}
                className="bg-gray-300 p-4 rounded-full"
              />
            </div>

            <div className="flex flex-row justify-around items-center w-full p-2 border rounded">
              <div
                style={{ height: 44, width: 72 }}
                className="bg-gray-300 p-4 rounded-full"
              />
              <div
                style={{ height: 44, width: 72 }}
                className="bg-gray-300 p-4 rounded-full"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) return <PodSodaError retry={retry} />;

  if (profile) {
    if (profile.id === userAccountState.userId) {
      return <YourProfile profile={profile} />;
    } else {
      return <PublicProfile profile={profile} />;
    }
  }
  return <PodSodaError retry={retry} />;
};

export default UserProfile;
