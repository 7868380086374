import React, { FC } from "react";
import { usePodSodaFetch } from "../../hooks";
import PodSodaError from "../shared/PodSodaError";
import { Moment } from "../../types/moments";
import { Link } from "react-router-dom";
import DiscoverMoments from "./DiscoverMoments";
import { TrendingUp } from "react-feather";

export interface TopTagMoments {
  tag: string;
  moments: Moment[];
}

const TrendingMomentsByTag: FC = () => {
  const { loading, error, data: topTagsMoments, retry } = usePodSodaFetch<
    TopTagMoments[]
  >("/moment/front-page");

  if (loading)
    return (
      <div className="mx-2">
        <div className="flex flex-row items-center mt-12 mb-6">
          <h2 className="font-semibold text-left text-3xl mr-6">
            Trending Tags
          </h2>
          <TrendingUp size={48} color="#647ACB" />
        </div>
      </div>
    );
  if (error) return <PodSodaError retry={retry} />;

  if (topTagsMoments) {
    return (
      <div className="mx-2">
        <div className="flex flex-row items-center mt-12 mb-6">
          <h2 className="font-semibold text-left text-3xl mr-3">
            Trending Tags
          </h2>
          <TrendingUp size={48} color="#647ACB" />
        </div>

        {topTagsMoments.map(topTagMoments => (
          <div key={topTagMoments.tag}>
            <div className="flex flex-row justify-between items-baseline border-b pb-1">
              <h3 className="font-semibold text-xl">#{topTagMoments.tag}</h3>
              <Link to={`/moments/tag/${topTagMoments.tag}`}>
                <button className="font-semibold text-sm text-blue-500 hover:text-blue-700 focus:outline-none">
                  View All
                </button>
              </Link>
            </div>

            <DiscoverMoments moments={topTagMoments.moments} />
          </div>
        ))}
      </div>
    );
  }
  return <PodSodaError retry={retry} />;
};

export default TrendingMomentsByTag;
