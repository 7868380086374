import React, { FC } from "react";
import { useParams, Link } from "react-router-dom";
import { useListenApiFetch } from "../../hooks";
import { ListenEpisodeDetailsResults } from "../../types/listenApi";
import PodSodaError from "../shared/PodSodaError";
import CommentSection from "../shared/CommentSection";
import EpisodePlayButton from "../Podcasts/EpisodePlayButton";
import { useSelectedStoreState } from "react-svelte-stores/build";
import striptags from "striptags";
// @ts-ignore
import ShowMore from "react-show-more";
import EpisodeMoments from "./EpisodeMoments";
import { playerStore } from "../shared/NewPlayer";

const EpisodesById: FC = () => {
  const { id } = useParams();

  const { loading, error, data: episode, retry } = useListenApiFetch<
    ListenEpisodeDetailsResults
  >(`/episodes/${id}`, [id]);

  const selected = useSelectedStoreState(
    playerStore,
    state => state.metadata?.id === id,
    [id]
  );

  if (loading)
    return (
      <div className="flex flex-col items-center">
        <div className="w-10/12 mt-8 flex flex-col">
          <div className="flex flex-col items-center md:items-start md:flex-row mb-6">
            <div className="flex w-40 flex-col items-center">
              <div className="h-32 w-32 bg-gray-400 rounded mb-2" />
              <div className="w-24 h-8 rounded-full bg-gray-300" />
            </div>
            <div className="mt-2 md:mt-0 flex w-full h-32 flex-col mx-2 bg-gray-200 rounded" />
          </div>
        </div>
      </div>
    );
  if (error) return <PodSodaError retry={retry} />;

  if (episode && id) {
    return (
      <div className="flex flex-col items-center">
        <div className="w-10/12 mt-8 flex flex-col">
          <div className="flex flex-col items-center md:items-start md:flex-row mb-6">
            <div className="flex w-40 flex-col items-center">
              <img
                src={episode.thumbnail}
                alt=""
                className="h-32 w-32 object-cover rounded mb-2"
              />

              <EpisodePlayButton
                episode={episode}
                podcastId={episode.podcast.id}
                podcastName={episode.podcast.title}
                genre_ids={episode.podcast.genre_ids}
              />
            </div>

            <div className="mt-2 md:mt-0 flex w-full flex-col mx-2">
              <p className="text-md text-blue-500 hover:text-blue-700">
                <Link to={`/podcasts/${episode.podcast.id}`}>
                  {striptags(episode.podcast.title)}
                </Link>
              </p>

              <p className="text-xl font-semibold">
                {striptags(episode.title)}
              </p>
              <p className="text-gray-600 text-sm mt-2">
                <ShowMore
                  lines={3}
                  more="Show more"
                  less="Show less"
                  anchorClass="text-blue-500 hover:text-blue-700"
                >
                  {striptags(episode.description)}
                </ShowMore>
              </p>
            </div>
          </div>

          <EpisodeMoments episodeId={id} />

          {selected ? (
            <SelectedEpisodeCommentSection episodeId={id} />
          ) : (
            <CommentSection type="episode" id={id} timestamp={0} />
          )}
        </div>
      </div>
    );
  }

  return <PodSodaError retry={retry} />;
};

export default EpisodesById;

/** This component exists to prevent rerenders if the episode is not the currently playing episode */
const SelectedEpisodeCommentSection: FC<{ episodeId: string }> = ({
  episodeId
}) => {
  const timestamp = useSelectedStoreState(
    playerStore,
    state => state.timestamp
  );

  return <CommentSection type="episode" id={episodeId} timestamp={timestamp} />;
};
