import React, { FC } from "react";
import { useSearch } from "../../hooks";
import { listenNotesFetch } from "../../utils";
import SearchBar from "../shared/SearchBar";
import SearchComponent from "../shared/SearchComponent";
import { ListenSearchPodcastsResults } from "../../types/listenApi";
// import { Link } from "react-router-dom";
import SearchResult from "../shared/SearchResult";
import ShimmerSearchResult from "../shared/ShimmerSearchResult";
import List from "../shared/List";
import CuratedLists from "./CuratedLists";

const Podcasts: FC = () => {
  const {
    loading,
    searching,
    searchResults,
    searchTerm,
    setSearchTerm
  } = useSearch<ListenSearchPodcastsResults>(
    listenNotesFetch,
    term => `/search?type=podcast&q=${term}`
  );

  return (
    <div className="flex flex-col items-center">
      <br />
      <SearchBar
        value={searchTerm}
        onChangeText={setSearchTerm}
        placeholder="Search Podcasts"
      />

      <div className="w-10/12 mt-8">
        <SearchComponent
          loading={loading}
          results={searchResults ? searchResults.results : []}
          searching={searching}
          RenderLoading={() => (
            <List
              items={Array(10).fill(0)}
              RenderItem={item => (
                <ShimmerSearchResult hasTertiaryText={true} />
              )}
              keyExtractor={(item, i) => i!.toString()}
            />
          )}
          // put featured podcasts here
          RenderDefaultContent={() => <CuratedLists />}
          RenderItem={item => (
            <SearchResult
              link={`/podcasts/${item.id}`}
              imageUrl={item.thumbnail}
              primaryText={item.title_original}
              secondaryText={item.publisher_original}
              tertiaryText={`${item.total_episodes} episodes`}
            />
          )}
          keyExtractor={item => item.id}
        />
      </div>
    </div>
  );
};

export default Podcasts;
