import React, { FC, useRef, useEffect } from "react";
import { useStoreState, writable, get } from "react-svelte-stores/build";
import {
  EpisodeMetadata,
  ListenEpisodeDetailsResults
} from "../../types/listenApi";
import listeningHistory from "../../stores/listeningHistory";
import { Play, Pause, Scissors } from "react-feather";
import { Link } from "react-router-dom";
import { formatTimestamp } from "../../utils/time";
import { Range, getTrackBackground } from "react-range";
import { Subject } from "rxjs";
import { skip } from "rxjs/operators";
import { detect } from "detect-browser";

// store state in a react-svelte-store so events can be sent from other components
// might use an XState service?

// the reducer has lots of repetition bc its essentially a
// "denormalized" statechart represented as an FSM.
// would be simpler if i could express orthogonal and hierarchical states

type State = {
  // status (player) and dragging status are orthogonal states!
  // discriminate between hydrating and loading (from user action) bc autoplay is disabled!
  status:
    | "idle"
    | "loading"
    | "playing"
    | "paused"
    | "remote_seeking"
    | "fetching_podcast"
    | "error";
  draggingStatus: "idle" | "dragging";
  metadata: EpisodeMetadata | null;
  timestamp: number;
  sliderTime: number;
};
const initialState: State = {
  status: "idle",
  draggingStatus: "idle",
  metadata: null,
  timestamp: 0,
  sliderTime: 0
};

type Action =
  | { type: "SET_PODCAST"; metadata: EpisodeMetadata; timestamp?: number }
  | { type: "LOADED" }
  | { type: "PLAY" }
  | { type: "PAUSE" }
  | { type: "UPDATE_TIMESTAMP"; timestamp: number }
  | { type: "REMOTE_SEEK"; timestamp: number }
  | { type: "FETCHING_PODCAST" }
  | { type: "FINISHED_SEEK" }
  | { type: "REMOVE_PODCAST" }
  | { type: "ERROR" }
  | { type: "DISMISS_ERROR" }
  | { type: "DRAG_START" }
  | { type: "DRAG_UPDATE"; timestamp: number }
  | { type: "DRAG_END"; timestamp: number };

const playerReducer = (state: State, action: Action): State => {
  switch (state.status) {
    case "idle":
      switch (action.type) {
        case "SET_PODCAST":
          const listeningHistoryState = get(listeningHistory);

          // first priority is action timestamp (for comment links), then history
          const timestamp = action.timestamp
            ? action.timestamp
            : listeningHistoryState.podcasts[action.metadata.id]
            ? listeningHistoryState.podcasts[action.metadata.id].position
            : 0;

          return {
            ...state,
            status: "loading",
            metadata: action.metadata,
            timestamp: timestamp
          };

        default:
          return state;
      }

    case "loading":
      switch (action.type) {
        case "SET_PODCAST":
          const listeningHistoryState = get(listeningHistory);

          // first priority is action timestamp (for comment links), then history
          const timestamp = action.timestamp
            ? action.timestamp
            : listeningHistoryState.podcasts[action.metadata.id]
            ? listeningHistoryState.podcasts[action.metadata.id].position
            : 0;

          return {
            ...state,
            status: "loading",
            metadata: action.metadata,
            timestamp: timestamp
          };

        case "REMOTE_SEEK":
          return {
            ...state,
            status: "remote_seeking",
            timestamp: action.timestamp
          };

        case "FETCHING_PODCAST":
          return {
            ...state,
            status: "fetching_podcast"
          };

        case "LOADED":
          const browser = detect();

          if (browser?.os === "iOS") {
            return {
              ...state,
              status: "paused"
            };
          }

          return {
            ...state,
            // might have to change to paused if it doesn't work on some browsers?
            status: "playing"
          };

        case "ERROR":
          return {
            ...state,
            status: "error"
          };

        default:
          return state;
      }

    case "playing":
      switch (action.type) {
        case "SET_PODCAST":
          const listeningHistoryState = get(listeningHistory);

          // first priority is action timestamp (for comment links), then history
          const timestamp = action.timestamp
            ? action.timestamp
            : listeningHistoryState.podcasts[action.metadata.id]
            ? listeningHistoryState.podcasts[action.metadata.id].position
            : 0;

          return {
            ...state,
            status: "loading",
            metadata: action.metadata,
            timestamp: timestamp
          };

        case "REMOTE_SEEK":
          return {
            ...state,
            status: "remote_seeking",
            timestamp: action.timestamp
          };

        case "FETCHING_PODCAST":
          return {
            ...state,
            status: "fetching_podcast"
          };

        case "PAUSE":
          return {
            ...state,
            status: "paused"
          };

        case "DRAG_START":
          return {
            ...state,
            draggingStatus: "dragging"
          };

        case "DRAG_UPDATE":
          return {
            ...state,
            sliderTime: action.timestamp
          };

        case "DRAG_END":
          return {
            ...state,
            draggingStatus: "idle"
          };

        case "UPDATE_TIMESTAMP":
          // don't interfere with slider time while dragging
          if (state.draggingStatus === "dragging") {
            return {
              ...state,
              timestamp: action.timestamp
            };
          } else {
            return {
              ...state,
              timestamp: action.timestamp,
              sliderTime: action.timestamp
            };
          }

        case "ERROR":
          return {
            ...state,
            status: "error"
          };

        default:
          return state;
      }

    case "paused":
      switch (action.type) {
        case "SET_PODCAST":
          const listeningHistoryState = get(listeningHistory);

          // first priority is action timestamp (for comment links), then history
          const timestamp = action.timestamp
            ? action.timestamp
            : listeningHistoryState.podcasts[action.metadata.id]
            ? listeningHistoryState.podcasts[action.metadata.id].position
            : 0;

          return {
            ...state,
            status: "loading",
            metadata: action.metadata,
            timestamp: timestamp
          };

        case "REMOTE_SEEK":
          return {
            ...state,
            status: "remote_seeking",
            timestamp: action.timestamp
          };

        case "FETCHING_PODCAST":
          return {
            ...state,
            status: "fetching_podcast"
          };

        case "PLAY":
          return {
            ...state,
            status: "playing"
          };

        case "DRAG_START":
          return {
            ...state,
            draggingStatus: "dragging"
          };

        case "DRAG_UPDATE":
          return {
            ...state,
            sliderTime: action.timestamp
          };

        case "DRAG_END":
          return {
            ...state,
            draggingStatus: "idle"
          };

        case "UPDATE_TIMESTAMP":
          // don't interfere with slider time while dragging
          if (state.draggingStatus === "dragging") {
            return {
              ...state,
              timestamp: action.timestamp
            };
          } else {
            return {
              ...state,
              timestamp: action.timestamp,
              sliderTime: action.timestamp
            };
          }
        case "ERROR":
          return {
            ...state,
            status: "error"
          };

        default:
          return state;
      }

    case "remote_seeking":
      switch (action.type) {
        case "FINISHED_SEEK":
          return {
            ...state,
            status: "paused"
          };

        case "FETCHING_PODCAST":
          return {
            ...state,
            status: "fetching_podcast"
          };

        case "ERROR":
          return {
            ...state,
            status: "error"
          };

        default:
          return state;
      }

    case "fetching_podcast":
      switch (action.type) {
        case "SET_PODCAST":
          const listeningHistoryState = get(listeningHistory);

          // first priority is action timestamp (for comment links), then history
          const timestamp = action.timestamp
            ? action.timestamp
            : listeningHistoryState.podcasts[action.metadata.id]
            ? listeningHistoryState.podcasts[action.metadata.id].position
            : 0;

          return {
            ...state,
            status: "loading",
            metadata: action.metadata,
            timestamp: timestamp,
            sliderTime: timestamp
          };

        case "ERROR":
          return {
            ...state,
            status: "error"
          };

        default:
          return state;
      }

    case "error":
      switch (action.type) {
        case "DISMISS_ERROR":
          return {
            ...state,
            status: "idle"
          };

        default:
          return state;
      }

    default:
      return state;
  }
};

const createPlayerStore = (
  initialState: State,
  reducer: (state: State, action: Action) => State
) => {
  //   const { subscribe, update } = persisted(initialState, "playerReducerStore");
  const { subscribe, update } = writable(initialState);

  const dispatch = (action: Action) => update(state => reducer(state, action));

  return {
    subscribe,
    dispatch: dispatch,
    // for setting episode and timestamp from comment
    loadAndSeekTo: (episodeId: string, timestamp: number) => {
      dispatch({ type: "FETCHING_PODCAST" });

      fetch(`https://podsoda-showcase.now.sh/api/listen/episodes/${episodeId}`)
        .then(response => {
          if (!response.ok) {
            throw Error(response.statusText);
          }
          return response.json();
        })
        .then((data: ListenEpisodeDetailsResults) => {
          dispatch({
            type: "SET_PODCAST",
            metadata: {
              ...data,
              podcastId: data.podcast.id,
              podcastName: data.podcast.title,
              genre_ids: data.podcast.genre_ids
            },
            timestamp: timestamp
          });
        })
        .catch(_ => {
          dispatch({ type: "ERROR" });
        });
    }
  };
};

export const playerStore = createPlayerStore(initialState, playerReducer);

// custom persistence logic

const playerStore$ = new Subject<State>();

// skip the default state
playerStore$.pipe(skip(1)).subscribe(state => {
  try {
    if (state) {
      localStorage.setItem("playerStore", JSON.stringify(state));
    }
  } catch (error) {
    console.log(error);
  }
});

playerStore.subscribe(state => playerStore$.next(state));

const NewPlayer: FC = () => {
  const playerState = useStoreState(playerStore);

  const audio = useRef<HTMLAudioElement>(null);

  // hydrate NOT on safari..
  useEffect(() => {
    const browser = detect();
    if (browser?.name !== "safari" && browser?.os !== "iOS") {
      try {
        const serializedState = localStorage.getItem("playerStore");

        if (serializedState) {
          const state: State = JSON.parse(serializedState);

          if (
            state.status !== "idle" &&
            state.status !== "error" &&
            state.metadata
          ) {
            playerStore.dispatch({
              type: "SET_PODCAST",
              metadata: state.metadata,
              timestamp: state.timestamp
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  useEffect(() => {
    if (playerState.status === "loading") {
      if (audio.current && playerState.metadata) {
        audio.current.src =
          playerState.metadata.audio + `#t=${playerState.timestamp}`;
        audio.current.load();
      }
    }

    if (playerState.status === "idle") {
      // this means that it was previously active, so unload audio element and stop rendering
    }

    if (playerState.status === "paused") {
      audio.current?.pause();
    }

    if (playerState.status === "playing") {
      audio.current?.play();
    }

    if (playerState.status === "remote_seeking") {
      if (audio.current) {
        audio.current.currentTime = playerState.timestamp;
        playerStore.dispatch({ type: "FINISHED_SEEK" });
      }
    }

    if (playerState.status === "fetching_podcast") {
    }

    // side effects ("actions") that run on each state transition
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerState.status]);

  useEffect(() => {
    console.log(playerState.draggingStatus);
    if (playerState.draggingStatus === "idle") {
      // this means that it was previously dragging, so set current time
      if (audio.current) {
        audio.current.currentTime = playerState.sliderTime;
      }
    }

    // side effects ("actions") that run on each state transition
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerState.draggingStatus]);

  if (playerState.status === "idle") return null;

  if (playerState.status === "error")
    return (
      <div className=" z-20 px-2 border-t flex flex-row items-center justify-between  h-16 w-full bg-gray-200">
        <p>error</p>
        <button onClick={() => playerStore.dispatch({ type: "DISMISS_ERROR" })}>
          dismiss
        </button>
      </div>
    );

  return (
    <div className=" z-20 px-2 border-t flex flex-row items-center justify-between  h-16 w-full bg-gray-200">
      {audio.current &&
      (playerState.status === "playing" || playerState.status === "paused") ? (
        <div className="absolute w-full " style={{ top: 0, left: 0 }}>
          <Range
            step={0.1}
            min={0}
            max={audio.current.duration}
            values={[playerState.sliderTime]}
            onChange={values => {
              if (playerState.draggingStatus === "idle") {
                playerStore.dispatch({ type: "DRAG_START" });
              }
              playerStore.dispatch({
                type: "DRAG_UPDATE",
                timestamp: values[0]
              });
            }}
            onFinalChange={values =>
              playerStore.dispatch({ type: "DRAG_END", timestamp: values[0] })
            }
            renderTrack={({ props, children }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "3px",
                  width: "100%",
                  // backgroundColor: "#98AEEB"
                  background: getTrackBackground({
                    values: [playerState.sliderTime],
                    min: 0,
                    max: audio.current!.duration,
                    colors: ["#7B93DB", "#BED0F7"]
                  })
                }}
              >
                {children}
              </div>
            )}
            renderThumb={({ props, value, isDragged }) => (
              <div
                {...props}
                className="rounded-full h-4 w-4 bg-white border shadow outline-none"
                style={{
                  ...props.style
                }}
              ></div>
            )}
          />
        </div>
      ) : (
        <></>
      )}

      <audio
        ref={audio}
        src={playerState.metadata?.audio}
        onError={() => playerStore.dispatch({ type: "ERROR" })}
        onCanPlay={() => playerStore.dispatch({ type: "LOADED" })}
        onPlay={() => playerStore.dispatch({ type: "PLAY" })}
        onPause={() => playerStore.dispatch({ type: "PAUSE" })}
        onTimeUpdate={e => {
          playerStore.dispatch({
            type: "UPDATE_TIMESTAMP",
            timestamp: e.currentTarget.currentTime
          });
          if (playerState.metadata) {
            listeningHistory.updatePodcastPosition(
              playerState.metadata.id,
              e.currentTarget.currentTime,
              e.currentTarget.currentTime,
              e.currentTarget.duration
            );
          }
        }}
      />

      <div className="flex flex-row w-full px-0 ml-4 items-center">
        <img
          className="w-12 h-12 object-cover rounded mr-1"
          src={playerState.metadata?.thumbnail}
          alt=""
        />
        <div className="flex flex-col w-full truncate">
          <p className="truncate ... text-xs font-semibold text-gray-700 hover:text-black">
            <Link to={`/episodes/${playerState.metadata?.id}`}>
              {playerState.metadata?.title}
            </Link>
          </p>

          <p className="truncate ... text-xs text-gray-600 hover:text-black">
            <Link to={`/podcasts/${playerState.metadata?.podcastId}`}>
              {playerState.metadata?.podcastName}
            </Link>
          </p>

          {playerState.metadata && (
            <p className="text-blue-700 text-xs">
              {formatTimestamp(
                audio.current && audio.current.duration > 0
                  ? audio.current.duration - audio.current.currentTime
                  : playerState.metadata.audio_length_sec -
                      playerState.timestamp
              )}{" "}
              left
            </p>
          )}
        </div>

        <div className="flex flex-row mx-2">
          <Link
            to={`/wizard/podcast/${playerState.metadata?.podcastId}/episode/${playerState.metadata?.id}?initial=${audio.current?.currentTime}`}
          >
            <div className="border rounded p-1 border-gray-500 hover:border-orange-600">
              <Scissors color="#F9703E" />
            </div>
          </Link>

          {playerState.status === "playing" && (
            <button
              className="mx-2 focus:outline-none border rounded p-1 border-gray-500 hover:border-orange-600"
              onClick={() => playerStore.dispatch({ type: "PAUSE" })}
            >
              <Pause color="#F9703E" />
            </button>
          )}

          {playerState.status === "paused" && (
            <button
              className="mx-2 focus:outline-none border rounded p-1 border-gray-500 hover:border-orange-600"
              onClick={() => playerStore.dispatch({ type: "PLAY" })}
            >
              <Play color="#F9703E" />
            </button>
          )}

          {playerState.status === "loading" ||
          playerState.status === "remote_seeking" ||
          playerState.status === "fetching_podcast" ? (
            <img
              style={{
                width: 34,
                height: 34,
                marginRight: 23
              }}
              className="p-1 border border-gray-500 ml-2 rounded flex-none"
              src={process.env.PUBLIC_URL + "/three-dots.svg"}
              alt="loading"
            />
          ) : (
            <></>
          )}
        </div>
      </div>

      {/* {playerState.status === "paused" ? (
        <button onClick={() => playerStore.dispatch({ type: "PLAY" })}>
          play
        </button>
      ) : (
        <button onClick={() => playerStore.dispatch({ type: "PAUSE" })}>
          pause
        </button>
      )} */}
    </div>
  );
};

export default NewPlayer;
