import React from "react";

interface Props<T> {
  items: T[] | undefined;
  keyExtractor: (item: T, i?: number) => string;
  RenderItem: (item: T) => JSX.Element;
  RenderEmpty?: () => JSX.Element;
  noBorder?: boolean;
}

function List<T>(props: Props<T>) {
  if (props.items && props.items.length > 0) {
    return (
      <ul
        className={props.noBorder ? "flex-1 w-full" : "flex-1 w-full border-t"}
      >
        {props.items.map((item, i) => (
          <li className="flex-1 w-full" key={props.keyExtractor(item, i)}>
            {props.RenderItem(item)}
          </li>
        ))}
      </ul>
    );
  } else {
    return props.RenderEmpty ? props.RenderEmpty() : <p>no items</p>;
  }
}

export default List;
