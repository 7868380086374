import React, { FC, useEffect, lazy, Suspense } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import LoginPage from "../Account/LoginPage";
import { useStoreState } from "react-svelte-stores/build";
import Moments from "../Moments/Moments";
import Podcasts from "../Podcasts/Podcasts";
import CuratedList from "../Podcasts/CuratedList";
import Playlists from "../Playlists/Playlists";
import EpisodeById from "../Episodes/EpisodeById";
import MomentById from "../Moments/MomentById";
import PlaylistById from "../Playlists/PlaylistById";
import PodcastById from "../Podcasts/PodcastById";
import UserProfile from "../Profile/UserProfile";
import Followers from "../Profile/Followers";
import Following from "../Profile/Following";
import MomentLikes from "../Moments/MomentLikes";
import CreatedMoments from "../Profile/CreatedMoments";
import LikedMoments from "../Profile/LikedMoments";
import UserPlaylists from "../Profile/UserPlaylists";
import Home from "../Home/Home";
import MomentsByTag from "../Moments/MomentsByTag";
import AddToPlaylist from "../Playlists/AddToPlaylist";

import Create from "./Create";
import userAccountStore from "../../stores/userAccount";
import WelcomeToast from "../Home/WelcomeToast";
import YourShowcases from "../Showcases/YourShowcases";
import EditShowcase from "../Showcases/EditShowcase";

const LazyWizardRouter = lazy(() => import("../Wizard"));

const LazyCreateShowcase = lazy(() => import("../Showcases/CreateShowcase"));

const LazyCreatePlaylist = lazy(() => import("../Playlists/CreatePlaylist"));

const LazyShare = lazy(() => import("./Share"));

const MainContent: FC = () => {
  const userAccountState = useStoreState(userAccountStore);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="mb-48" style={{ minHeight: "100vh" }}>
      <WelcomeToast />

      <Switch>
        <Route exact path="/">
          <Home />
        </Route>

        <Route path="/wizard">
          <Suspense
            fallback={
              <h1 className="text-2xl text-center font-bold my-12">
                loading...
              </h1>
            }
          >
            <LazyWizardRouter />
          </Suspense>
        </Route>

        <Route path="/share">
          <Suspense
            fallback={
              <h1 className="text-2xl text-center font-bold my-12">
                loading...
              </h1>
            }
          >
            <LazyShare />
          </Suspense>
        </Route>

        <Route path="/create">
          <Create />
        </Route>

        <Route exact path="/podcasts">
          <Podcasts />
        </Route>

        <Route exact path="/podcasts/:id">
          <PodcastById />
        </Route>

        <Route exact path="/podcasts/curated-list/:id">
          <CuratedList />
        </Route>

        <Route exact path="/moments">
          <Moments />
        </Route>

        <Route exact path="/moments/:id">
          <MomentById />
        </Route>

        <Route path="/moments/:id/likes">
          <MomentLikes />
        </Route>

        <Route exact path="/moments/tag/:tag">
          <MomentsByTag />
        </Route>

        <Route exact path="/playlists">
          <Playlists />
        </Route>

        <Route exact path="/playlists/:id">
          <PlaylistById />
        </Route>

        <Route exact path="/playlists/add-to-playlist/:momentId">
          {userAccountState.hasAccount ? (
            <AddToPlaylist />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>

        <Route path="/new-playlist/">
          {userAccountState.hasAccount ? (
            <Suspense
              fallback={
                <h1 className="text-2xl text-center font-bold my-12">
                  loading...
                </h1>
              }
            >
              <LazyCreatePlaylist />
            </Suspense>
          ) : (
            <Redirect to="/login" />
          )}
        </Route>

        <Route path="/episodes/:id">
          <EpisodeById />
        </Route>

        <Route exact path="/user/:username">
          <UserProfile />
        </Route>

        <Route exact path="/user/:username/followers">
          <Followers />
        </Route>

        <Route exact path="/user/:username/following">
          <Following />
        </Route>

        <Route exact path="/user/:username/liked-moments">
          <LikedMoments />
        </Route>

        <Route exact path="/user/:username/created-moments">
          <CreatedMoments />
        </Route>

        <Route exact path="/user/:username/created-playlists">
          <UserPlaylists />
        </Route>

        <Route path="/create-showcase">
          {userAccountState.hasAccount ? (
            <Suspense
              fallback={
                <h1 className="text-2xl text-center font-bold my-12">
                  loading...
                </h1>
              }
            >
              <LazyCreateShowcase />
            </Suspense>
          ) : (
            <Redirect to="/login" />
          )}
        </Route>

        <Route exact path="/your-showcases">
          {userAccountState.hasAccount ? (
            <YourShowcases />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>

        <Route exact path="/edit-showcase/:showcaseId">
          {userAccountState.hasAccount ? (
            <EditShowcase />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>

        <Route path="/login">
          {userAccountState.hasAccount ? <Redirect to="/" /> : <LoginPage />}
        </Route>
      </Switch>
    </div>
  );
};

export default MainContent;
