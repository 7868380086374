import React, { FC, useState } from "react";
import { Moment } from "../../../types/moments";
import { XCircle } from "react-feather";
import Modal from "react-modal";
import TwitterShare from "../../shared/TwitterShare";
import { useCopyToClipboard } from "react-use";
import Tooltip from "rc-tooltip";

type Props = {
  moment: Moment;
  showShareModal: boolean;
  setShowShareModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const ShareModal: FC<Props> = ({
  moment,
  showShareModal,
  setShowShareModal
}) => {
  //   const open = () => setShowShareModal(true);
  const close = () => setShowShareModal(false);

  const [tab, setTab] = useState<"share" | "embed">("share");

  // eslint-disable-next-line no-unused-vars
  const [clipboardState, copyToClipboard] = useCopyToClipboard();

  const [minCopied, setMinCopied] = useState(false);
  const [detCopied, setDetCopied] = useState(false);

  return (
    <div>
      <Modal
        isOpen={showShareModal}
        // onAfterOpen={afterOpenModal}
        onRequestClose={close}
        style={{
          content: {
            maxWidth: 700,
            margin: "0 auto"
          }
        }}
        contentLabel="Share Modal"
      >
        <button className="close-button" onClick={close}>
          <span>
            <XCircle />
          </span>
        </button>
        <div className="flex flex-row justify-start border-b pb-2">
          <button
            className={tab === "share" ? "text-lg font-semibold" : "text-lg "}
            onClick={() => setTab("share")}
          >
            Share
          </button>
          <button
            className={
              tab === "embed" ? "text-lg font-semibold ml-2" : "text-lg ml-2"
            }
            onClick={() => setTab("embed")}
          >
            Embed
          </button>
        </div>

        {tab === "share" ? (
          //   share
          <div className="flex flex-col mt-8">
            <div className="" style={{ width: 32, height: 32 }}>
              <TwitterShare
                momentId={moment.id}
                placement="bottomLeft"
                size={32}
              />
            </div>
          </div>
        ) : (
          // embed
          <div className="">
            <p className="text-2xl font-semibold text-gray-800 mt-4 mb-2">
              Minimalistic
            </p>

            <Tooltip
              placement="right"
              overlay={
                <span>
                  {minCopied ? "Copied to Clipboard!" : "Copy to Clipboard"}
                </span>
              }
            >
              <button
                className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow mb-4"
                onClick={() => {
                  copyToClipboard(
                    `<iframe frameBorder="no" width="100%" scrolling="no" src="https://pod.soda.com.tw/e/2/${moment.id}" height="90"></iframe>`
                  );
                  setMinCopied(true);
                  setTimeout(() => setMinCopied(false), 1000);
                }}
              >
                Copy Minimalistic HTML
              </button>
            </Tooltip>

            <iframe
              frameBorder="no"
              width="100%"
              scrolling="no"
              src={`https://pod.soda.com.tw/e/2/${moment.id}`}
              height="90"
            ></iframe>

            <div className="border-t mt-6" />

            <p className="text-2xl font-semibold text-gray-800 mt-4 mb-2">
              Detailed
            </p>

            <Tooltip
              placement="right"
              overlay={
                <span>
                  {detCopied ? "Copied to Clipboard!" : "Copy to Clipboard"}
                </span>
              }
            >
              <button
                className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow mb-4"
                onClick={() => {
                  copyToClipboard(
                    `<iframe frameBorder="no" width="100%" scrolling="no" src="https://pod.soda.com.tw/e/1/${moment.id}" height="168"></iframe>`
                  );
                  setDetCopied(true);
                  setTimeout(() => setDetCopied(false), 1000);
                }}
              >
                Copy Detailed HTML
              </button>
            </Tooltip>

            <iframe
              title="embed"
              frameBorder="no"
              width="100%"
              scrolling="no"
              height="168px"
              src={`https://pod.soda.com.tw/e/1/${moment.id}`}
            ></iframe>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ShareModal;
