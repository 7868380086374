import React, { FC } from "react";
import { useSearch } from "../../hooks";
import { SearchMoment } from "../../types/moments";
import { podsodaFetch } from "../../utils";
import SearchBar from "../shared/SearchBar";
import SearchComponent from "../shared/SearchComponent";
import SearchResult from "../shared/SearchResult";
import ShimmerSearchResult from "../shared/ShimmerSearchResult";
import List from "../shared/List";
import PopularMoments from "./PopularMoments";

const Moments: FC = () => {
  const {
    searchTerm,
    setSearchTerm,
    searchResults,
    loading,
    searching
  } = useSearch<SearchMoment[]>(
    podsodaFetch,
    term => `/moment/search/${term}?limit=10`
  );

  return (
    <div className="flex flex-col items-center">
      <br />
      <SearchBar
        value={searchTerm}
        onChangeText={setSearchTerm}
        placeholder="Search Moments"
      />
      <div className="w-10/12 mt-8">
        <SearchComponent
          loading={loading}
          searching={searching}
          RenderLoading={() => (
            <List
              items={Array(10).fill(0)}
              RenderItem={item => (
                <ShimmerSearchResult hasTertiaryText={true} />
              )}
              keyExtractor={(item, i) => i!.toString()}
            />
          )}
          // put featured moments here
          RenderDefaultContent={() => <PopularMoments />}
          RenderItem={item => (
            <SearchResult
              link={`/moments/${item.id}`}
              imageUrl={item.thumbnail}
              primaryText={item.momentTitle}
              secondaryText={item.podcastName}
              tertiaryText={`created by @${item.username}`}
            />
          )}
          RenderEmpty={() => <p>No moments found</p>}
          keyExtractor={item => item.id}
          results={searchResults ? searchResults : []}
        />
      </div>
    </div>
  );
};

export default Moments;
