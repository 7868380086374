import React, { FC, useState, useRef, useEffect } from "react";
import { Moment } from "../../../types/moments";
import { formatTimestamp } from "../../../utils/time";
import { Play, Rewind, FastForward, Pause } from "react-feather";

import "./MomentPlayer.css";
import { playerStore } from "../../shared/NewPlayer";
import { useStoreState } from "react-svelte-stores/build";
import { detect } from "detect-browser";

type Props = {
  moment: Moment;
  setTimestamp: React.Dispatch<React.SetStateAction<number>>;
};

// might rewrite w/ xstate.. working well for now!

const browser = detect();

const MomentPlayer: FC<Props> = ({ moment, setTimestamp }) => {
  const [crossedHalfway, setCrossedHalfway] = useState(false);

  const audio = useRef<HTMLAudioElement>(null);

  const playerState = useStoreState(playerStore);

  const [loadingAudio, setLoadingAudio] = useState(true);

  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(1);
  const [currentTime, setCurrentTime] = useState(0);

  const [playing, setPlaying] = useState(false);

  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    setStartTime(parseFloat(moment.startTime));
    setCurrentTime(parseFloat(moment.startTime));
    setEndTime(parseFloat(moment.endTime));
  }, [moment]);

  return (
    <div className="mt-4 mb-2">
      <div className="flex flex-row justify-between items-baseline mb-1">
        <p className="text-xs mx-1" style={{ color: "#52606D" }}>
          {formatTimestamp(currentTime)}
        </p>
        {/* have to disable on iOS bc every audio play has to be "user initiated" */}
        {completed && browser?.os !== "iOS" ? (
          <button
            className="text-xs mx-1 text-blue-500 hover:text-blue-700 focus:outline-none"
            onClick={() => {
              if (playerState.metadata?.id !== moment.episodeId) {
                playerStore.loadAndSeekTo(moment.episodeId, endTime);
              } else {
                playerStore.dispatch({
                  type: "REMOTE_SEEK",
                  timestamp: endTime
                });
              }
            }}
          >
            Finish Podcast
          </button>
        ) : (
          <p className="text-xs mx-1" style={{ color: "#7B93DB" }}>
            {formatTimestamp(endTime)}
          </p>
        )}
      </div>

      <progress
        className="w-full"
        value={(currentTime - startTime) / (endTime - startTime)}
        max={1}
      />

      <div
        id="controls"
        className="flex flex-row items-center justify-center mt-4"
      >
        <Rewind
          className={
            loadingAudio ? "mx-3 cursor-not-allowed" : "mx-3 cursor-pointer"
          }
          fill={loadingAudio ? "lightgray" : "#7B8794"}
          color={loadingAudio ? "lightgray" : "#7B8794"}
          onClick={() => {
            if (audio.current && !loadingAudio) {
              if (audio.current.currentTime - startTime > 5) {
                audio.current.currentTime = audio.current.currentTime - 5;
              } else if (audio.current.currentTime - startTime > 3) {
                audio.current.currentTime = audio.current.currentTime - 3;
              } else {
                audio.current.currentTime = startTime;
                audio.current.pause();
              }
            }
          }}
        />

        {loadingAudio ? (
          <img
            style={{ width: 32, height: 32 }}
            className="mx-3"
            src={process.env.PUBLIC_URL + "/three-dots.svg"}
            alt="loading"
          />
        ) : completed ? (
          <Play
            onClick={() => {
              if (audio.current) {
                audio.current.currentTime = startTime;
                audio.current.play();
                playerStore.dispatch({ type: "PAUSE" });
              }
            }}
            className="mx-3 cursor-pointer"
            fill="#7B8794"
            color="#7B8794"
            size={32}
          />
        ) : playing ? (
          <Pause
            onClick={() => audio.current?.pause()}
            className="mx-3 cursor-pointer"
            fill="#7B8794"
            color="#7B8794"
            size={32}
          />
        ) : (
          <Play
            onClick={() => {
              audio.current?.play();
              playerStore.dispatch({ type: "PAUSE" });
            }}
            className="mx-3 cursor-pointer"
            fill="#7B8794"
            color="#7B8794"
            size={32}
          />
        )}

        <FastForward
          onClick={() => {
            if (audio.current && !loadingAudio) {
              if (endTime - audio.current.currentTime > 5) {
                audio.current.currentTime = audio.current.currentTime + 5;
              } else if (endTime - audio.current.currentTime > 3) {
                audio.current.currentTime = audio.current.currentTime + 3;
              } else {
                audio.current.currentTime = endTime;
                audio.current.pause();
              }
            }
          }}
          className={
            loadingAudio ? "mx-3 cursor-not-allowed" : "mx-3 cursor-pointer"
          }
          fill={loadingAudio ? "lightgray" : "#7B8794"}
          color={loadingAudio ? "lightgray" : "#7B8794"}
        />
      </div>

      <audio
        ref={audio}
        onCanPlay={() => setLoadingAudio(false)}
        onPlay={e => setPlaying(true)}
        onPause={e => setPlaying(false)}
        onTimeUpdate={(e: any) => {
          setCurrentTime(e.nativeEvent.target.currentTime);
          setTimestamp(e.nativeEvent.target.currentTime);

          if (e.nativeEvent.target.currentTime >= endTime) {
            setCompleted(true);
            audio.current?.pause();
          } else {
            setCompleted(false);
          }

          if (crossedHalfway) {
            if (
              e.nativeEvent.target.currentTime <
              (parseFloat(moment.startTime) + parseFloat(moment.endTime)) / 2
            ) {
              setCrossedHalfway(false);
            }
          } else {
            if (
              e.nativeEvent.target.currentTime >
              (parseFloat(moment.startTime) + parseFloat(moment.endTime)) / 2
            ) {
              setCrossedHalfway(true);
              // fail silently, nobody cares
              fetch(
                `https://podsoda-server.herokuapp.com/moment/increment-plays/${moment.id}`,
                {
                  method: "POST"
                }
              )
                .then(response => {
                  if (!response.ok) {
                    throw Error(response.statusText);
                  }
                })
                .catch(_ => {});
            }
          }
        }}
        src={`${moment.audio}#t=${moment.startTime},${moment.endTime}`}
      />
    </div>
  );
};

export default MomentPlayer;
