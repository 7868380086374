import React, { FC } from "react";
import { Link } from "react-router-dom";
import striptags from "striptags";

type Props = {
  imageUrl: string;
  primaryText: string;
  secondaryText: string;
  tertiaryText?: string;
  quaternaryChild?: JSX.Element;
  TopRightElement?: JSX.Element;
  link: string;
  noLink?: boolean;
};

const SearchResult: FC<Props> = ({
  imageUrl,
  primaryText,
  secondaryText,
  tertiaryText,
  quaternaryChild,
  TopRightElement,
  link,
  noLink
}) => {
  if (noLink) {
    return (
      <div className="bg-white w-full  border-b  p-2 overflow-hidden ">
        <div className="flex flex-row w-full">
          <img
            className="h-20 w-20 object-cover rounded-sm"
            src={imageUrl}
            alt="primary"
          />

          <div className="flex flex-col mx-2 truncate w-10/12">
            {tertiaryText && (
              <p className="text-gray-600 hover:text-gray-600 font-semibold uppercase text-xs truncate ... ">
                {striptags(tertiaryText)}
              </p>
            )}
            <p className="font-semibold text-md truncate ...">
              {striptags(primaryText)}
            </p>
            <p className="text-sm text-gray-600 hover:text-gray-600 truncate ...">
              {striptags(secondaryText)}
            </p>
            {quaternaryChild ? quaternaryChild : <div className="w-1" />}
          </div>

          <div className="flex flex-col self-center align-end">
            {TopRightElement && TopRightElement}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="bg-white w-full hover:text-blue-600  border-b  p-2 overflow-hidden ">
        <Link to={link}>
          <div className="flex flex-row w-full">
            <img
              className="h-20 w-20 object-cover rounded-sm"
              src={imageUrl}
              alt="primary"
            />

            <div className="flex flex-col mx-2 truncate w-10/12">
              {tertiaryText && (
                <p className="text-gray-600 hover:text-gray-600 font-semibold uppercase text-xs truncate ... ">
                  {striptags(tertiaryText)}
                </p>
              )}
              <p className="font-semibold text-md truncate ...">
                {striptags(primaryText)}
              </p>
              <p className="text-sm text-gray-600 hover:text-gray-600 truncate ...">
                {striptags(secondaryText)}
              </p>
              {quaternaryChild ? quaternaryChild : <div className="w-1" />}
            </div>

            <div className="flex flex-col self-center align-end">
              {TopRightElement && TopRightElement}
            </div>
          </div>
        </Link>
      </div>
    );
  }
};

export default SearchResult;
