import React, { FC } from "react";
import { useParams } from "react-router-dom";
import { useListenApiFetch } from "../../hooks";
import { CuratedListDetails } from "../../types/listenApi";
import PodSodaError from "../shared/PodSodaError";
import List from "../shared/List";
import SearchResult from "../shared/SearchResult";
import ShimmerSearchResult from "../shared/ShimmerSearchResult";
// @ts-ignore
import ShowMore from "react-show-more";

const CuratedList: FC = () => {
  const { id } = useParams();

  const { loading, error, data, retry } = useListenApiFetch<CuratedListDetails>(
    `/curated_podcasts/${id}`,
    [id]
  );

  if (loading)
    return (
      <div className="flex flex-col items-center">
        <div className="w-10/12 mt-8 flex flex-col">
          <div className="self-center w-3/5">
            <h3 className="text-center text-xl font-bold my-4 text-blue-500">
              Loading...
            </h3>
          </div>
          <List
            items={Array(10).fill(0)}
            RenderItem={item => <ShimmerSearchResult hasTertiaryText={true} />}
            keyExtractor={(item, i) => i!.toString()}
          />
        </div>
      </div>
    );
  if (error) return <PodSodaError retry={retry} />;

  if (data) {
    return (
      <div className="flex flex-col items-center">
        <div className="w-10/12 mt-8 flex flex-col">
          <div className="self-center w-3/5 mt-4 mb-8">
            <h3 className="text-center text-xl font-bold text-blue-500">
              {data.title}
            </h3>
            <p className="text-gray-600 text-sm mt-2">
              <ShowMore
                lines={3}
                more="Show more"
                less="Show less"
                anchorClass="text-blue-500 hover:text-blue-700"
              >
                {data.description}
              </ShowMore>
            </p>
          </div>
          <List
            items={data.podcasts ? data.podcasts : []}
            keyExtractor={podcast => podcast.id}
            RenderItem={podcast => (
              <SearchResult
                imageUrl={podcast.thumbnail}
                primaryText={podcast.title}
                secondaryText={podcast.publisher}
                tertiaryText={podcast.total_episodes.toString()}
                link={`/podcasts/${podcast.id}`}
              />
            )}
          />
        </div>
      </div>
    );
  }
  return <PodSodaError retry={retry} />;
};

export default CuratedList;
