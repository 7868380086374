import React, { FC, useState, useEffect } from "react";
import { usePodSodaFetch } from "../../hooks";
import { Moment } from "../../types/moments";
import PodSodaError from "../shared/PodSodaError";
import List from "../shared/List";
import SearchResult from "../shared/SearchResult";
import ShimmerSearchResult from "../shared/ShimmerSearchResult";

type Props = {
  episodeId: string;
};

const limit = 3;

const EpisodeMoments: FC<Props> = ({ episodeId }) => {
  const { loading, error, data, retry } = usePodSodaFetch<[Moment[], number]>(
    `/moment/by-episode-id/${episodeId}?limit=${limit}&offset=0`
  );

  const [moments, setMoments] = useState<Moment[]>([]);

  const [loadingMore, setLoadingMore] = useState(false);

  useEffect(() => {
    if (data) {
      setMoments(data[0]);
    }
  }, [data]);

  const handleLoadMore = () => {
    setLoadingMore(true);
    fetch(
      `https://podsoda-server.herokuapp.com/moment/by-episode-id/${episodeId}?limit=${limit}&offset=${moments.length}`
    )
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((data: [Moment[], number]) => {
        setMoments(moments => [...moments, ...data[0]]);
        setLoadingMore(false);
      })
      .catch(_ => {});
  };

  if (loading)
    return (
      <div className="mt-4 mb-8 border-t">
        <p className="text-md font-semibold mt-2 mb-2">Loading Moments</p>
        <List
          items={Array(limit).fill(0)}
          RenderItem={item => <ShimmerSearchResult hasTertiaryText={true} />}
          keyExtractor={(item, i) => i!.toString()}
        />
      </div>
    );
  if (error) return <PodSodaError retry={retry} />;

  if (data) {
    return (
      <div className="mt-4 mb-8 border-t">
        <p className="text-md font-semibold mt-2 mb-2">
          {data[1]} {data[1] === 1 ? "Moment" : "Moments"}
        </p>
        {/* <div className="mx-8"> */}
        <List
          items={moments}
          noBorder={true}
          keyExtractor={moment => moment.id}
          RenderItem={moment => (
            <SearchResult
              imageUrl={moment.thumbnail}
              primaryText={moment.momentTitle}
              secondaryText={moment.episodeTitle}
              tertiaryText={`created by @${moment.username}`}
              link={`/moments/${moment.id}`}
            />
          )}
          RenderEmpty={() => (
            <div className="border p-4">
              <p className="text-sm text-gray-600 font-semibold">
                This episode has no moments yet
              </p>
            </div>
          )}
        />
        {moments.length < data[1] ? (
          loadingMore ? (
            <div className="w-full cursor-pointer p-2 px-4 text-gray-500 hover:text-gray-700 text-sm ">
              <p>loading...</p>
            </div>
          ) : (
            <div
              onClick={handleLoadMore}
              className="w-full cursor-pointer p-2 px-4 text-gray-500 hover:text-gray-700 text-sm cursor-pointer"
            >
              <p>load more</p>
            </div>
          )
        ) : null}
        {/* </div> */}
      </div>
    );
  }
  return <PodSodaError retry={retry} />;
};

export default EpisodeMoments;
