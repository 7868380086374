import React, { FC, useState, useLayoutEffect } from "react";
import { Moment } from "../../types/moments";
import { useStoreState } from "react-svelte-stores/build";
import { popularMomentsStore } from "../../stores/cacheStores";
import ShimmerSearchResult from "../shared/ShimmerSearchResult";
import List from "../shared/List";
import PodSodaError from "../shared/PodSodaError";
import SearchResult from "../shared/SearchResult";

const PopularMoments: FC = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [moments, setMoments] = useState<Moment[]>();

  const popularMomentsState = useStoreState(popularMomentsStore);

  useLayoutEffect(() => {
    if (popularMomentsState) {
      setMoments(popularMomentsState);
      setLoading(false);
    } else {
      fetch(`https://podsoda-server.herokuapp.com/moment/popular`)
        .then(response => {
          if (!response.ok) {
            throw Error(response.statusText);
          }
          return response.json();
        })
        .then((data: Moment[]) => {
          setMoments(data);
          popularMomentsStore.set(data);
          setLoading(false);
        })
        .catch(_ => {
          setError(true);
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading)
    return (
      <div className="flex flex-col items-center">
        <h3 className="text-center text-xl font-bold text-blue-500 mb-4">
          Popular Moments
        </h3>
        <List
          items={Array(10).fill(0)}
          RenderItem={item => <ShimmerSearchResult hasTertiaryText={true} />}
          keyExtractor={(item, i) => i!.toString()}
        />
      </div>
    );
  if (error) return <PodSodaError retry={() => {}} />;

  if (moments) {
    return (
      <div className="flex flex-col items-center">
        <h3 className="text-center text-xl font-bold text-blue-500 mb-4">
          Popular Moments
        </h3>

        <List
          items={moments}
          keyExtractor={moment => moment.id}
          RenderItem={moment => (
            <SearchResult
              imageUrl={moment.thumbnail}
              primaryText={moment.momentTitle}
              secondaryText={moment.podcastName}
              tertiaryText={`created by @${moment.username}`}
              link={`/moments/${moment.id}`}
            />
          )}
        />
      </div>
    );
  }
  return <PodSodaError retry={() => {}} />;
};

export default PopularMoments;
