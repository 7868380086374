import React, { FC, useState, FormEvent, useEffect } from "react";
import { IProfile } from "../../../types/moments";
import { Link } from "react-router-dom";
import { useStoreState } from "react-svelte-stores/build";
import userAccountStore from "../../../stores/userAccount";
import List from "../../shared/List";
import SearchResult from "../../shared/SearchResult";
import momentjs from "moment";
import { User } from "react-feather";
import SavedPlaylists from "./SavedPlaylists";
import FavoritePodcasts from "./FavoritePodcasts";
import { firstTime } from "../../../stores/firstTime";

type Props = {
  profile: IProfile;
};

const YourProfile: FC<Props> = ({ profile }) => {
  const [editingBio, setEditingBio] = useState(false);
  const [truthBio, setTruthBio] = useState(profile.bio);
  const [optimisticBio, setOptimisticBio] = useState(profile.bio);

  // honestly, this will never run... how can two different
  // users have the same user id (this component wouldn't be rendered)
  // just writing it for habit
  useEffect(() => {
    setTruthBio(profile.bio);
    setOptimisticBio(profile.bio);
  }, [profile]);

  const userAccountState = useStoreState(userAccountStore);
  // edit bio

  const handleSubmitBio = (e: FormEvent) => {
    e.preventDefault();
    setEditingBio(false);
    if (optimisticBio !== truthBio) {
      fetch(`https://podsoda-server.herokuapp.com/users/bio`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `bearer ${userAccountState.token}`
        },
        body: JSON.stringify({ newBio: optimisticBio })
      })
        .then(response => {
          if (!response.ok) {
            throw Error(response.statusText);
          }
          setTruthBio(optimisticBio);
        })
        .catch(_ => {
          setOptimisticBio(truthBio);
        });
    }
  };

  return (
    <div className="flex flex-col items-center">
      <div className="w-10/12 mt-8 flex flex-col">
        <div className="flex flex-col items-center self-center w-3/5">
          <div className="bg-white p-2 rounded-full border self-center">
            <User />
          </div>
          <h3 className="text-center text-xl font-bold my-4 text-blue-500 hover:text-blue-700">
            @{profile.username}
          </h3>

          {editingBio ? (
            <form onSubmit={handleSubmitBio}>
              <div className="flex flex-col my-2">
                <input
                  value={optimisticBio}
                  onChange={e => setOptimisticBio(e.target.value)}
                  type="text"
                  className="appearance-none block w-full bg-gray-200 text-gray-700  rounded py-3 px-4 mb-2 leading-tight focus:outline-none focus:shadow-outline focus:bg-white"
                  placeholder="Edit bio"
                  maxLength={140}
                />
                <button
                  className="w-48 bg-blue-500 hover:bg-blue-700 text-white font-bold rounded self-center"
                  type="submit"
                >
                  finish editing bio
                </button>
              </div>
            </form>
          ) : (
            <div className="flex flex-col my-2">
              <div
                className="cursor-pointer"
                onClick={() => setEditingBio(true)}
              >
                {optimisticBio ? (
                  <p className="appearance-none block w-full bg-gray-200 text-gray-700 rounded py-3 px-4 mb-2 leading-tight focus:outline-none focus:bg-white">
                    {optimisticBio}
                  </p>
                ) : (
                  <p className="appearance-none block w-full bg-gray-200 text-gray-500 rounded py-3 px-4 mb-2 leading-tight focus:outline-none focus:bg-white">
                    Edit bio
                  </p>
                )}
              </div>
              <button
                className="w-48 bg-blue-500 hover:bg-blue-700 text-white font-bold  rounded self-center"
                onClick={() => setEditingBio(true)}
              >
                edit bio
              </button>
            </div>
          )}

          <div>
            <Link to="/your-showcases">
              <button className="w-48 bg-blue-500 hover:bg-blue-700 text-white font-bold  rounded self-center">
                View your showcases
              </button>
            </Link>
          </div>

          <div className="flex flex-col w-full border rounded sm:flex-row justify-between sm:justify-around items-center my-2 p-2">
            <Link to={`/user/${profile.username}/followers`}>
              <div className="flex flex-col text-blue-500 hover:text-blue-700 my-1 sm:my-0">
                <p className="text-center">{profile.followers_count}</p>
                <p className="text-gray-500 hover:text-gray-700">followers</p>
              </div>
            </Link>

            <Link to={`/user/${profile.username}/following`}>
              <div className="flex flex-col text-blue-500 hover:text-blue-700 my-1 sm:my-0">
                <p className="text-center">{profile.following_count}</p>
                <p className="text-gray-500 hover:text-gray-700">following</p>
              </div>
            </Link>
          </div>

          <div className="flex flex-col w-full border rounded sm:flex-row justify-between sm:justify-around items-center p-2">
            {/* <Link to={`/user/${profile.username}/following`}> */}
            <div className="flex flex-col text-blue-500  my-1 sm:my-0">
              <p className="text-center">{profile.comments_count}</p>
              <p className="text-gray-500 ">comments</p>
            </div>
            {/* </Link> */}
            <Link to={`/user/${profile.username}/created-playlists`}>
              <div className="flex flex-col text-blue-500 hover:text-blue-700 my-1 sm:my-0">
                <p className="text-center">{profile.created_playlists_count}</p>
                <p className="text-gray-500 hover:text-gray-700">playlists</p>
              </div>
            </Link>
            <button
              onClick={() => {
                userAccountStore.logOut();
                firstTime.set(true);
              }}
              className=" bg-transparent mr-2 text-sm focus:outline-none  hover:bg-orange-500 text-orange-700 font-semibold hover:text-white py-1 px-3 border border-red-500 hover:border-transparent rounded-full"
            >
              Log Out
            </button>
          </div>
        </div>

        <div className="flex flex-row justify-between items-baseline mt-8 mb-2">
          <p className="font-semibold">Created Moments</p>
          <Link to={`/user/${profile.username}/created-moments`}>
            <button className="font-semibold text-sm text-blue-500 hover:text-blue-700 focus:outline-none">
              View All
            </button>
          </Link>
        </div>
        <List
          items={profile.moments}
          keyExtractor={moment => moment.id}
          RenderItem={moment => (
            <SearchResult
              imageUrl={moment.thumbnail}
              primaryText={moment.momentTitle}
              secondaryText={moment.podcastName}
              link={`/moments/${moment.id}`}
              tertiaryText={`${momentjs
                .utc(parseInt(moment.startTime) * 1000)
                .format(
                  parseInt(moment.startTime) >= 3600 ? "HH:mm:ss" : "mm:ss"
                )} to ${momentjs
                .utc(parseInt(moment.endTime) * 1000)
                .format(
                  parseInt(moment.endTime) >= 3600 ? "HH:mm:ss" : "mm:ss"
                )}`}
            />
          )}
          RenderEmpty={() => (
            <div className="border p-4">
              <p className="text-sm text-gray-600 font-semibold">
                You haven't created any moments yet
              </p>
            </div>
          )}
        />

        <div className="flex flex-row justify-between items-baseline mt-8 mb-2">
          <p className="font-semibold">Liked Moments</p>
          <Link to={`/user/${profile.username}/liked-moments`}>
            <button className="font-semibold text-sm text-blue-500 hover:text-blue-700 focus:outline-none">
              View All
            </button>
          </Link>
        </div>
        <List
          items={profile.likes}
          keyExtractor={like => like.moment.id}
          RenderItem={like => (
            <SearchResult
              imageUrl={like.moment.thumbnail}
              primaryText={like.moment.momentTitle}
              secondaryText={like.moment.podcastName}
              tertiaryText={`${momentjs
                .utc(parseInt(like.moment.startTime) * 1000)
                .format(
                  parseInt(like.moment.startTime) >= 3600 ? "HH:mm:ss" : "mm:ss"
                )} to ${momentjs
                .utc(parseInt(like.moment.endTime) * 1000)
                .format(
                  parseInt(like.moment.endTime) >= 3600 ? "HH:mm:ss" : "mm:ss"
                )}`}
              link={`/moments/${like.moment.id}`}
            />
          )}
          RenderEmpty={() => (
            <div className="border p-4">
              <p className="text-sm text-gray-600 font-semibold">
                You haven't liked any moments yet
              </p>
            </div>
          )}
        />

        <FavoritePodcasts />

        <SavedPlaylists />
      </div>
    </div>
  );
};

export default YourProfile;
