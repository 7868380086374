import React, { FC, useState } from "react";
import Login from "./Login";
import SignUp from "./SignUp";

const LoginPage: FC = () => {
  const [newUser, setNewUser] = useState(true);

  return (
    <div className="flex flex-col items-center ">
      <div className="w-10/12 mt-8">
        <div className=" flex flex-col items-center lg:flex-row lg:justify-around md:w-full">
          <div className="flex flex-col my-4">
            <h3 className="text-lg text-center font-bold mb-4">
              Sign up for PodSoda to
            </h3>
            <ul className="list-disc">
              <li className="text-gray-700 py-1">
                Keep track of your created moments <span aria-hidden>🤓</span>
              </li>
              <li className="text-gray-700 py-1">
                Create moment playlists <span aria-hidden>💽</span>
              </li>
              <li className="text-gray-700 py-1">
                Like and comment on moments <span aria-hidden>❤</span>
              </li>
              <li className="text-gray-700 py-1">
                Enjoy podcasts, powered by ListenAPI
              </li>
            </ul>
          </div>

          <div className="flex flex-col">
            {newUser ? (
              <button
                className="w-64 focus:outline-none mb-2 bg-transparent text-sm hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                onClick={() => setNewUser(false)}
              >
                Already have an account?
              </button>
            ) : (
              <button
                className="w-64 focus:outline-none mb-2 bg-transparent text-sm hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                onClick={() => setNewUser(true)}
              >
                Don't have an account yet?
              </button>
            )}
            {newUser ? <SignUp /> : <Login />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
