import React, { FC } from "react";
import { ListenFeedEpisodePreview } from "../../types/listenApi";
// import Logger from "../shared/Logger";
import { useSelectedStoreState } from "react-svelte-stores";
import listeningHistory from "../../stores/listeningHistory";
import moment from "moment";

type Props = {
  episode: ListenFeedEpisodePreview;
  podcastId: string;
  podcastName: string;
  genre_ids: number[];
};

const EpisodeSecondsLeft: FC<Props> = ({
  episode,
  podcastId,
  podcastName,
  genre_ids
}) => {
  const progress = useSelectedStoreState(
    listeningHistory,
    state => state.podcasts[episode.id],
    [episode.id]
  );

  return (
    <div className="flex flex-row">
      {progress ? (
        <p className="text-blue-700  text-xs ">
          {progress.duration > 0
            ? moment
                .utc((progress.duration - progress.position) * 1000)
                .format(
                  episode.audio_length_sec >= 3600 ? "HH:mm:ss" : "mm:ss"
                ) + " left"
            : "Loading..."}
        </p>
      ) : (
        <p className="text-blue-700  text-xs ">
          {moment
            .utc(episode.audio_length_sec * 1000)
            .format(episode.audio_length_sec >= 3600 ? "HH:mm:ss" : "mm:ss")}
        </p>
      )}

      {/* <Logger label="infscrollepisode" /> */}
    </div>
  );
};

export default EpisodeSecondsLeft;
