import React, { FC } from "react";
import { Scissors, Share, User, TrendingUp } from "react-feather";
import { useLocation, Link } from "react-router-dom";
import { useStoreState } from "react-svelte-stores/build";
import userAccountStore from "../../stores/userAccount";
import NewPlayer from "./NewPlayer";

const BottomTab: FC = () => {
  const location = useLocation();

  const userAccountState = useStoreState(userAccountStore);

  return (
    <div
      className="fixed border-r border-l  bottom-0   bg-gray-200 flex flex-col"
      style={{
        paddingBottom: "env(safe-area-inset-bottom)",
        width: "100%",
        maxWidth: 1024,
        left: "50%",
        transform: "translateX(-50%)"
      }}
    >
      <NewPlayer />
      <div className="flex flex-row w-full justify-around bg-gray-200 border-t">
        <Link to="/">
          <div className="flex flex-col items-center w-18">
            <TrendingUp
              size={32}
              color={location.pathname === "/" ? "#647ACB" : "#CBD2D9"}
              stroke-width={2}
            />
            <p
              style={{
                color: location.pathname === "/" ? "#647ACB" : "#CBD2D9"
              }}
              className={
                location.pathname === "/"
                  ? "font-semibold text-center"
                  : "font-semibold text-center"
              }
            >
              Discover
            </p>
          </div>
        </Link>

        <Link to="/create">
          <div className="flex flex-col items-center w-18">
            <Scissors
              size={32}
              color={location.pathname === "/create" ? "#647ACB" : "#CBD2D9"}
              stroke-width={2}
            />
            <p
              style={{
                color: location.pathname === "/create" ? "#647ACB" : "#CBD2D9"
              }}
              className={
                location.pathname === "/create"
                  ? "font-semibold text-center"
                  : "font-semibold text-center"
              }
            >
              Create
            </p>
          </div>
        </Link>

        <Link to="/share">
          <div className="flex flex-col items-center w-18">
            <Share
              size={32}
              color={location.pathname === "/share" ? "#647ACB" : "#CBD2D9"}
              stroke-width={2}
            />
            <p
              style={{
                color: location.pathname === "/share" ? "#647ACB" : "#CBD2D9"
              }}
              className={
                location.pathname === "/share"
                  ? "font-semibold text-center"
                  : " font-semibold text-center"
              }
            >
              Share
            </p>
          </div>
        </Link>

        <Link
          to={
            userAccountState.hasAccount
              ? `/user/${userAccountState.username}`
              : "/login"
          }
        >
          <div className="flex flex-col items-center w-18">
            <User
              size={32}
              color={
                location.pathname === "/login" ||
                (userAccountState.hasAccount &&
                  location.pathname === `/user/${userAccountState.username}`)
                  ? "#647ACB"
                  : "#CBD2D9"
              }
              stroke-width={2}
            />
            <p
              style={{
                color:
                  location.pathname === "/login" ||
                  (userAccountState.hasAccount &&
                    location.pathname === `/user/${userAccountState.username}`)
                    ? "#647ACB"
                    : "#CBD2D9"
              }}
              className={
                location.pathname === "/login" ||
                (userAccountState.hasAccount &&
                  location.pathname === `/user/${userAccountState.username}`)
                  ? "font-semibold text-center"
                  : "font-semibold text-center"
              }
            >
              Profile
            </p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default BottomTab;
