import React, { FC } from "react";
import { PlaylistMoment } from "../../../types/moments";
import { useMachine } from "@xstate/react";
import { playlistMachine } from "./machine";
import { useRef } from "react";
import { Range, getTrackBackground } from "react-range";
import { formatTimestamp } from "../../../utils/time";
import { PauseCircle, PlayCircle, Clock } from "react-feather";
// import Toggle from "react-toggle";

type Props = {
  playlistMoments: PlaylistMoment[];
  title: string;
  id: string;
};

const Player: FC<Props> = ({ playlistMoments, title, id }) => {
  const audioRef = useRef<HTMLAudioElement>(null);

  const [current, send] = useMachine(playlistMachine, {
    context: {
      audio: audioRef.current,
      playlistMoments,
      // for better loading experience
      startTime: parseFloat(playlistMoments[0].moment.startTime),
      currentTime: parseFloat(playlistMoments[0].moment.startTime),
      endTime: parseFloat(playlistMoments[0].moment.endTime)
    },
    devTools: true
  });

  const {
    currentIndex,
    startTime,
    currentTime,
    currentSliderTime,
    endTime,
    autoplay
  } = current.context;

  const currentMoment = playlistMoments[currentIndex].moment;

  return (
    <div
      className="focus:outline-none  rounded-lg overflow-hidden shadow-lg"
      onKeyDown={e => {
        // keyboard controls
        switch (e.key) {
          case " ":
            if (current.matches("mounted.player.paused")) send("PLAY");
            if (current.matches("mounted.player.playing")) send("PAUSE");
        }
      }}
      tabIndex={0}
    >
      <div className="bg-orange-500 px-2 py-2 truncate flex flex-row justify-between items-center">
        <a
          className="truncate text-white  text-md font-semibold"
          href={`https://podsoda.com/playlists/${id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {title}
        </a>

        <div className="flex-none text-white text-xs">
          {current.matches("mounted") ? (
            autoplay ? (
              <button onClick={() => send("DISABLE_AUTOPLAY")}>
                disable autoplay
              </button>
            ) : (
              <button onClick={() => send("ENABLE_AUTOPLAY")}>
                enable autoplay
              </button>
            )
          ) : (
            <button className="text-gray-300 cursor-not-allowed">
              disable autoplay
            </button>
          )}
        </div>
      </div>

      <audio
        ref={audioRef}
        src={
          playlistMoments[currentIndex].moment.audio +
          `#t=${playlistMoments[currentIndex].moment.startTime},${playlistMoments[currentIndex].moment.endTime}`
        }
        onCanPlay={() => {
          if (current.matches("mounting")) {
            send("MOUNTED", { audio: audioRef.current });
          } else {
            send("LOADED_MOMENT");
          }
        }}
        onError={() => {
          send("FAILURE");
        }}
        onTimeUpdate={e => {
          if (!current.matches("mounted.player.loading")) {
            send("TIME_UPDATE", { timestamp: e.currentTarget.currentTime });
          }
        }}
        onPlay={() => send("PLAY")}
        onPause={() => send("PAUSE")}
      />

      {/* Current Moment */}
      <div className="flex flex-row w-full rounded items-center  bg-gray-100 border-b">
        <div className="relative w-24 h-24 flex-none">
          <img
            alt=""
            src={currentMoment.thumbnail}
            className="w-full w-24 object-cover flex-none"
            style={{
              filter:
                current.matches("mounted.player.loading") ||
                current.matches("mounting")
                  ? "brightness(18%)"
                  : ""
            }}
          />
          <div className="absolute top-0 flex flex-row justify-center items-center w-full h-full">
            {current.matches("mounting") ||
            current.matches("mounted.player.loading") ? (
              <img
                style={{ width: 44, height: 44 }}
                className="mx-3"
                src={"/three-dots.svg"}
                alt="loading border rounded-full "
              />
            ) : null}
            {current.matches("mounted.player.playing") && (
              <PauseCircle
                color="#f5f7fa"
                fill="#f9703e"
                size={44}
                onClick={() => send("PAUSE")}
                className="opacity-75 hover:opacity-100 cursor-pointer"
              />
              // <button
              //   onClick={() => send("PAUSE")}
              //   className="text-sm my-2 w-24 uppercase font-semibold text-blue-500 hover:text-blue-700 focus:outline-none bg-gray-300 rounded-full px-2 py-1"
              // >
              //   Pause
              // </button>
            )}
            {current.matches("mounted.player.paused") && (
              <PlayCircle
                color="#f5f7fa"
                fill="#f9703e"
                size={44}
                onClick={() => send("PLAY")}
                className="opacity-75 hover:opacity-100 cursor-pointer"
              />
              // <button
              //   onClick={() => send("PLAY")}
              //   className="text-sm my-2 w-24 uppercase font-semibold text-blue-500 hover:text-blue-700 focus:outline-none bg-gray-300 rounded-full px-2 py-1"
              // >
              //   Play
              // </button>
            )}
          </div>
        </div>

        <div className="w-full flex flex-col justify-around truncate px-2">
          <a
            href={`https://podsoda.com/moments/${currentMoment.id}`}
            target="_blank"
            rel="noopener noreferrer"
            className="truncate text-sm text-indigo-600 hover:text-indigo-800 cursor-pointer font-semibold"
          >
            {currentMoment.momentTitle}
          </a>
          <a
            href={`https://podsoda.com/podcasts/${currentMoment.podcastId}`}
            target="_blank"
            rel="noopener noreferrer"
            className="truncate text-xs text-gray-600 hover:text-gray-700 cursor-pointer"
          >
            {currentMoment.podcastName}
          </a>

          {audioRef.current &&
          current.matches("mounted") &&
          !current.matches("mounted.player.loading") ? (
            <div className="h-8 w-full mt-1">
              <div className="w-full flex flex-row justify-between">
                <p className="text-xs text-blue-500">
                  {formatTimestamp(currentTime)}
                </p>
                <p className="text-xs text-blue-500">
                  -
                  {formatTimestamp(
                    endTime - currentTime >= 0 ? endTime - currentTime : 0
                  )}
                </p>
              </div>
              <div className="w-full">
                <Range
                  step={0.1}
                  min={startTime}
                  max={endTime}
                  values={[currentSliderTime]}
                  onChange={values => {
                    if (current.matches("mounted.range.idle")) {
                      send("DRAG_START");
                    }
                    send("DRAG_UPDATE", { sliderTimestamp: values[0] });
                  }}
                  onFinalChange={values =>
                    send("DRAG_END", { sliderTimestamp: values[0] })
                  }
                  renderTrack={({ props, children }) => (
                    <div
                      {...props}
                      style={{
                        ...props.style,
                        height: "5px",
                        width: "100%",
                        // backgroundColor: "#98AEEB"
                        background: getTrackBackground({
                          values: [currentSliderTime],
                          min: startTime,
                          max: endTime,
                          colors: ["#7B93DB", "#BED0F7"]
                        })
                      }}
                    >
                      {children}
                    </div>
                  )}
                  renderThumb={({ props, value, isDragged }) => (
                    <div
                      {...props}
                      className="rounded-full h-4 w-4 bg-white border shadow outline-none"
                      style={{
                        ...props.style
                      }}
                    >
                      {/* <p
                        className="absolute border rounded"
                        style={{ bottom: 10, left: "25%" }}
                      >
                        {isDragged ? formatTimestamp(value) : null}
                      </p> */}
                    </div>
                  )}
                />
              </div>
            </div>
          ) : (
            // loading state slider
            <div className="h-8 w-full mt-1">
              <div className="w-full flex flex-row justify-between">
                <p className="text-xs text-blue-500">
                  {formatTimestamp(startTime)}
                </p>
                <p className="text-xs text-blue-500">
                  -
                  {formatTimestamp(
                    endTime - currentTime >= 0 ? endTime - currentTime : 0
                  )}
                </p>
              </div>
              <div
                style={{
                  height: "5px",
                  width: "100%",
                  backgroundColor: "#BED0F7"
                }}
              ></div>
            </div>
          )}
        </div>
      </div>

      <div className="overflow-y-scroll">
        {/* make it visually clear that users can't change moments until first moment is loaded ("mounted" state) */}
        {current.matches("mounting")
          ? playlistMoments.map((playlistMoment, i) => (
              <div
                className={
                  i === currentIndex
                    ? "bg-gray-100  cursor-not-allowed flex flex-row items-center h-16 p-1 border-b"
                    : "bg-white cursor-not-allowed flex flex-row items-center h-16 p-1 border-b"
                }
                key={i.toString()}
              >
                <div
                  className="relative h-full flex-none"
                  style={{
                    filter: "brightness(66%)"
                  }}
                >
                  <img
                    alt=""
                    src={playlistMoment.moment.thumbnail}
                    className="h-full object-cover flex-none rounded"
                    style={{
                      width: 56
                    }}
                  />
                  <div
                    className="flex flex-row items-center text-white absolute top-0 mt-1 bg-black rounded px-1 "
                    style={{ fontSize: 11 }}
                  >
                    <Clock size={11} />
                    <p className="ml-1">
                      {formatTimestamp(
                        parseFloat(playlistMoment.moment.endTime) -
                          parseFloat(playlistMoment.moment.startTime)
                      )}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col px-2 w-full truncate">
                  <p
                    className={
                      i === currentIndex
                        ? "text-indigo-300 text-xs truncate font-semibold"
                        : "text-xs text-indigo-300 truncate"
                    }
                  >
                    {playlistMoment.moment.momentTitle}
                  </p>
                  <p className="text-xs text-gray-400 truncate">
                    {playlistMoment.moment.podcastName}
                  </p>
                  <p className="text-xs text-gray-300">
                    {playlistMoment.moment.plays === 1
                      ? playlistMoment.moment.plays + " play"
                      : playlistMoment.moment.plays + " plays"}
                  </p>
                </div>
              </div>
            ))
          : playlistMoments.map((playlistMoment, i) => (
              <div
                key={playlistMoment.moment.id}
                onClick={() => send("CHANGE_CURRENT_MOMENT", { nextIndex: i })}
                className={
                  i === currentIndex
                    ? "bg-gray-100 flex flex-row items-center h-16 p-1 border-b"
                    : "bg-white hover:bg-gray-100 cursor-pointer flex flex-row items-center h-16 p-1 border-b"
                }
              >
                <div className="relative h-full flex-none">
                  <img
                    alt=""
                    src={playlistMoment.moment.thumbnail}
                    className="h-full object-cover flex-none rounded"
                    style={{
                      width: 56
                    }}
                  />
                  <div
                    className="flex flex-row items-center text-white absolute top-0 mt-1 bg-black rounded px-1 "
                    style={{ fontSize: 11 }}
                  >
                    <Clock size={11} />
                    <p className="ml-1">
                      {formatTimestamp(
                        parseFloat(playlistMoment.moment.endTime) -
                          parseFloat(playlistMoment.moment.startTime)
                      )}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col px-2 w-full truncate">
                  <p
                    className={
                      i === currentIndex
                        ? "text-indigo-500 text-xs truncate font-semibold"
                        : "text-xs text-indigo-500 truncate"
                    }
                  >
                    {playlistMoment.moment.momentTitle}
                  </p>
                  <p className="text-xs text-gray-600 truncate">
                    {playlistMoment.moment.podcastName}
                  </p>
                  <p className="text-xs text-gray-500">
                    {playlistMoment.moment.plays === 1
                      ? playlistMoment.moment.plays + " play"
                      : playlistMoment.moment.plays + " plays"}
                  </p>
                </div>
              </div>
            ))}
      </div>
    </div>
  );
};

export default Player;
