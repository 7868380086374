import React, { FC } from "react";
import { Link } from "react-router-dom";

const Create: FC = () => {
  return (
    <div className="flex flex-col items-center pt-4 mx-4">
      <div className="flex flex-col text-center items-center my-4">
        <Link to="/wizard">
          <p className="font-semibold text-gray-700 hover:text-gray-900 text-2xl">
            Create a{" "}
            <span className="bg-blue-500 py-1 px-2 text-white hover:text-white">
              moment
            </span>{" "}
            from your favorite podcast
          </p>
        </Link>
      </div>

      <p className="text-3xl text-gray-700 font-semibold my-4">-or-</p>

      <div className="flex flex-col text-center items-center mt-8 mb-4">
        <Link to="/new-playlist">
          <p className="font-semibold text-gray-700 hover:text-gray-900 text-2xl">
            Create a{" "}
            <span className="bg-blue-500 py-1 px-2 text-white">playlist</span>{" "}
            of your favorite moments
          </p>
        </Link>
      </div>

      <p className="text-3xl text-gray-700 font-semibold my-4">-or-</p>

      <div className="flex flex-col text-center items-center mt-8 mb-4">
        <Link to="/create-showcase">
          <p className="font-semibold text-gray-700 hover:text-gray-900 text-2xl">
            Create a{" "}
            <span className="bg-blue-500 py-1 px-2 text-white">showcase</span>{" "}
            for your podcast
          </p>
        </Link>
      </div>
    </div>
  );
};

export default Create;
