import React, { FC } from "react";
import { useSearch } from "../../hooks";
import { podsodaFetch } from "../../utils";
import SearchBar from "../shared/SearchBar";
import SearchComponent from "../shared/SearchComponent";
import { PlaylistSearchResult } from "../../types/moments";
import { Link } from "react-router-dom";
import SearchResult from "../shared/SearchResult";
import moment from "moment";
import List from "../shared/List";
import ShimmerSearchResult from "../shared/ShimmerSearchResult";

const Playlists: FC = () => {
  const {
    loading,
    searching,
    searchResults,
    searchTerm,
    setSearchTerm
  } = useSearch<PlaylistSearchResult[]>(
    podsodaFetch,
    term => `/playlist/search/${term}`
  );

  return (
    <div className="flex flex-col items-center">
      <br />
      <SearchBar
        value={searchTerm}
        onChangeText={setSearchTerm}
        placeholder="Search Playlists"
      />

      <div className="w-10/12 mt-8">
        <SearchComponent
          loading={loading}
          searching={searching}
          results={searchResults ? searchResults : []}
          RenderLoading={() => (
            <List
              items={Array(10).fill(0)}
              RenderItem={item => (
                <ShimmerSearchResult hasTertiaryText={true} />
              )}
              keyExtractor={(item, i) => i!.toString()}
            />
          )}
          RenderDefaultContent={() => <p>default content</p>}
          RenderItem={item => (
            <SearchResult
              link={`/playlists/${item.id}`}
              primaryText={item.name}
              secondaryText={`Created by @${item.creator.username}`}
              tertiaryText={`Last updated: ${moment
                .utc(item.updatedDate)
                .fromNow()}`}
              imageUrl={`${process.env.PUBLIC_URL}/podsoda.png`}
            />
          )}
          keyExtractor={item => item.id}
        />
      </div>
    </div>
  );
};

export default Playlists;
