/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef, useCallback } from "react";

// TODO: Optimistic mutation hook

// TODO: Use debounce hook (for editor)

/** Path example: `/users/moments-liked-by/${username}` */
export function usePodSodaFetch<T>(
  path: string,
  deps?: React.DependencyList,
  options?: RequestInit
) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState<T>();
  const isMounted = useRef(false);

  const dataFetchCallback = useCallback(() => {
    setLoading(true);
    fetch(
      `https://podsoda-server.herokuapp.com${path}`,
      options ? options : { method: "GET" }
    )
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }

        return response.json();
      })
      .then((data: T) => {
        if (isMounted.current) {
          setData(data);
          setLoading(false);
        }
      })
      .catch(err => {
        if (isMounted.current) {
          setError(true);
          setLoading(false);
        }
      });
  }, [path, options]);

  useEffect(
    () => {
      isMounted.current = true;
      dataFetchCallback();

      return () => {
        isMounted.current = false;
      };
    },
    deps ? [...deps, path, dataFetchCallback] : [path, dataFetchCallback]
  );

  return {
    loading,
    error,
    data,
    retry: () => dataFetchCallback()
  };
}

/** Path example: `/podcasts/${id}` */
export function useListenApiFetch<T>(
  path: string,
  deps?: React.DependencyList,
  options?: RequestInit
) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState<T>();
  const isMounted = useRef(false);

  const dataFetchCallback = useCallback(() => {
    setLoading(true);
    fetch(
      `https://podsoda-showcase.now.sh/api/listen${path}`,
      options
        ? {
            ...options,
            headers: {
              ...(options.headers || {})
            }
          }
        : {
            method: "GET"
          }
    )
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }

        return response.json();
      })
      .then((data: T) => {
        if (isMounted.current) {
          setData(data);
          setLoading(false);
        }
      })
      .catch(err => {
        if (isMounted.current) {
          setError(true);
          setLoading(false);
        }
      });
  }, [path, options]);

  useEffect(
    () => {
      isMounted.current = true;
      dataFetchCallback();

      return () => {
        isMounted.current = false;
      };
    },
    deps ? [...deps, path, dataFetchCallback] : [path, dataFetchCallback]
  );

  return {
    loading,
    error,
    data,
    retry: () => dataFetchCallback()
  };
}
