import React, { useState, FC } from "react";
import { useCopyToClipboard } from "react-use";
import Tooltip from "rc-tooltip";

type Props = {
  momentId: string;
  placement?: string;
  size?: number;
};

const TwitterShare: FC<Props> = ({
  momentId,
  placement = "left",
  size = 20
}) => {
  const [twitterTooltipMessage, setTwitterTooltipMessage] = useState(
    "Copy Twitter Link!"
  );

  // eslint-disable-next-line no-unused-vars
  const [clipboardState, copyToClipboard] = useCopyToClipboard();

  return (
    <Tooltip
      overlay={<span>{twitterTooltipMessage}</span>}
      placement={placement}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          position: "relative"
        }}
      >
        <img
          src={process.env.PUBLIC_URL + "/twitter.svg"}
          alt="share on twitter"
          style={{ height: size, width: size, cursor: "pointer" }}
          onClick={() => {
            setTwitterTooltipMessage("Copied to Clipboard!");
            copyToClipboard(`https://pod.soda.com.tw/m/1/${momentId}`);
            setTimeout(() => {
              setTwitterTooltipMessage("Copy Twitter Link");
            }, 1000);
          }}
        />
      </div>
    </Tooltip>
  );
};

export default TwitterShare;
