import React, { FC, useState, FormEvent } from "react";
import userAccount from "../../stores/userAccount";
import { firstTime } from "../../stores/firstTime";

const SignUp: FC = () => {
  const [showPassword, setShowPassword] = useState(false);

  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    fetch(`https://podsoda-server.herokuapp.com/auth/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email,
        username,
        password
      })
    })
      .then(response => {
        if (!response.ok) {
          console.log(response);
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then(data => {
        userAccount.logIn(
          data.access_token,
          data.userId,
          data.username,
          data.email
        );
        firstTime.set(false);
      })
      .catch(_ => {
        setErrorMessage("invalid credentials");
        setLoading(false);
      });
  };

  return (
    <div className="w-64">
      {errorMessage && (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 mb-2 text-sm rounded relative"
          role="alert"
        >
          <span className="block sm:inline">{errorMessage}</span>
          <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
            <svg
              onClick={() => setErrorMessage("")}
              className="fill-current h-6 w-6 text-red-500"
              role="button"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <title>Close</title>
              <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
            </svg>
          </span>
        </div>
      )}
      <form
        className="border bg-gray-100  rounded px-8 pt-6 pb-8 mb-4 shadow-lg"
        onSubmit={handleSubmit}
      >
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Email
          </label>
          <input
            className="appearance-none border bg-gray-300 focus:bg-white rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none "
            type="text"
            placeholder="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Username
          </label>
          <input
            className="appearance-none border bg-gray-300 focus:bg-white rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            placeholder="username"
            value={username}
            onChange={e => setUsername(e.target.value)}
          />
          <p className="text-xs text-gray-600  mt-1">
            at least 3 characters long
          </p>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Password
          </label>
          <input
            className="appearance-none border bg-gray-300 focus:bg-white rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type={showPassword ? "text" : "password"}
            placeholder="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          <p className="text-xs text-gray-600  mt-1">
            at least 6 characters long
          </p>
          {showPassword ? (
            <button
              className="border border-red-500 mt-1 py-1 px-2 hover:border-red-700 rounded-full text-xs  text-red-500 hover:text-red-700 focus:outline-none"
              onClick={e => {
                e.preventDefault();
                setShowPassword(false);
              }}
            >
              Hide Password
            </button>
          ) : (
            <button
              type="button"
              className="border border-red-500 mt-1 py-1 px-2 hover:border-red-700 rounded-full text-xs  text-red-500 hover:text-red-700 focus:outline-none"
              onClick={e => {
                e.preventDefault();
                setShowPassword(true);
              }}
            >
              Show Password
            </button>
          )}
        </div>

        <div className="flex items-center justify-between">
          {validations(email, username, password) ? (
            <button
              disabled={loading}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Sign Up
            </button>
          ) : (
            <button
              disabled={true}
              className="bg-blue-300  text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Sign Up
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default SignUp;

const validations = (
  email: string,
  username: string,
  password: string
): boolean => {
  return email.length > 0 && username.length >= 3 && password.length >= 6;
};
