/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useCallback } from "react";
import { useSearch } from "../../hooks";
import { SearchMoment, PlaylistSearchResult } from "../../types/moments";
import { podsodaFetch, listenNotesFetch } from "../../utils";
import { ListenSearchPodcastsResults } from "../../types/listenApi";
import SearchComponent from "./SearchComponent";
import { useHistory } from "react-router-dom";
import ShimmerSearchResult from "./ShimmerSearchResult";
import SearchResult from "./SearchResult";
import List from "./List";
import { XCircle } from "react-feather";

const Discover: FC = () => {
  const history = useHistory();

  const momentSearch = useSearch<SearchMoment[]>(
    podsodaFetch,
    term => `/moment/search/${term}?limit=3`,
    false
  );
  const playlistSearch = useSearch<PlaylistSearchResult[]>(
    podsodaFetch,
    term => `/playlist/search/${term}?limit=3`,
    false
  );
  const podcastSearch = useSearch<ListenSearchPodcastsResults>(
    listenNotesFetch,
    term => `/search?type=podcast&q=${term}`,
    true
  );

  const stopSearching = useCallback(() => {
    momentSearch.setSearching(false);
    playlistSearch.setSearching(false);
    podcastSearch.setSearching(false);
  }, []);

  const clearSearch = useCallback(() => {
    momentSearch.setSearchTerm("");
    playlistSearch.setSearchTerm("");
    podcastSearch.setSearchTerm("");
    stopSearching();
  }, []);

  return (
    <div className="w-full">
      <div className="flex flex-row bg-gray-200 border-b  items-center">
        {/* <Search size={32} color="#616E7C" /> */}

        <img
          src={process.env.PUBLIC_URL + "/podsoda.png"}
          alt=""
          className="h-full w-8 ml-2 object-cover"
        />

        <input
          spellCheck="false"
          className="appearance-none block h-12 w-full bg-gray-200 text-gray-700 py-2 px-3 leading-tight focus:outline-none"
          value={momentSearch.searchTerm}
          onFocus={() => {
            momentSearch.setSearching(true);
            playlistSearch.setSearching(true);
            podcastSearch.setSearching(true);
          }}
          // onBlur={() => {
          //   momentSearch.setSearching(false);
          //   playlistSearch.setSearching(false);
          //   podcastSearch.setSearching(false);
          // }}
          onChange={e => {
            momentSearch.setSearchTerm(e.target.value);
            playlistSearch.setSearchTerm(e.target.value);
            podcastSearch.setSearchTerm(e.target.value);
          }}
          placeholder="Discover moments, playlists, podcasts..."
        />

        {momentSearch.searchTerm.length > 0 && (
          <XCircle
            size={18}
            color="#7B8794"
            onClick={clearSearch}
            className="cursor-pointer mr-4"
          />
        )}
      </div>

      {momentSearch.searching && momentSearch.searchTerm.length > 0 ? (
        <div
          className="bg-white absolute overflow-y-scroll top-12 shadow-xl z-40"
          style={{
            width: "100%",
            maxWidth: 1022,
            maxHeight: "70vh"
          }}
        >
          <div className="bg-gray-100 py-1">
            <p className="uppercase font-bold text-sm ml-2">moments</p>
          </div>
          <SearchComponent
            loading={momentSearch.loading}
            searching={momentSearch.searching}
            results={
              momentSearch.searchResults ? momentSearch.searchResults : []
            }
            RenderDefaultContent={() => <></>}
            RenderLoading={() => (
              <div className="border-t">
                <List
                  items={Array(3).fill(0)}
                  RenderItem={item => (
                    <ShimmerSearchResult hasTertiaryText={true} />
                  )}
                  keyExtractor={(item, i) => i!.toString()}
                />
              </div>
            )}
            RenderItem={item => (
              <div
                onMouseDown={() => {
                  history.push(`/moments/${item.id}`);
                  stopSearching();
                }}
                className="cursor-pointer"
              >
                <SearchResult
                  noLink={true}
                  link={"/"}
                  primaryText={item.momentTitle}
                  secondaryText={item.podcastName}
                  tertiaryText={`created by ${item.username}`}
                  // quaternaryChild={
                  //   <MomentTimeline
                  //     startTime={parseFloat(item.startTime)}
                  //     endTime={parseFloat(item.endTime)}
                  //     duration={parseFloat(item.originalEpisodeDuration)}
                  //   />
                  // }
                  imageUrl={item.thumbnail}
                />
              </div>
            )}
            RenderEmpty={() =>
              momentSearch.searchTerm.length > 0 ? (
                <div className="border-t p-4">
                  <p className="text-sm text-gray-800">No moments found</p>
                </div>
              ) : (
                <div>
                  <p>suggestions</p>
                </div>
              )
            }
            keyExtractor={item => item.id}
          />
          <div className="bg-gray-100 py-1">
            <p className="uppercase font-bold text-sm ml-2">playlists</p>
          </div>
          <SearchComponent
            loading={playlistSearch.loading}
            searching={playlistSearch.searching}
            results={
              playlistSearch.searchResults ? playlistSearch.searchResults : []
            }
            RenderDefaultContent={() => <></>}
            RenderLoading={() => (
              <div className="border-t">
                <List
                  items={Array(3).fill(0)}
                  RenderItem={item => (
                    <ShimmerSearchResult hasTertiaryText={true} />
                  )}
                  keyExtractor={(item, i) => i!.toString()}
                />
              </div>
            )}
            RenderItem={item => (
              <div
                onMouseDown={() => {
                  history.push(`/playlists/${item.id}`);
                  stopSearching();
                }}
                className="cursor-pointer"
              >
                <SearchResult
                  noLink={true}
                  link=""
                  primaryText={item.name}
                  secondaryText={item.creator.username}
                  tertiaryText={`${item.playlistMoments.length} moments`}
                  imageUrl={process.env.PUBLIC_URL + "/podsoda.png"}
                />
              </div>
            )}
            RenderEmpty={() => (
              <div className="border-t p-4">
                <p className="text-sm text-gray-800">No playlists found</p>
              </div>
            )}
            keyExtractor={item => item.id}
          />
          <div className="bg-gray-100 py-1">
            <p className="uppercase font-bold text-sm ml-2">podcasts</p>
          </div>
          <SearchComponent
            loading={podcastSearch.loading}
            searching={podcastSearch.searching}
            results={
              podcastSearch.searchResults
                ? podcastSearch.searchResults.results
                : []
            }
            RenderDefaultContent={() => <></>}
            RenderLoading={() => (
              <div className="border-t">
                <List
                  items={Array(3).fill(0)}
                  RenderItem={item => (
                    <ShimmerSearchResult hasTertiaryText={true} />
                  )}
                  keyExtractor={(item, i) => i!.toString()}
                />
              </div>
            )}
            RenderItem={item => (
              <div
                onMouseDown={() => {
                  history.push(`/podcasts/${item.id}`);
                  stopSearching();
                }}
                className="cursor-pointer"
              >
                <SearchResult
                  noLink={true}
                  link=""
                  primaryText={item.title_original}
                  secondaryText={item.publisher_original}
                  tertiaryText={`${item.total_episodes} episodes`}
                  imageUrl={item.thumbnail}
                />
              </div>
            )}
            RenderEmpty={() => (
              <div className="border-t p-4">
                <p className="text-sm text-gray-800">No podcasts found</p>
              </div>
            )}
            keyExtractor={item => item.id}
          />{" "}
        </div>
      ) : (
        <></>
      )}
    </div>
  );

  // when not searching
};

export default Discover;
