import React, { FC, useEffect, useState } from "react";
import { useParams, useHistory, Redirect } from "react-router-dom";
import { usePodSodaFetch } from "../../hooks";
import { Showcase, Moment } from "../../types/moments";
import { useStoreState } from "react-svelte-stores/build";
import userAccountStore from "../../stores/userAccount";
import SearchResult from "../shared/SearchResult";
import { ArrowRight } from "react-feather";
import MomentDragger from "./components/MomentDragger";
import ErrorMessage from "../shared/ErrorMessage";

const EditShowcase: FC = () => {
  const { showcaseId } = useParams();
  const history = useHistory();

  const userAccountState = useStoreState(userAccountStore);
  const { loading, error, data: showcase } = usePodSodaFetch<Showcase>(
    `/showcase/${showcaseId}`,
    [showcaseId]
  );

  useEffect(() => {
    if (showcase) {
      if (showcase.userId !== userAccountState.userId) {
        history.push(`/create-showcase`);
      } else {
        setShowEpisodes(showcase.show_episodes);
        setSelectedMoments(
          showcase.showcaseMoments.map(showcaseMoment => showcaseMoment.moment)
        );
        setDescription(showcase.description);
      }
    }
  }, [showcase]);

  const [showEpisodes, setShowEpisodes] = useState(true);
  const [selectedMoments, setSelectedMoments] = useState<Moment[]>([]);
  const [description, setDescription] = useState("");

  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [savingError, setSavingError] = useState("");

  const saveShowcase = () => {
    if (showcase) {
      setSaving(true);
      window.scrollTo(0, 0);
      fetch(
        `https://podsoda-server.herokuapp.com/showcase/edit/${showcase.id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${userAccountState.token}`
          },
          body: JSON.stringify({
            showcaseId: showcase.id,
            momentIds: selectedMoments.map(moment => moment.id),
            description: description,
            show_episodes: showEpisodes
          })
        }
      )
        .then(response => {
          if (!response.ok) {
            throw Error(response.statusText);
          }
          return response.json();
        })
        .then(_ => {
          setSaved(true);
          setSaving(false);
        })
        .catch(_ => {
          setSavingError("Error while saving changes to showcase");
          setSaving(false);
        });
    }
  };

  if (loading)
    return (
      <div className="flex flex-col items-center">
        <div className="w-10/12 mt-8 flex flex-col">
          <div className="self-center w-3/5">
            <h3 className="text-center text-2xl font-bold mt-4 mb-8">
              Edit Showcase
            </h3>
          </div>
        </div>
      </div>
    );
  if (error) return <p>error</p>;

  if (showcase) {
    if (saving) {
      return (
        <div className="flex flex-col items-center">
          <div className="w-10/12 mt-8 flex flex-col">
            <div className="self-center w-3/5">
              <h3 className="text-center text-2xl font-bold mt-4 mb-8">
                Edit Showcase
              </h3>
            </div>

            <div className="border-t mb-8 w-10/12">
              <SearchResult
                imageUrl={showcase.image}
                primaryText={showcase.podcast_title}
                secondaryText={showcase.publisher}
                tertiaryText={`/${showcase.path}`}
                link={``}
                noLink={true}
              />
            </div>

            <p className="text-xl text-center text-gray-600">
              Saving showcase...
            </p>
          </div>
        </div>
      );
    }

    if (savingError) {
      return (
        <div className="flex flex-col items-center">
          <div className="w-10/12 mt-8 flex flex-col">
            <div className="self-center w-3/5">
              <h3 className="text-center text-2xl font-bold mt-4 mb-8">
                Edit Showcase
              </h3>
            </div>

            <div className="border-t mb-8 w-10/12">
              <SearchResult
                imageUrl={showcase.image}
                primaryText={showcase.podcast_title}
                secondaryText={showcase.publisher}
                tertiaryText={`/${showcase.path}`}
                link={``}
                noLink={true}
              />
            </div>
            <ErrorMessage
              errorMessage={savingError}
              setErrorMessage={setSavingError}
            />
          </div>
        </div>
      );
    }

    if (saved) {
      return (
        <div className="flex flex-col items-center">
          <h2 className="text-4xl text-center font-bold my-10">
            Congrats! <span aria-hidden>🎉</span>
          </h2>

          <h3 className="text-xl text-gray-700 text-center mb-4">
            Your showcase has been updated!
          </h3>

          <div className="border-t mb-8 w-10/12">
            <SearchResult
              imageUrl={showcase.image}
              primaryText={showcase.podcast_title}
              secondaryText={showcase.publisher}
              tertiaryText={`/${showcase.path}`}
              link={``}
              noLink={true}
            />
          </div>

          <a
            rel="noopener noreferrer"
            target="_blank"
            href={`https://show.podsoda.com/${showcase.path}`}
            className="flex flex-row items-center justify-center w-48 p-2 border rounded bg-gray-100 cursor-pointer mt-2"
          >
            Go to Showcase
          </a>
        </div>
      );
    }

    return (
      <div className="flex flex-col items-center">
        <div className="w-10/12 mt-8 flex flex-col">
          <div className="self-center w-3/5">
            <h3 className="text-center text-2xl font-bold mt-4 mb-8">
              Edit Showcase
            </h3>
          </div>

          <div className="border-t mb-8">
            <SearchResult
              imageUrl={showcase.image}
              primaryText={showcase.podcast_title}
              secondaryText={showcase.publisher}
              tertiaryText={`/${showcase.path}`}
              link={``}
              noLink={true}
            />
          </div>

          <MomentDragger
            podcastId={showcase.listen_id}
            selectedMoments={selectedMoments}
            setSelectedMoments={setSelectedMoments}
          />

          <p className="text-center text-xl text-gray-800 font-semibold mt-4 mb-4">
            Edit Description
          </p>

          <textarea
            className="appearance-none border bg-gray-300 text-sm text-center focus:bg-white rounded w-full py-2 px-3 h-24 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-8"
            placeholder="Your description"
            maxLength={420}
            value={description}
            onChange={e => setDescription(e.target.value)}
          />

          <div className="flex flex-col mx-4 mt-4 ">
            <label className="self-center text-xs uppercase text-gray-600 mb-4">
              Show Episodes:{" "}
              <input
                type="checkbox"
                checked={showEpisodes}
                onChange={e => setShowEpisodes(e.target.checked)}
              />
            </label>
          </div>

          <div className="flex flex-col mt-8">
            <div
              onClick={saveShowcase}
              className="self-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded cursor-pointer flex flex-row items-center"
            >
              <span>Finish Editing</span> <ArrowRight size={18} />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <p>error</p>;
  }
};

export default EditShowcase;
