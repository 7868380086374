import React, { FC, useState, useEffect } from "react";
import { useStoreState } from "react-svelte-stores/build";
import userAccountStore from "../../../stores/userAccount";
import { Link, useHistory } from "react-router-dom";
import { Heart } from "react-feather";

type Props = {
  likes_count: number;
  liked: boolean;
  momentId: string;
};

// interface MomentLikeHeartState {
//   /** Truth, confirmed by server */
//   truthLiked: boolean;
//   likeActionInFlight: boolean;
//   optimisticLiked: boolean;
//   optimisticLikesCount: number;
//   error: boolean;
// }

const MomentLikeHeart: FC<Props> = ({ liked, likes_count, momentId }) => {
  const userAccountState = useStoreState(userAccountStore);

  const history = useHistory();

  const [optimisticLiked, setOptimisticLiked] = useState(liked);
  const [optimisticLikesCount, setOptimisticLikesCount] = useState(likes_count);

  useEffect(() => {
    setOptimisticLiked(liked);
    setOptimisticLikesCount(likes_count);
  }, [likes_count, liked]);

  const [likeActionInFlight, setLikeActionInFlight] = useState(false);

  const handleLike = () => {
    if (!userAccountState.token) {
      console.log("go log in");
    } else {
      if (!likeActionInFlight) {
        setLikeActionInFlight(true);
        setOptimisticLiked(true);
        setOptimisticLikesCount(lc => lc + 1);

        fetch(`https://podsoda-server.herokuapp.com/like/add`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${userAccountState.token}`
          },
          body: JSON.stringify({ momentId: momentId })
        })
          .then(response => {
            if (!response.ok) {
              throw Error(response.statusText);
            }
            setLikeActionInFlight(false);
          })
          .catch(_ => {
            setOptimisticLiked(true);
            setOptimisticLikesCount(lc => lc - 1);
            setLikeActionInFlight(false);
          });
      }
    }
  };

  const handleUnlike = () => {
    if (!userAccountState.token) {
      console.log("go log in");
    } else {
      if (!likeActionInFlight) {
        setLikeActionInFlight(true);
        setOptimisticLiked(false);
        setOptimisticLikesCount(lc => lc - 1);

        fetch(`https://podsoda-server.herokuapp.com/like/remove`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${userAccountState.token}`
          },
          body: JSON.stringify({ momentId: momentId })
        })
          .then(response => {
            if (!response.ok) {
              throw Error(response.statusText);
            }
            setLikeActionInFlight(false);
          })
          .catch(_ => {
            setOptimisticLiked(true);
            setOptimisticLikesCount(lc => lc + 1);
            setLikeActionInFlight(false);
          });
      }
    }
  };

  return (
    <div className="flex flex-row items-center w-10 ">
      {optimisticLiked ? (
        <button className="focus:outline-none" onClick={handleUnlike}>
          <Heart color="red" size={24} fill="red" />
        </button>
      ) : (
        <button
          className="focus:outline-none"
          onClick={
            userAccountState.hasAccount
              ? () => handleLike()
              : () => history.push("/login")
          }
        >
          <Heart color="red" size={24} />
        </button>
      )}

      <Link to={`/moments/${momentId}/likes`}>
        <p className="text-md text-gray-600 font-semibold text-center ml-1 ">
          {optimisticLikesCount}
        </p>
      </Link>
    </div>
  );
};

export default MomentLikeHeart;
