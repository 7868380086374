import React, { FC, useState, useEffect } from "react";
import { useStoreState } from "react-svelte-stores/build";
import userAccountStore from "../../../stores/userAccount";
import { Link } from "react-router-dom";

type Props = {
  following: boolean;
  followedUsername: string;
  followersCount: number;
  followingCount: number;
};

const FollowButton: FC<Props> = ({
  following,
  followedUsername,
  followersCount,
  followingCount
}) => {
  const [optimisticFollowing, setOptimisticFollowing] = useState(following);
  const [optimisticFollowersCount, setOptimisticFollowersCount] = useState(
    followersCount
  );

  const [followInFlight, setFollowInFlight] = useState(false);

  useEffect(() => {
    setOptimisticFollowing(following);
  }, [following]);

  const userAccountState = useStoreState(userAccountStore);

  const handleUnfollow = () => {
    setFollowInFlight(true);
    setOptimisticFollowing(false);
    setOptimisticFollowersCount(count => count - 1);
    fetch(
      `https://podsoda-server.herokuapp.com/relationships/unfollow/${followedUsername}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `bearer ${userAccountState.token}`
        }
      }
    )
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        setFollowInFlight(false);
      })
      .catch(_ => {
        setOptimisticFollowing(true);
        setOptimisticFollowersCount(count => count + 1);
        setFollowInFlight(false);
      });
  };

  const handleFollow = () => {
    setFollowInFlight(true);
    setOptimisticFollowing(true);
    setOptimisticFollowersCount(count => count + 1);
    fetch(
      `https://podsoda-server.herokuapp.com/relationships/follow/${followedUsername}`,
      {
        method: "POST",
        headers: {
          Authorization: `bearer ${userAccountState.token}`
        }
      }
    )
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        setFollowInFlight(false);
      })
      .catch(_ => {
        setOptimisticFollowing(false);
        setOptimisticFollowersCount(count => count - 1);
        setFollowInFlight(false);
      });
  };

  return (
    <div className="w-full border rounded flex flex-col sm:flex-row justify-between sm:justify-around items-center my-2 p-2">
      <Link to={`/user/${followedUsername}/followers`}>
        <div className="flex flex-col text-blue-500 hover:text-blue-700 my-1 sm:my-0">
          <p className="text-center">{optimisticFollowersCount}</p>
          <p className="text-gray-500 hover:text-gray-700">followers</p>
        </div>
      </Link>

      <Link to={`/user/${followedUsername}/following`}>
        <div className="flex flex-col text-blue-500 hover:text-blue-700 my-1 sm:my-0">
          <p className="text-center">{followingCount}</p>
          <p className="text-gray-500 hover:text-gray-700">following</p>
        </div>
      </Link>

      {optimisticFollowing ? (
        <button
          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full focus:outline-none my-1 sm:my-0"
          disabled={followInFlight}
          onClick={handleUnfollow}
        >
          Unfollow
        </button>
      ) : (
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full focus:outline-none my-1 sm:my-0"
          disabled={followInFlight}
          onClick={handleFollow}
        >
          Follow
        </button>
      )}
    </div>
  );
};

export default FollowButton;
