import React, { FC, useState, SetStateAction, useEffect } from "react";
import { usePodSodaFetch } from "../../../hooks";
import { Moment } from "../../../types/moments";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult
} from "react-beautiful-dnd";
import DiscoverMoments from "../../Home/DiscoverMoments";
import ShowcaseMoment from "./ShowcaseMoment";

type Props = {
  podcastId: string;
  selectedMoments: Moment[];
  setSelectedMoments: React.Dispatch<SetStateAction<Moment[]>>;
};

const onDragEnd = (
  result: DropResult,
  selectedMoments: Moment[],
  availableMoments: Moment[],
  setSelectedMoments: React.Dispatch<SetStateAction<Moment[]>>,
  setAvailableMoments: React.Dispatch<SetStateAction<Moment[]>>
) => {
  if (!result.destination) {
    return;
  } else {
    if (result.source.droppableId === result.destination.droppableId) {
      const newSelectedMoments = selectedMoments.filter(
        moment => moment.id !== result.draggableId
      );
      const sourceMoment = selectedMoments.find(
        moment => moment.id === result.draggableId
      );
      if (sourceMoment) {
        newSelectedMoments.splice(result.destination.index, 0, sourceMoment);
      }
      setSelectedMoments(newSelectedMoments);
    }
  }
};

const limit = 20;

const MomentDragger: FC<Props> = ({
  podcastId,
  selectedMoments,
  setSelectedMoments
}) => {
  const { loading, error, data } = usePodSodaFetch<[Moment[], number]>(
    `/moment/by-podcast-id/${podcastId}?limit=${limit}`
  );

  const [availableMoments, setAvailableMoments] = useState<Moment[]>([]);

  const [showMomentsFullMessage, setShowMomentsFullMessage] = useState(false);

  const [loadingMore, setLoadingMore] = useState(false);

  useEffect(() => {
    if (data) {
      const newAvailableMoments = data[0].filter(
        moment =>
          !selectedMoments
            .map(selectedMoment => selectedMoment.id)
            .includes(moment.id)
      );

      setAvailableMoments(newAvailableMoments);
    }
  }, [data]);

  const loadMore = () => {
    fetch(
      `https://podsoda-server.herokuapp.com/moment/by-podcast-id/${podcastId}?limit=${limit}&offset=${selectedMoments.length +
        availableMoments.length}`
    )
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((data: [Moment[], number]) => {
        const newAvailableMoments = data[0].filter(
          moment =>
            !selectedMoments
              .map(selectedMoment => selectedMoment.id)
              .includes(moment.id)
        );

        setAvailableMoments([...availableMoments, ...newAvailableMoments]);
      })
      .catch(_ => {});
  };

  const selectMoment = (moment: Moment) => {
    if (selectedMoments.length < 6) {
      const newSelectedMoments = [...selectedMoments, moment];
      const newAvailableMoments = availableMoments.filter(
        availableMoment => availableMoment.id !== moment.id
      );

      setSelectedMoments(newSelectedMoments);
      setAvailableMoments(newAvailableMoments);
    } else {
      setShowMomentsFullMessage(true);
      setTimeout(() => setShowMomentsFullMessage(false), 7000);
    }
  };

  const removeMoment = (moment: Moment) => {
    const newSelectedMoments = selectedMoments.filter(
      selectedMoment => selectedMoment.id !== moment.id
    );
    const removedMoment = selectedMoments.find(
      selectedMoment => selectedMoment.id === moment.id
    );
    const newAvailableMoments = removedMoment
      ? [removedMoment, ...availableMoments]
      : availableMoments;
    setSelectedMoments(newSelectedMoments);
    setAvailableMoments(newAvailableMoments);
  };

  if (loading)
    return (
      <p className="text-center text-xl text-gray-600 mt-4">
        Loading showcase moment editor...
      </p>
    );
  if (error) return <p>error</p>;

  if (data) {
    return (
      <div className="lg:mx-12 ">
        <p className="text-center text-xl text-gray-800 font-semibold mt-4">
          Featured Moments
        </p>
        <p className="text-center text-md text-gray-700 mb-4">
          Drag and Drop moments to reorder
        </p>

        {showMomentsFullMessage && (
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mx-8 my-4"
            role="alert"
          >
            <strong className="font-semibold">
              You can feature 6 moments at a time. Remove a moment to add this
              moment
            </strong>
            <span
              className="absolute top-0 bottom-0 right-0 px-4 py-3"
              onClick={() => setShowMomentsFullMessage(false)}
            >
              <svg
                className="fill-current h-6 w-6 text-red-500"
                role="button"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <title>Close</title>
                <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
              </svg>
            </span>
          </div>
        )}

        <DragDropContext
          onDragStart={() => {
            // Add a little vibration if the browser supports it.
            // Adds a nice little physical feedback
            if (window.navigator.vibrate) {
              window.navigator.vibrate(100);
            }
          }}
          onDragEnd={result =>
            onDragEnd(
              result,
              selectedMoments,
              availableMoments,
              setSelectedMoments,
              setAvailableMoments
            )
          }
        >
          <Droppable key="selectedMoments" droppableId="selectedMoments">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  background: snapshot.isDraggingOver
                    ? "lightgrey"
                    : "lightgrey",
                  padding: 4,

                  minHeight: 100
                }}
                className="w-full"
              >
                {selectedMoments.length === 0 && (
                  <p className="text-center mt-8 text-gray-800 font-semibold">
                    Select moments to feature in your showcase
                  </p>
                )}
                {selectedMoments.map((moment, index) => (
                  <Draggable
                    key={moment.id}
                    draggableId={moment.id}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          userSelect: "none",

                          margin: 4,

                          ...provided.draggableProps.style
                        }}
                      >
                        <ShowcaseMoment
                          selectedMoment={true}
                          unselectMoment={removeMoment}
                          moment={moment}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <div>
          {data[1] === 0 && (
            <div className="w-full  p-2 text-gray-500 text-sm border-t">
              <p>
                This podcast doesn't have any moments yet. Don't worry, you can
                add moments to your showcase later!
              </p>
            </div>
          )}

          {data[1] > 0 && (
            <>
              <p className="text-center text-xl text-gray-800 font-semibold mt-6">
                Available Moments
              </p>
              <p className="text-center text-md text-gray-700 mb-4">
                Click on a moment to add it to Featured Moments (up to 6)
              </p>
            </>
          )}

          {availableMoments.map(moment => (
            <div key={moment.id}>
              <ShowcaseMoment
                selectedMoment={false}
                moment={moment}
                onClick={selectMoment}
              />
            </div>
          ))}

          {selectedMoments.length + availableMoments.length < data[1] && (
            <div className="w-full  p-2 text-gray-500 text-sm border-t">
              {loadingMore ? (
                <p>Loading...</p>
              ) : (
                <p className="hover:text-gray-700" onClick={loadMore}>
                  Load more
                </p>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }

  return <p>error</p>;
};

export default MomentDragger;
