import React, { FC } from "react";
import { TrendingMoment } from "../../types/moments";
import List from "../shared/List";
import { Play, Heart, Clock } from "react-feather";
import { Link } from "react-router-dom";
import { formatTimestamp } from "../../utils/time";

type Props = {
  moments: TrendingMoment[];
};

const DiscoverMoments: FC<Props> = ({ moments }) => {
  return (
    <List
      items={moments}
      noBorder={true}
      keyExtractor={moment => moment.id}
      RenderItem={moment => (
        <div className="flex flex-row my-6 mx-2">
          <div className="w-32 relative">
            <Link to={`/moments/${moment.id}`}>
              <img
                src={moment.thumbnail}
                alt=""
                className="h-24 w-24 rounded"
              />
            </Link>
            <div className="flex flex-row items-center text-white absolute top-0 ml-1 mt-1 bg-black rounded text-xs px-1 ">
              <Clock size={12} />
              <p className="ml-1">
                {formatTimestamp(
                  parseFloat(moment.endTime) - parseFloat(moment.startTime)
                )}
              </p>
            </div>
          </div>

          <div className="flex flex-col ml-2 md:ml-0 w-full truncate">
            <p className="text-blue-500 hover:text-blue-700 text-sm truncate">
              {moment.username !== "anon" ? (
                <Link to={`/user/${moment.username}`}>@{moment.username}</Link>
              ) : (
                "anon"
              )}
            </p>
            <p className="text-lg font-semibold text-gray-800 hover:text-black truncate">
              <Link to={`/moments/${moment.id}`}>{moment.momentTitle}</Link>
            </p>
            <p className="text-sm text-gray-600 hover:text-gray-700 truncate">
              <Link to={`/podcasts/${moment.podcastId}`}>
                {moment.podcastName}
              </Link>
            </p>
            <div className="flex flex-row justify-start mt-1">
              <div className="flex flex-row items-center">
                <Play size={12} color="#7B8794" />
                <p className="text-gray-600 text-xs">{moment.plays}</p>
              </div>
              <div className="flex flex-row mx-4 items-center">
                <Heart size={12} color="#7B8794" />
                <p className="text-gray-600 text-xs ml-1">
                  {moment.likes_count}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      RenderEmpty={() => (
        <div className="border p-4 my-8 ">
          <p className="text-sm text-gray-600 font-semibold">
            No moments found
          </p>
        </div>
      )}
    />
  );
};

export default DiscoverMoments;
