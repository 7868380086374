import React, { FC } from "react";
import { usePodSodaFetch } from "../../hooks";
import { useParams } from "react-router-dom";
import { IMomentLike } from "../../types/moments";
import PodSodaError from "../shared/PodSodaError";
import List from "../shared/List";
import UserSearchResult from "../Profile/UserSearchResult";
import UserShimmer from "../Profile/components/UserShimmer";

const MomentLikes: FC = () => {
  const { id } = useParams();

  const { loading, error, data: likes, retry } = usePodSodaFetch<IMomentLike[]>(
    `/like/moment/${id}`,
    [id]
  );

  if (loading)
    return (
      <div className="flex flex-col items-center">
        <div className="w-10/12 mt-8 flex flex-col">
          <UserShimmer />
          <UserShimmer />
          <UserShimmer />
          <UserShimmer />
          <UserShimmer />
          <UserShimmer />
          <UserShimmer />
          <UserShimmer />
          <UserShimmer />
          <UserShimmer />
        </div>
      </div>
    );
  if (error) return <PodSodaError retry={retry} />;

  if (likes) {
    return (
      <div className="flex flex-col items-center">
        <div className="w-10/12 mt-8 flex flex-col">
          <List
            items={likes}
            RenderItem={like => (
              <UserSearchResult
                moments_count={like.user.moments_count}
                followers_count={like.user.followers_count}
                following_count={like.user.following_count}
                username={like.user.username}
              />
            )}
            RenderEmpty={() => (
              <div className="border p-4">
                <p className="text-sm text-gray-600 font-semibold">
                  No likes yet
                </p>
              </div>
            )}
            keyExtractor={like => like.user.id}
          />
        </div>
      </div>
    );
  }
  return <PodSodaError retry={retry} />;
};

export default MomentLikes;
