import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import BottomTab from "./shared/BottomTab";

import Discover from "./shared/Discover";

// @ts-ignore
import posthog from "posthog-js";
import MainContent from "./Tabs/MainContent";

posthog.init("9djIk3Bd7LKywf0QMayIF5Jg3h3dQckJ-U6f9M-FAT0", {
  api_host: "https://podsoda-analytics.herokuapp.com",
  autocapture: false
});

const App = () => {
  useEffect(() => {
    // posthog.init("9djIk3Bd7LKywf0QMayIF5Jg3h3dQckJ-U6f9M-FAT0", {
    //   api_host: "https://podsoda-analytics.herokuapp.com",
    //   autocapture: false
    // });

    console.log(posthog);
  }, []);

  return (
    <Router>
      <div className="flex flex-col h-screen antialiased text-gray-900 bg-gray-100">
        <div
          id="holygrail-content"
          className="pb-16 border-l border-r bg-white  self-center"
          style={{
            width: "100%",
            maxWidth: 1024,
            borderTopWidth: 4,
            borderTopColor: "hsl(16, 94%, 61%)"
          }}
        >
          <Discover />

          <MainContent />
        </div>
      </div>

      <BottomTab />
    </Router>
  );
};

export default App;
