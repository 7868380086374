import React, { FC, useState } from "react";
import {
  ListenFeedEpisodePreview,
  ListenPodcastFeedResults
} from "../../types/listenApi";
import List from "../shared/List";
import EpisodeSecondsLeft from "./EpisodeSecondsLeft";
import EpisodePlayButton from "./EpisodePlayButton";
import SearchResult from "../shared/SearchResult";
import moment from "moment";
import { listenNotesFetch } from "../../utils";

type Props = {
  episodes: ListenFeedEpisodePreview[];
  next_episode_pub_date: number | null;
  podcastId: string;
  podcastName: string;
  genre_ids: number[];
};

const InfScrollEpisodes: FC<Props> = ({
  episodes,
  next_episode_pub_date,
  podcastId,
  podcastName,
  genre_ids
}) => {
  const [nextEpisodePubDate, setNextEpisodePubDate] = useState(
    next_episode_pub_date
  );
  const [infEpisodes, setInfEpisodes] = useState(episodes);
  const [loadingMore, setLoadingMore] = useState(false);

  return (
    <div>
      <List
        items={infEpisodes}
        keyExtractor={episode => episode.id}
        RenderItem={episode => (
          <SearchResult
            link={`/episodes/${episode.id}`}
            imageUrl={episode.thumbnail}
            primaryText={episode.title}
            secondaryText={episode.description}
            tertiaryText={moment.utc(episode.pub_date_ms).fromNow()}
            quaternaryChild={
              <EpisodeSecondsLeft
                episode={episode}
                podcastId={podcastId}
                podcastName={podcastName}
                genre_ids={genre_ids}
              />
            }
            TopRightElement={
              <EpisodePlayButton
                episode={episode}
                podcastId={podcastId}
                podcastName={podcastName}
                genre_ids={genre_ids}
              />
            }
          />
        )}
        RenderEmpty={() => (
          <div className="border p-4">
            <p className="text-sm text-gray-600 font-semibold">
              No episodes found
            </p>
          </div>
        )}
      />
      {nextEpisodePubDate !== null && !loadingMore && (
        <div
          onClick={async () => {
            setLoadingMore(true);
            const nextBatch = await listenNotesFetch<ListenPodcastFeedResults>(
              `/podcasts/${podcastId}?next_episode_pub_date=${nextEpisodePubDate}`
            );

            setInfEpisodes(episodes => [...episodes, ...nextBatch.episodes]);
            setNextEpisodePubDate(nextBatch.next_episode_pub_date);
            setLoadingMore(false);
          }}
          className="w-full cursor-pointer p-2 px-4 text-gray-500 hover:text-gray-700 text-sm "
        >
          <p>load more</p>
        </div>
      )}
      {loadingMore && (
        <div className="w-full p-2 px-4 text-gray-500 hover:text-gray-700 text-sm">
          <p>loading...</p>
        </div>
      )}

      <br />
    </div>
  );
};

export default InfScrollEpisodes;
