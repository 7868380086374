import React, { FC, useState, useLayoutEffect } from "react";
import AuthorizedLink from "../shared/AuthorizedLink";
import { useStoreState } from "react-svelte-stores/build";
import userAccountStore from "../../stores/userAccount";
import optimisticPlaylists from "../../stores/optimisticPlaylists";
import { Playlist } from "../../types/moments";

type Props = {
  playlistId: string;
  playlist: Playlist;
  toggleEditing: () => void;
  editing: boolean;
};

const SavePlaylist: FC<Props> = ({
  playlistId,
  playlist,
  toggleEditing,
  editing
}) => {
  const userAccountState = useStoreState(userAccountStore);

  const [saved, setSaved] = useState(false);
  const [saveChangeInFlight, setSaveChangeInFlight] = useState(false);

  const optimisticPlaylistsState = useStoreState(optimisticPlaylists);

  useLayoutEffect(() => {
    const exists = optimisticPlaylistsState.find(
      optPlaylist => optPlaylist.id === playlist.id
    );
    setSaved(exists ? true : false);
  }, [optimisticPlaylistsState, playlist.id]);

  const handleSave = () => {
    if (userAccountState.token) {
      optimisticPlaylists.addPlaylist(playlist);
      setSaveChangeInFlight(true);
      fetch(
        `https://podsoda-server.herokuapp.com/playlist/save/${playlist.id}`,
        {
          method: "POST",
          headers: {
            Authorization: `bearer ${userAccountState.token}`
          }
        }
      )
        .then(response => {
          if (!response.ok) {
            throw Error(response.statusText);
          }
          setSaveChangeInFlight(false);
        })
        .catch(_ => {
          optimisticPlaylists.removePlaylist(playlist.id);
          setSaveChangeInFlight(false);
        });
    }
  };

  const handleUnsave = () => {
    if (userAccountState.token) {
      optimisticPlaylists.removePlaylist(playlist.id);
      fetch(
        `https://podsoda-server.herokuapp.com/playlist/unsave/${playlist.id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `bearer ${userAccountState.token}`
          }
        }
      )
        .then(response => {
          if (!response.ok) {
            throw Error(response.statusText);
          }
          setSaveChangeInFlight(false);
        })
        .catch(_ => {
          optimisticPlaylists.addPlaylist(playlist);
          setSaveChangeInFlight(false);
        });
    }
  };

  if (userAccountState.hasAccount) {
    if (userAccountState.userId !== playlist.creatorId) {
      if (saved) {
        return (
          <button
            onClick={!saveChangeInFlight ? handleUnsave : () => {}}
            className="bg-red-500 hover:bg-red-700 text-white font-bold my-2 py-1 px-2 rounded-full focus:outline-none"
          >
            unsave
          </button>
        );
      } else {
        return (
          <button
            onClick={!saveChangeInFlight ? handleSave : () => {}}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold my-2 py-1 px-2 rounded-full  focus:outline-none"
          >
            save
          </button>
        );
      }
    } else {
      if (editing) {
        return (
          <button
            onClick={toggleEditing}
            className="bg-red-500 hover:bg-red-700 text-white font-bold my-2 py-1 px-2 rounded-full focus:outline-none"
          >
            cancel edit
          </button>
        );
      } else {
        return (
          <button
            onClick={toggleEditing}
            className="bg-red-500 hover:bg-red-700 text-white font-bold my-2 py-1 px-4 rounded-full focus:outline-none"
          >
            edit
          </button>
        );
      }
    }
  } else {
    return (
      <div>
        <AuthorizedLink to="/wizard">
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold my-2 py-1 px-2 rounded-full  focus:outline-none">
            save
          </button>
        </AuthorizedLink>
      </div>
    );
  }
};

export default SavePlaylist;
