import moment from "moment";

/** takes time in seconds and returns a pretty timestamp */
export const formatTimestamp = (timestamp: number | string) => {
  const seconds =
    typeof timestamp === "string" ? parseInt(timestamp) : timestamp;

  return moment
    .utc(seconds * 1000)
    .format(seconds >= 3600 ? "HH:mm:ss" : "mm:ss");

  // return moment
  // .utc(seconds * 1000)
  // .format(seconds >= 3600 ? "HH:mm:ss" : seconds >= 60 ? "mm:ss" : "ss");
};

export const fromNow = (date: number | Date | string) => {
  return moment.utc(date).fromNow();
};
