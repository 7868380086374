import React, { FC } from "react";

const UserShimmer: FC = () => (
  <div className="bg-white w-full hover:bg-gray-300 border-b border-l border-r p-2 overflow-hidden">
    <div className="flex flex-row">
      <div className="flex flex-col justify-around mx-2 pb-2">
        <div className="h-4 w-20 rounded-sm bg-gray-400" />
        <div className="h-4 w-48 rounded-sm bg-gray-400" />
        <div className=" h-4 w-16 rounded-sm bg-gray-400" />
      </div>
    </div>
  </div>
);

export default UserShimmer;
