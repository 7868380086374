import React, { FC, useLayoutEffect, useState } from "react";
import { useStoreState } from "react-svelte-stores/build";
import userAccountStore from "../../../stores/userAccount";
import { UserPlaylist } from "../../../types/moments";
import { podsodaFetch } from "../../../utils";
import List from "../../shared/List";
import optimisticPlaylists from "../../../stores/optimisticPlaylists";
import SearchResult from "../../shared/SearchResult";
import { fromNow } from "../../../utils/time";

const SavedPlaylists: FC = () => {
  const userAccountState = useStoreState(userAccountStore);

  const optimisticPlaylistsState = useStoreState(optimisticPlaylists);

  const [error, setError] = useState(false);

  useLayoutEffect(() => {
    if (userAccountState.hasAccount) {
      // fetch!
      podsodaFetch<UserPlaylist[]>(`/playlist/saved/${userAccountState.userId}`)
        .then(optimisticPlaylists.setPlaylists)
        .catch(err => {
          setError(true);
        });
    } else {
      optimisticPlaylists.setPlaylists([]);
    }
  }, [userAccountState]);

  if (error) {
    return (
      <div>
        <p>Failed to load user playlists</p>
      </div>
    );
  }

  if (userAccountState.hasAccount) {
    return (
      <div className="">
        <div className="flex flex-row justify-start items-baseline  mt-8 mb-2">
          <p className="font-semibold">Saved Playlists</p>
        </div>

        <List
          items={optimisticPlaylistsState}
          RenderItem={item => (
            <SearchResult
              link={`/playlists/${item.id}`}
              imageUrl={process.env.PUBLIC_URL + "/podsoda.png"}
              primaryText={item.name}
              secondaryText={item.creator.username}
              tertiaryText={`Last Updated ${fromNow(item.updatedDate)}`}
            />
          )}
          RenderEmpty={() => (
            <div className="border p-4">
              <p className="text-sm text-gray-600 font-semibold">
                You haven't saved any playlists yet
              </p>
            </div>
          )}
          keyExtractor={item => item.id}
        />
        <div className="mb-2" />
      </div>
    );
  } else {
    return null;
  }
};

export default SavedPlaylists;

// const mockTrendingPlaylists: IPlaylist[] = [
//   {
//     id: "b618decb-5772-4fcd-a895-c30c8265a258",
//     name: "Athletics",
//     createdDate: "2020-02-09T19:01:03.645Z",
//     updatedDate: "2020-02-09T19:01:03.645Z",
//     creatorId: "c2bb9780-11f4-4a5a-93fa-582823210319",
//     creator: {
//       id: "c2bb9780-11f4-4a5a-93fa-582823210319",
//       username: "tj"
//     }
//   },
//   {
//     id: "2db609b9-c88c-43c1-8db0-bb1b23398d9c",
//     name: "Motivational",
//     createdDate: "2020-02-10T19:56:56.629Z",
//     updatedDate: "2020-02-10T19:56:56.629Z",
//     creatorId: "3cff78ea-87bb-4a42-94b1-5e9fce1f6ab2",
//     creator: {
//       id: "3cff78ea-87bb-4a42-94b1-5e9fce1f6ab2",
//       username: "poopoo"
//     }
//   },
//   {
//     id: "0420b479-2538-464d-bece-1a0772d27fdf",
//     name: "Tech",
//     createdDate: "2020-02-11T19:39:21.244Z",
//     updatedDate: "2020-02-11T19:39:21.244Z",
//     creatorId: "c2bb9780-11f4-4a5a-93fa-582823210319",
//     creator: {
//       id: "c2bb9780-11f4-4a5a-93fa-582823210319",
//       username: "tj"
//     }
//   }
// ];
