import { IPlaylist } from "../types/moments";
import { writable } from "react-svelte-stores/build";

interface OptimisticPlaylists extends Array<IPlaylist> {}

const defaultOptimisticPlaylistsState: OptimisticPlaylists = [];

const createOptimisticPlaylistsStore = (initalState: OptimisticPlaylists) => {
  const { subscribe, update, set } = writable(defaultOptimisticPlaylistsState);

  return {
    subscribe,
    setPlaylists: (playlists: IPlaylist[]) => set(playlists),
    addPlaylist: (playlist: IPlaylist) =>
      update(playlists => [...playlists, playlist]),
    removePlaylist: (playlistId: string) =>
      update(playlists =>
        playlists.filter(playlist => playlist.id !== playlistId)
      )
  };
};

export default createOptimisticPlaylistsStore(defaultOptimisticPlaylistsState);
