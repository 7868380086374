import React, { FC } from "react";

type Props = {
  value: string;
  onChangeText: (text: string) => void;
  placeholder: string;
};

const SearchBar: FC<Props> = ({
  value,
  onChangeText,
  placeholder = "Search"
}) => (
  <input
    spellCheck="false"
    type="text"
    placeholder={placeholder}
    value={value}
    onChange={e => onChangeText(e.target.value)}
    className=" max-w-lg appearance-none  bg-gray-200 focus:bg-white rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
  />
);

export default SearchBar;
