/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useRef, useEffect } from "react";
import { Subject } from "rxjs";
import { debounceTime, filter } from "rxjs/operators";

//@ts-ignore
import posthog from "posthog-js";

/** Type argument of API response. TOOD: explain fetchWrapper and pathBuilder. fetchWrapper wraps fetch for your API,
 * deserializes JSON. pathBuilder receives argument of search term and returns the appropriate path for search.
 * e.g. podsodaFetchWrapper wraps 'https://podsoda-server.herokuapp.com', pathBuilder arg. can be (term) => `/podcasts/${term}`
 */
export function useSearch<T>(
  fetchWrapper: <T>(path: string) => Promise<T>,
  pathBuilder: (term: string) => string,
  analytics: boolean = true,
  debounceMs: number = 700
) {
  const [searchTerm, internalSetSearchTerm] = useState<string>("");
  const [searchResults, setSearchResults] = useState<T>();
  const [searching, setSearching] = useState(false);
  const [loading, setLoading] = useState(false);
  const isMounted = useRef(false);
  const term$ = useRef(new Subject<string>());

  // pass in a path builder

  useEffect(() => {
    isMounted.current = true;
    const subscription = term$.current
      .pipe(
        filter(term => term.length > 0),
        debounceTime(debounceMs)
      )
      .subscribe(async term => {
        if (isMounted.current === true) {
          try {
            if (analytics) {
              posthog.capture("search", { term: term });
            }
            const results = await fetchWrapper<T>(pathBuilder(term));
            setSearchResults(results);
            setLoading(false);
          } catch (err) {
            console.log(err);
            setLoading(false);
          }
        }
      });

    return () => {
      isMounted.current = false;
      subscription.unsubscribe();
    };
  }, []);

  const setSearchTerm = (term: string) => {
    internalSetSearchTerm(term);
    setLoading(term.length > 0 ? true : false);
    setSearching(term.length > 0 ? true : false);
    term$.current.next(term);
  };

  return {
    searchTerm,
    setSearchTerm,
    searchResults,
    loading,
    searching,
    /** Expose set searching for dismissing dropdowns! */
    setSearching
  };
}
