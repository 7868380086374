import React, { FC, useState } from "react";
import { useParams } from "react-router-dom";
import { useListenApiFetch, useSearch } from "../../hooks";
import {
  ListenPodcastFeedResults,
  ListenSearchEpisodesInPodcastResponse
} from "../../types/listenApi";
import PodSodaError from "../shared/PodSodaError";
import { listenNotesFetch } from "../../utils";
import SearchBar from "../shared/SearchBar";
import SearchComponent from "../shared/SearchComponent";
import InfScrollEpisodes from "./InfScrollEpisodes";
import FavHeart from "./FavHeart";
import ShimmerSearchResult from "../shared/ShimmerSearchResult";
import List from "../shared/List";
import SearchResult from "../shared/SearchResult";
import EpisodeSecondsLeft from "./EpisodeSecondsLeft";
import EpisodePlayButton from "./EpisodePlayButton";
import moment from "moment";
import PodcastMoments from "./PodcastMoments";
import striptags from "striptags";
// @ts-ignore
import ShowMore from "react-show-more";
import ListenOn from "./ListenOn";

const PodcastById: FC = () => {
  const { id } = useParams();

  const { loading, error, data: podcast, retry } = useListenApiFetch<
    ListenPodcastFeedResults
  >(`/podcasts/${id}`, [id]);

  const [toggle, setToggle] = useState<"episodes" | "moments">("episodes");

  const {
    loading: searchLoading,
    searching,
    searchResults,
    searchTerm,
    setSearchTerm
  } = useSearch<ListenSearchEpisodesInPodcastResponse>(
    listenNotesFetch,
    term => `/search?type=episode&ocid=${id}&q=${term}`
  );

  if (loading)
    return (
      <div className="flex flex-col items-center">
        <div className="w-10/12 mt-8 flex flex-col">
          <div className="flex flex-col items-center md:items-start md:flex-row mb-6">
            <div className="flex w-40 flex-col items-center">
              <div className="h-32 w-32 bg-gray-400 rounded mb-2" />
              <div className="w-32 h-8 rounded-full bg-gray-300" />
            </div>
            <div className="mt-2 md:mt-0 flex w-full h-32 flex-col mx-2 bg-gray-200 rounded" />
          </div>
        </div>
      </div>
    );
  if (error) return <PodSodaError retry={retry} />;

  if (podcast) {
    return (
      <div className="flex flex-col items-center">
        <div className="w-10/12 mt-8">
          <div className="flex flex-col items-center md:items-start md:flex-row mb-6">
            <div className="flex w-40 flex-col items-center">
              <img
                src={podcast.thumbnail}
                alt=""
                className="h-32 w-32 object-cover rounded mb-2"
              />

              <FavHeart podcastId={podcast.id} podcast={podcast} />
            </div>

            <div className="mt-2 md:mt-0 flex w-full flex-col mx-2">
              <p className="text-md text-blue-500 hover:text-blue-700">
                {striptags(podcast.publisher)}
              </p>

              <p className="text-xl font-semibold">
                {striptags(podcast.title)}
              </p>

              <div className="text-gray-600 text-sm mt-2">
                <ShowMore
                  lines={3}
                  more="Show more"
                  less="Show less"
                  anchorClass="text-blue-500 hover:text-blue-700"
                >
                  <p>{striptags(podcast.description)}</p>
                </ShowMore>
              </div>
            </div>
          </div>

          <ListenOn
            itunesId={podcast.itunes_id}
            feedUrl={podcast.rss}
            small={true}
          />

          <div className="flex flex-row justify-center mt-4">
            <button
              className={
                toggle === "episodes"
                  ? "text-black font-semibold mx-2 focus:outline-none"
                  : "text-gray-600 hover:text-black mx-2 focus:outline-none"
              }
              onClick={() => setToggle("episodes")}
            >
              Episodes
            </button>
            <button
              className={
                toggle === "moments"
                  ? "text-black font-semibold mx-2 focus:outline-none"
                  : "text-gray-600 hover:text-black mx-2 focus:outline-none"
              }
              onClick={() => setToggle("moments")}
            >
              Moments
            </button>
          </div>

          {toggle === "episodes" ? (
            <>
              <div className="flex flex-row justify-center my-6">
                <SearchBar
                  placeholder="Search Episodes"
                  value={searchTerm}
                  onChangeText={setSearchTerm}
                />
              </div>
              <SearchComponent
                loading={searchLoading}
                searching={searching}
                results={searchResults ? searchResults.results : []}
                RenderLoading={() => (
                  <List
                    items={Array(10).fill(0)}
                    RenderItem={item => (
                      <ShimmerSearchResult hasTertiaryText={true} />
                    )}
                    keyExtractor={(item, i) => i!.toString()}
                  />
                )}
                RenderDefaultContent={() => (
                  <InfScrollEpisodes
                    episodes={podcast.episodes}
                    next_episode_pub_date={
                      podcast ? podcast.next_episode_pub_date : null
                    }
                    podcastId={podcast.id}
                    podcastName={podcast.title}
                    genre_ids={podcast.genre_ids}
                  />
                )}
                RenderItem={episode => (
                  <SearchResult
                    link={`/episodes/${episode.id}`}
                    imageUrl={episode.thumbnail}
                    primaryText={episode.title_original}
                    secondaryText={episode.description_original}
                    tertiaryText={moment.utc(episode.pub_date_ms).fromNow()}
                    quaternaryChild={
                      <EpisodeSecondsLeft
                        episode={{
                          audio: episode.audio,
                          audio_length_sec: episode.audio_length_sec,
                          description: episode.description_original,
                          explicit_content: episode.explicit_content,
                          id: episode.id,
                          image: episode.image,
                          listennotes_edit_url: episode.listennotes_url,
                          listennotes_url: episode.listennotes_url,
                          maybe_audio_invalid: false,
                          pub_date_ms: episode.pub_date_ms,
                          thumbnail: episode.thumbnail,
                          title: episode.title_original
                        }}
                        podcastId={episode.podcast_id}
                        podcastName={episode.podcast_title_original}
                        genre_ids={episode.genre_ids}
                      />
                    }
                    TopRightElement={
                      <EpisodePlayButton
                        episode={{
                          audio: episode.audio,
                          audio_length_sec: episode.audio_length_sec,
                          description: episode.description_original,
                          explicit_content: episode.explicit_content,
                          id: episode.id,
                          image: episode.image,
                          listennotes_edit_url: episode.listennotes_url,
                          listennotes_url: episode.listennotes_url,
                          maybe_audio_invalid: false,
                          pub_date_ms: episode.pub_date_ms,
                          thumbnail: episode.thumbnail,
                          title: episode.title_original
                        }}
                        podcastId={episode.podcast_id}
                        podcastName={episode.podcast_title_original}
                        genre_ids={episode.genre_ids}
                      />
                    }
                  />
                )}
                RenderEmpty={() => (
                  <div className="border p-4">
                    <p className="text-sm text-gray-600 font-semibold">
                      No episodes found
                    </p>
                  </div>
                )}
                keyExtractor={item => item.id}
              />{" "}
            </>
          ) : (
            <PodcastMoments podcastId={podcast.id} />
          )}
        </div>
      </div>
    );
  }
  return <PodSodaError retry={retry} />;
};

export default PodcastById;
