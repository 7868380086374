import React, { FC, useState, useEffect, FormEvent } from "react";
import { usePodSodaFetch } from "../../hooks";
import { CommentsResponse, IComment } from "../../types/moments";
import PodSodaError from "./PodSodaError";
import List from "./List";
import Comment from "./Comment";
import { useStoreState } from "react-svelte-stores/build";
import userAccountStore from "../../stores/userAccount";
import moment from "moment";
import { formatTimestamp } from "../../utils/time";
import { useHistory } from "react-router-dom";

type Props = {
  type: "episode" | "moment";
  id: string;
  timestamp: number;
};

const limit = 4;

const CommentSection: FC<Props> = ({ type, id, timestamp }) => {
  const { loading, error, data: commentsResponse, retry } = usePodSodaFetch<
    CommentsResponse
  >(`/comment/${type}/${id}?limit=${limit}&offset=0`, [type, id]);

  const userAccountState = useStoreState(userAccountStore);

  const history = useHistory();

  const [optimisticComments, setOptimisticComments] = useState<IComment[]>([]);

  const [draftComment, setDraftComment] = useState("");
  const [newCommentInFlight, setNewCommentInFlight] = useState(false);

  const [commentsCount, setCommentsCount] = useState(0);

  // to know when to hide load more
  const [exhaustedComments, setExhaustedComments] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);

  useEffect(() => {
    if (commentsResponse) {
      setOptimisticComments(commentsResponse.comments[0]);

      setCommentsCount(commentsResponse.comments[1]);

      if (commentsResponse.comments[1] <= limit) {
        setExhaustedComments(true);
      }
    }
  }, [commentsResponse]);

  const handleLoadMore = () => {
    setLoadingMore(true);
    fetch(
      `https://podsoda-server.herokuapp.com/comment/${type}/${id}?limit=${limit}&offset=${optimisticComments.length}`
    )
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((data: CommentsResponse) => {
        const commentsList = [...optimisticComments, ...data.comments[0]];
        setExhaustedComments(
          commentsList.length < data.comments[1] ? false : true
        );
        setOptimisticComments(commentsList);
        setCommentsCount(data.comments[1]);
        setLoadingMore(false);
      })
      .catch(_ => {
        setLoadingMore(false);
      });
  };

  if (loading) return null;
  if (error) return <PodSodaError retry={retry} />;

  if (commentsResponse) {
    // strict null checks make this verbose
    const submitComment = (e: FormEvent) => {
      e.preventDefault();
      if (
        !newCommentInFlight &&
        userAccountState.userId &&
        userAccountState.username
      ) {
        const newComment = {
          content: draftComment,
          createdAt: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
          timestamp: timestamp,
          episodeId: commentsResponse?.episodeId
        };
        setOptimisticComments(comments => [
          {
            timestamp: newComment.timestamp.toString(),
            createdAt: newComment.createdAt,
            content: newComment.content,
            episodeId: newComment.episodeId,
            id: "inprogress",
            userId: userAccountState.userId!,
            user: {
              id: userAccountState.userId!,
              username: userAccountState.username!
            }
          },
          ...comments
        ]);
        setCommentsCount(count => count + 1);

        fetch(`https://podsoda-server.herokuapp.com/comment/create`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `bearer ${userAccountState.token}`
          },
          body: JSON.stringify(newComment)
        })
          .then(response => {
            if (!response.ok) {
              throw Error(response.statusText);
            }
            return response.json();
          })
          .then(data => {
            const successfulComment: IComment = {
              ...data,
              user: {
                id: userAccountState.userId,
                username: userAccountState.username
              }
            };

            setDraftComment("");
            // stale closures help us here!
            setOptimisticComments([successfulComment, ...optimisticComments]);
            setNewCommentInFlight(false);
          })
          .catch(_ => {
            // revert
            setOptimisticComments(optimisticComments);
            setCommentsCount(count => count - 1);
            setNewCommentInFlight(false);
          });
      }
    };

    const deleteComment = (commentId: string) => {
      const toBeDeleted = optimisticComments.find(
        comment => comment.id === commentId
      );
      if (toBeDeleted && !newCommentInFlight) {
        const filteredComments = optimisticComments.filter(
          comment => comment.id !== commentId
        );
        setOptimisticComments(filteredComments);
        setCommentsCount(count => count - 1);

        fetch(
          `https://podsoda-server.herokuapp.com/comment/delete/${commentId}`,
          {
            method: "DELETE",
            headers: {
              Authorization: `bearer ${userAccountState.token}`
            }
          }
        )
          .then(response => {
            if (!response.ok) {
              throw Error(response.statusText);
            }
          })
          .catch(_ => {
            setCommentsCount(count => count + 1);
            setOptimisticComments(comments => [toBeDeleted, ...comments]);
          });
      }
    };

    return (
      <div className="flex flex-col justify-center border-t">
        <div className="flex flex-row justify-between mt-1 mb-2 items-baseline">
          <p className="text-md mt-1 font-semibold">
            {commentsCount} {commentsCount === 1 ? "Comment" : "Comments"}
          </p>
          <p className="text-gray-600 text-sm">
            comment at {formatTimestamp(timestamp)}
          </p>
        </div>
        <form onSubmit={submitComment}>
          <input
            onMouseDown={() => {
              if (!userAccountState.hasAccount) {
                history.push("/login");
              }
            }}
            type="text"
            value={draftComment}
            onChange={e => setDraftComment(e.target.value)}
            className="appearance-none block w-full bg-gray-200 text-gray-700  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:shadow-outline focus:bg-white"
            maxLength={140}
            placeholder="leave comment"
          />
        </form>
        <List
          items={optimisticComments}
          RenderItem={comment => (
            <Comment comment={comment} deleteComment={deleteComment} />
          )}
          keyExtractor={comment => comment.id}
          noBorder={true}
          RenderEmpty={() => (
            <div className="border p-4">
              <p className="text-sm text-gray-600 font-semibold">
                This {type} has no comments yet
              </p>
            </div>
          )}
        />

        {!exhaustedComments ? (
          loadingMore || newCommentInFlight ? (
            <div className="w-full  p-2 text-gray-500 text-sm border-t">
              <p>Loading...</p>
            </div>
          ) : (
            <div
              onClick={handleLoadMore}
              className="w-full cursor-pointer p-2 text-gray-500 hover:text-gray-700 text-sm border-t"
            >
              <p>Load more</p>
            </div>
          )
        ) : null}
      </div>
    );
  }
  return <PodSodaError retry={retry} />;
};

export default CommentSection;
