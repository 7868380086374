import React, { FC, useState, useEffect } from "react";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";

type Props = {
  feedUrl?: string;
  itunesId: number;
  small?: boolean;
};

const ListenOn: FC<Props> = ({ itunesId, feedUrl, small = false }) => {
  const [feedUrlFromItunes, setFeedUrlFromItunes] = useState("");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`https://podsoda-showcase.now.sh/api/itunes/${itunesId}`)
      .then(response => {
        if (!response.ok) {
          throw Error(response.statusText);
        }

        console.log(response);
        return response.json();
      })
      .then((data: { feed_url: string }) => {
        const feedUrlFromItunes = data.feed_url;

        if (feedUrlFromItunes) {
          setFeedUrlFromItunes(feedUrlFromItunes);
        }

        setLoading(false);
      })
      .catch(_ => {});
  }, [itunesId]);

  if (loading)
    return (
      <div className=" ">
        <p
          className={
            small
              ? "text-center text-md font-normal text-gray-600 mb-2"
              : "text-center text-xl font-semibold mb-2"
          }
        >
          Listen on your favorite podcast app
        </p>
        <div
          style={{ height: 98 }}
          className="flex flex-row flex-wrap justify-center bg-gray-200 rounded border"
        ></div>
      </div>
    );

  return (
    <div className=" ">
      <p
        className={
          small
            ? "text-center text-md font-normal text-gray-600 mb-2"
            : "text-center text-xl font-semibold mb-2"
        }
      >
        Listen on your favorite podcast app
      </p>
      <div className="flex flex-row flex-wrap justify-center bg-gray-200 py-4 rounded border">
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={`https://podcasts.apple.com/podcast/id${itunesId}`}
        >
          <Tooltip overlay={<span>Apple Podcasts</span>} placement="top">
            <img
              src={process.env.PUBLIC_URL + "/applepodcasts.png"}
              alt=""
              className="h-12 w-12 m-2"
            />
          </Tooltip>
        </a>

        {feedUrlFromItunes && (
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={`https://podcasts.google.com/?feed=${btoa(
              feedUrlFromItunes
            )}`}
          >
            <Tooltip overlay={<span>Google Podcasts</span>} placement="top">
              <img
                src={process.env.PUBLIC_URL + "/googlepodcasts.png"}
                alt=""
                className="h-12 w-12 m-2 rounded-lg bg-white"
              />
            </Tooltip>
          </a>
        )}

        <a
          rel="noopener noreferrer"
          target="_blank"
          href={`https://castro.fm/itunes/${itunesId}`}
        >
          <Tooltip overlay={<span>Castro</span>} placement="top">
            <img
              src={process.env.PUBLIC_URL + "/castro.svg"}
              alt=""
              className="h-12 w-12 m-2"
            />
          </Tooltip>
        </a>

        <a
          rel="noopener noreferrer"
          target="_blank"
          href={`https://pca.st/itunes/${itunesId}`}
        >
          <Tooltip overlay={<span>Pocket Casts</span>} placement="top">
            <img
              src={process.env.PUBLIC_URL + "/pocketcasts.png"}
              alt=""
              className="h-12 w-12 m-2"
            />
          </Tooltip>
        </a>

        <a
          rel="noopener noreferrer"
          target="_blank"
          href={`https://castbox.fm/vic/${itunesId}`}
        >
          {" "}
          <Tooltip overlay={<span>Castbox</span>} placement="top">
            <img
              src={process.env.PUBLIC_URL + "/castbox.png"}
              alt=""
              className="h-12 w-12 m-2"
            />
          </Tooltip>
        </a>

        <a
          rel="noopener noreferrer"
          target="_blank"
          href={`https://overcast.fm/itunes${itunesId}`}
        >
          <Tooltip overlay={<span>Overcast</span>} placement="top">
            <img
              src={process.env.PUBLIC_URL + "/overcast.png"}
              alt=""
              className="h-12 w-12 rounded-lg m-2"
            />
          </Tooltip>
        </a>

        {feedUrlFromItunes && (
          <a rel="noopener noreferrer" target="_blank" href={feedUrlFromItunes}>
            <Tooltip overlay={<span>RSS Feed</span>} placement="top">
              <img
                src={process.env.PUBLIC_URL + "/rss.svg"}
                alt=""
                className="h-12 w-12 rounded-lg m-2"
              />
            </Tooltip>
          </a>
        )}

        <a
          rel="noopener noreferrer"
          target="_blank"
          href={`https://www.breaker.audio/a/subscribe/apple-podcasts/${itunesId}`}
        >
          <Tooltip overlay={<span>Breaker</span>} placement="top">
            <img
              src={process.env.PUBLIC_URL + "/breaker.png"}
              alt=""
              className="h-12 w-12 rounded-lg m-2"
            />
          </Tooltip>
        </a>

        {feedUrlFromItunes && (
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={`https://radiopublic.com/${encodeURIComponent(
              feedUrlFromItunes
            )}`}
          >
            <Tooltip overlay={<span>Radio Public</span>} placement="top">
              <img
                src={process.env.PUBLIC_URL + "/radiopublic.svg"}
                alt=""
                className="h-12 w-12 rounded-lg m-2"
              />
            </Tooltip>
          </a>
        )}
      </div>
    </div>
  );
};

export default ListenOn;
