import React, { FC, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { usePodSodaFetch } from "../../hooks";
import { Moment, UserPlaylist } from "../../types/moments";
import PodSodaError from "../shared/PodSodaError";
import SearchResult from "../shared/SearchResult";
import { useStoreState } from "react-svelte-stores/build";
import userAccountStore from "../../stores/userAccount";
import List from "../shared/List";
import momentjs from "moment";
import ShimmerSearchResult from "../shared/ShimmerSearchResult";
import ErrorMessage from "../shared/ErrorMessage";

const AddToPlaylist: FC = () => {
  const { momentId } = useParams();

  const history = useHistory();

  const userAccountState = useStoreState(userAccountStore);

  const { error: errorOne, data: moment, retry } = usePodSodaFetch<Moment>(
    `/moment/by-moment-id/${momentId}`
  );
  const { error: errorTwo, data: playlists } = usePodSodaFetch<
    [UserPlaylist[], number]
  >(`/playlist/created/${userAccountState.username}?limit=${10}`, [
    userAccountState.username
  ]);

  const [addingInFlight, setAddingInFlight] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleAdd = (playlistId: string) => {
    setAddingInFlight(true);
    fetch(`https://podsoda-server.herokuapp.com/playlist/add-moment`, {
      method: "POST",
      headers: {
        Authorization: `bearer ${userAccountState.token}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        id: playlistId,
        momentId
      })
    })
      .then(response => {
        if (response.status === 400) {
          setErrorMessage("Moment is already in this playlist");
          setAddingInFlight(false);
        } else {
          setAddingInFlight(false);
          history.push(`/playlists/${playlistId}`);
        }
      })
      .catch(_ => {
        setErrorMessage("Failed to Save");
        setAddingInFlight(false);
      });
  };

  if (errorOne || errorTwo) return <PodSodaError retry={retry} />;

  if (moment && playlists) {
    return (
      <div className="flex flex-col items-center">
        <div className="w-10/12 mt-8 flex flex-col">
          <h2 className="text-center text-2xl font-gray-700 font-semibold my-4">
            Add moment to playlist
          </h2>
          <div className="self-center w-3/5 border-t">
            <SearchResult
              imageUrl={moment.thumbnail}
              primaryText={moment.momentTitle}
              secondaryText={moment.podcastName}
              tertiaryText={`created by @${moment.username}`}
              noLink={true}
              link=""
            />
          </div>

          <div className="mt-3" />

          <ErrorMessage
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
          />

          <div className="mt-3" />

          <List
            items={playlists[0]}
            keyExtractor={playlist => playlist.id}
            RenderItem={playlist => (
              <div
                className="cursor-pointer"
                onClick={() => {
                  if (!addingInFlight) {
                    handleAdd(playlist.id);
                  }
                }}
              >
                <SearchResult
                  noLink={true}
                  link=""
                  primaryText={playlist.name}
                  secondaryText={`${playlist.playlistMoments.length} moments`}
                  tertiaryText={`Last updated: ${momentjs
                    .utc(playlist.updatedDate)
                    .fromNow()}`}
                  imageUrl={`${process.env.PUBLIC_URL}/podsoda.png`}
                />
              </div>
            )}
            RenderEmpty={() => (
              <div className="border p-4">
                <p className="text-sm text-gray-600 font-semibold">
                  You haven't created any playlists yet
                </p>
              </div>
            )}
          />
        </div>
      </div>
    );
  }
  return (
    <div className="flex flex-col items-center">
      <div className="w-10/12 mt-8 flex flex-col">
        <h2 className="text-center text-2xl font-gray-700 font-semibold my-4">
          Add moment to playlist
        </h2>
        <div className="self-center w-3/5 border-t">
          <ShimmerSearchResult hasTertiaryText={true} />
        </div>

        <div className="mt-6" />

        <List
          items={Array(10).fill(0)}
          RenderItem={item => <ShimmerSearchResult hasTertiaryText={true} />}
          keyExtractor={(item, i) => i!.toString()}
        />
      </div>
    </div>
  );
};

export default AddToPlaylist;
