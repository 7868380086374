import React, { FC, useState, useLayoutEffect } from "react";
import { useStoreState } from "react-svelte-stores/build";
import favoritePodcasts from "../../stores/favoritePodcasts";
import { ListenPodcastFeedResults } from "../../types/listenApi";

type Props = {
  podcastId: string;
  podcast: ListenPodcastFeedResults;
};

const FavHeart: FC<Props> = ({ podcastId, podcast }) => {
  const favoritePodcastsState = useStoreState(favoritePodcasts);

  const [favorited, setFavorited] = useState(false);

  useLayoutEffect(() => {
    const exists = favoritePodcastsState.find(
      podcast => podcast.id === podcastId
    );
    setFavorited(exists ? true : false);
  }, [favoritePodcastsState, podcastId]);

  return (
    <div>
      {favorited ? (
        <button
          className="text-sm w-32 uppercase bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-1 rounded-full focus:outline-none"
          onClick={() => favoritePodcasts.removeFavoritePodcast(podcast.id)}
        >
          Unfavorite
        </button>
      ) : (
        <button
          className="text-sm w-32 uppercase bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-1 rounded-full  focus:outline-none"
          onClick={() =>
            favoritePodcasts.addFavoritePodcast({
              description_original: podcast.description,
              genre_ids: podcast.genre_ids,
              id: podcast.id,
              image: podcast.image,
              itunes_id: podcast.itunes_id,
              listennotes_url: podcast.listennotes_url,
              thumbnail: podcast.thumbnail,
              publisher_original: podcast.publisher,
              title_original: podcast.title,
              rss: podcast.rss
            })
          }
        >
          Favorite
        </button>
      )}
    </div>
  );
};

export default FavHeart;
