import React from "react";
import List from "./List";

interface Props<T> {
  /** Whether or not the search results are loading */
  loading: boolean;
  /** Shows results if true, default content if false */
  searching: boolean;
  /** Search Results */
  results: T[];
  /** What to render when loading */
  RenderLoading: () => JSX.Element;
  /** Default Content */
  RenderDefaultContent: () => JSX.Element;
  /** Render each result item */
  RenderItem: (item: T) => JSX.Element;
  /** Extract string key from each item */
  keyExtractor: (item: T) => string;
  RenderEmpty?: () => JSX.Element;
}

/** Optionally provide type argument of individual search result. Pass array of search results as prop "result" */
function SearchComponent<T>(props: Props<T>) {
  if (!props.searching) {
    return <props.RenderDefaultContent />;
  } else {
    if (props.loading) return <props.RenderLoading />;

    return (
      <List
        items={props.results}
        keyExtractor={props.keyExtractor}
        RenderItem={props.RenderItem}
        RenderEmpty={props.RenderEmpty}
      />
    );
  }
}

export default SearchComponent;
