import React, { FC, useEffect } from "react";
import { X } from "react-feather";
import { firstTime } from "../../stores/firstTime";
import { useStoreState } from "react-svelte-stores";

const WelcomeToast: FC = () => {
  const firstTimeState = useStoreState(firstTime);

  useEffect(() => {
    console.log(firstTimeState);
  }, [firstTimeState]);

  if (firstTimeState) {
    return (
      <div
        className="flex items-center justify-between bg-blue-500 text-white text-sm font-bold px-4 py-3 mx-8 mt-4"
        role="alert"
      >
        <div className="flex flex-row">
          <span aria-hidden>👋</span>
          <p>Welcome to PodSoda! Learn more: For Podcasters / For Listeners</p>
        </div>
        <X
          color="#CBD2D9"
          className="cursor-pointer"
          onClick={() => firstTime.set(false)}
        />
      </div>
    );
  } else {
    return null;
  }
};

export default WelcomeToast;
