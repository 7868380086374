import { persisted, log } from "react-svelte-stores";

export interface UserAccountState {
  hasAccount: boolean;
  token?: string;
  userId?: string;
  username?: string;
  email?: string;
}

const userAccountDefaultState: UserAccountState = {
  hasAccount: false
};

const createUserAccountStore = (defaultState: UserAccountState) => {
  const { subscribe, set } = persisted(defaultState, "userAccount");

  return {
    subscribe,
    logIn: (token: string, userId: string, username: string, email: string) =>
      set({
        hasAccount: true,
        token,
        userId,
        username,
        email
      }),
    logOut: () => set(defaultState)
  };
};

const userAccountStore = createUserAccountStore(userAccountDefaultState);

export default userAccountStore;

log("acct", userAccountStore);
