import React, { FC } from "react";
import { IComment } from "../../types/moments";
import {
  useStoreState,
  useSelectedStoreState
} from "react-svelte-stores/build";
import userAccountStore from "../../stores/userAccount";
import { formatTimestamp, fromNow } from "../../utils/time";
import { Link } from "react-router-dom";
import { playerStore } from "./NewPlayer";

type Props = {
  comment: IComment;
  deleteComment: (commentId: string) => void;
};

const Comment: FC<Props> = ({ comment, deleteComment }) => {
  const userAccountState = useStoreState(userAccountStore);

  const currentPodcast = useSelectedStoreState(
    playerStore,
    state => state.metadata
  );

  return (
    <div className="flex flex-col py-2 border-t px-2">
      <div className="flex flex-row justify-between items-end">
        <div className="flex flex-row items-end">
          <Link to={`/user/${comment.user.username}`}>
            <p className="text-sm font-semibold text-blue-500 hover:text-blue-700">
              @{comment.user.username}
            </p>
          </Link>
          <p className="text-xs text-gray-600 ml-1">
            at{" "}
            <span
              onClick={() => {
                if (currentPodcast?.id === comment.episodeId) {
                  playerStore.dispatch({
                    type: "REMOTE_SEEK",
                    timestamp: parseFloat(comment.timestamp)
                  });
                } else {
                  playerStore.loadAndSeekTo(
                    comment.episodeId,
                    parseFloat(comment.timestamp)
                  );
                }
              }}
              className="hover:text-gray-700 cursor-pointer"
            >
              {formatTimestamp(comment.timestamp)}
            </span>
          </p>
        </div>

        <p className="text-xs text-gray-600 mb-1">
          {fromNow(comment.createdAt)}
        </p>
      </div>

      <div className="my-1 text-sm">
        <p>{comment.content}</p>
      </div>

      <div className="flex flex-row justify-end">
        {comment.id === "inprogress" ? (
          <p className=" text-xs text-gray-500">loading</p>
        ) : userAccountState.userId === comment.userId ? (
          <button
            className=" text-xs text-red-500 hover:text-red-700"
            onClick={() => deleteComment(comment.id)}
          >
            delete
          </button>
        ) : (
          <p className=" text-xs text-white">
            {/* report comment */}
            {"."}
          </p>
        )}
      </div>
    </div>
  );
};

export default Comment;
