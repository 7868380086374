import React, { FC } from "react";
import HotMoments from "./HotMoments";
import TrendingMomentsByTag from "./TrendingMomentsByTag";

const Home: FC = () => {
  return (
    <div className="flex flex-col items-center">
      <div className="w-full sm:w-10/12 mt-6">
        <HotMoments />
        <TrendingMomentsByTag />
      </div>
    </div>
  );
};

export default Home;
