import React, { FC, useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { usePodSodaFetch } from "../../hooks";
import { Moment } from "../../types/moments";
import PodSodaError from "../shared/PodSodaError";
import { useStoreState } from "react-svelte-stores/build";
import userAccount from "../../stores/userAccount";
import CommentSection from "../shared/CommentSection";
import MomentLikeHeart from "./components/MomentLikeHeart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { Play, Share } from "react-feather";
import momentjs from "moment";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";

// @ts-ignore
import ShowMore from "react-show-more";

// @ts-ignore
import posthog from "posthog-js";
import MomentPlayer from "./components/MomentPlayer";
import ShareModal from "./components/ShareModal";
import ListenOn from "../Podcasts/ListenOn";

const MomentById: FC = () => {
  const { id } = useParams();

  const userAccountState = useStoreState(userAccount);

  useEffect(() => {
    posthog.capture("viewed-moment-page");
  }, []);

  const { loading, error, data: moment, retry } = usePodSodaFetch<Moment>(
    `/moment/by-moment-id/${id}?userId=${
      userAccountState.hasAccount ? userAccountState.userId : "anon"
    }`,
    [userAccountState.hasAccount, id]
  );

  const [timestamp, setTimestamp] = useState(0);

  useEffect(() => {
    if (moment) {
      setTimestamp(parseFloat(moment.startTime));
    }
  }, [moment]);

  const [showShareModal, setShowShareModal] = useState(false);

  if (loading)
    return (
      <div className="flex flex-col items-center">
        <div className="w-10/12 ">
          <div className="flex flex-col md:flex-row w-full  mt-8">
            <div className="w-40 h-32 bg-gray-300  self-center  rounded" />
            <div className="w-full h-32 bg-gray-200 mt-4 md:mt-0   md:ml-4 rounded" />
          </div>
        </div>
      </div>
    );
  if (error) return <PodSodaError retry={retry} />;

  if (moment) {
    return (
      <div className="flex flex-col items-center">
        <ShareModal
          moment={moment}
          showShareModal={showShareModal}
          setShowShareModal={setShowShareModal}
        />
        <div className="w-10/12 ">
          <div className="flex flex-col md:flex-row   mt-8">
            <img
              src={moment.thumbnail}
              className="w-32 h-32 object-cover self-center rounded"
              alt=""
            />

            <div className="flex flex-col w-full text-center md:text-left mt-4 md:mt-0   md:ml-4">
              <Link to={`/podcasts/${moment.podcastId}`}>
                <p className="text-md truncate text-blue-500 hover:text-blue-700">
                  {moment.podcastName}
                </p>
              </Link>
              <p className="text-xl font-semibold ">
                <ShowMore
                  lines={2}
                  more="Show more"
                  less="Show less"
                  anchorClass="text-blue-500 hover:text-blue-700"
                >
                  {moment.momentTitle}
                </ShowMore>
              </p>

              <p className="text-blue-500  hover:text-blue-700 mt-2">
                <Link to={`/episodes/${moment.episodeId}`}>
                  <ShowMore
                    lines={2}
                    more="Show more"
                    less="Show less"
                    anchorClass="text-blue-500 hover:text-blue-700"
                  >
                    Episode: {moment.episodeTitle}
                  </ShowMore>
                </Link>
              </p>

              {moment.username !== "anon" ? (
                <p className="text-gray-600 text-sm mt-2">
                  <Link to={`/user/${moment.username}`}>
                    <span className="text-blue-500 hover:text-blue-700">
                      @{moment.username}
                    </span>
                  </Link>{" "}
                  on {momentjs(moment.createdAt).format("MMM Do, YYYY")}
                </p>
              ) : (
                <p className="text-gray-600 text-sm mt-2">
                  anon on {momentjs(moment.createdAt).format("MMM Do, YYYY")}
                </p>
              )}
            </div>
          </div>

          <MomentPlayer moment={moment} setTimestamp={setTimestamp} />

          <div className="border-t pt-6 mt-6">
            <ListenOn feedUrl={""} itunesId={moment.itunesId} small={true} />
          </div>

          <div className="flex flex-row justify-between my-8">
            <div className="flex flex-row justify-start">
              <MomentLikeHeart
                liked={moment.liked}
                likes_count={moment.likes_count}
                momentId={moment.id}
              />

              <div className="flex flex-row items-center mx-6">
                <Play color="#7B8794" size={24} />
                <p className="text-gray-600 font-semibold text-md ml-1">
                  {moment.plays}
                </p>
              </div>
            </div>

            <div className="flex flex-row justify-end items-center">
              <Link to={`/playlists/add-to-playlist/${moment.id}`}>
                <div className="flex flex-row items-center text-gray-600 hover:text-gray-700 mx-6 ">
                  <FontAwesomeIcon icon={faPlusCircle} transform="shrink-3" />
                  <p className="text-sm  font-semibold ml-1 pb-1">playlist</p>
                </div>
              </Link>

              <Tooltip overlay={<span>Share or Embed</span>} placement="left">
                <div>
                  <Share
                    size={28}
                    color="#F9703E"
                    className="cursor-pointer"
                    onClick={() => setShowShareModal(true)}
                  />
                </div>
              </Tooltip>
            </div>
          </div>

          {id && <CommentSection type="moment" id={id} timestamp={timestamp} />}
        </div>
      </div>
    );
  }
  return <PodSodaError retry={retry} />;
};

export default MomentById;
